import React from "react";
import styled from "styled-components";
import { Button, HeadingXSmall, ParagraphSmall, HeadingSmall } from "@my-swipestox/components";
import { useTranslation } from "react-i18next";

import constants from "../../../constants";

const Wrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const DisableMfaBtn = styled(Button)`
  margin-right: 5px;
  background: transparent;
  color: ${(props) => props.theme.color.primary};
`;

const RecoveryCodesButton = styled(Button)`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.color.primary};
`;

const SecurityMFA = (props) => {
  const { t } = useTranslation();
  const { mfaEnabled, setSecurityStep } = props;

  const showAuthenticator = () => {
    setSecurityStep(constants.MFA_STEPS.ENTER_KEY);
  };

  const showRecoveryCodes = () => {
    setSecurityStep(constants.MFA_STEPS.RECOVERY_CODES);
  };

  return (
    <Wrapper>
      <HeadingSmall mb="1rem"> {t("SECURITY.TWO_FACTOR_AUTHENTICATION")}</HeadingSmall>
      <ParagraphSmall mb="0.5rem">
        {t("SECURITY.TWO_FACTOR_AUTHENTICATION_DESCRIPTION")}
      </ParagraphSmall>
      <HeadingXSmall mt="1.5rem" mb="0.5rem">
        {t("SECURITY.AUTHENTICATOR_APP")}
      </HeadingXSmall>
      <ParagraphSmall mb="1rem">{t("SECURITY.AUTHENTICATOR_APP_DESCRIPTION")}</ParagraphSmall>
      {mfaEnabled ? (
        <div>
          <DisableMfaBtn onClick={showAuthenticator}>
            {t(`SECURITY.DISABLE_TWO_FACTOR_AUTHENTICATION`)}
          </DisableMfaBtn>
          <RecoveryCodesButton onClick={showRecoveryCodes}>
            {t(`SECURITY.GET_RECOVERY_CODES`)}
          </RecoveryCodesButton>
        </div>
      ) : (
        <Button bsStyle="primary" onClick={showAuthenticator}>
          {t(`SECURITY.ENABLE_TWO_FACTOR_AUTHENTICATION`)}
        </Button>
      )}
    </Wrapper>
  );
};

export default SecurityMFA;
