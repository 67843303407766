import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { Input, Button, FormControl, ParagraphLarge } from "@my-swipestox/components";

import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";
import state from "../../state";
import cacheHelper from "../../helpers/cacheHelper";

import UnauthorizedHeader from "../header/UnauthorizedHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const Content = styled.div`
  border-radius: 3px;
  max-width: 32rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const FormWrapper = styled.div`
  padding: 4rem 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    padding: 4rem;
  }
`;

const StyledSubheadline = styled(ParagraphLarge)`
  text-align: center;
`;

const PasswordRecovery = () => {
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const setRefreshTokenExpireTime = state.useRefreshToken(
    (state) => state.setRefreshTokenExpireTime
  );
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const history = useHistory();
  const urlParams = useParams();

  const resetPasswordMutation = useMutation(api.mutations.resetPassword, {
    onError: () => toast.error(t("REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER")),
    onSuccess: (response) => {
      const token = response.data.info.token;
      const xsrf = response.data.info.xsrf;
      const userInfo = response.data.data;

      if (userInfo.mfa_enabled) {
        cacheHelper.setToken(token);
        history.push("/mfa");
      } else {
        setIsLoggedIn(true, token);
        history.push("/");
      }
      setXsrf(xsrf);
      setRefreshToken(response.data.info.refreshToken);
      setRefreshTokenExpireTime(response.data.info.token_expiration);
      setUserInfo(userInfo);
    },
  });

  const onFormSubmit = (values) => {
    const data = {
      email: decodeURIComponent(urlParams.email),
      verification_code: decodeURIComponent(urlParams.code),
      password: utils.crypto.encode(values.password),
      plain_password: values.confirm_password,
    };

    resetPasswordMutation.mutate(data);
  };

  const { handleSubmit, getValues, register, errors } = useForm();
  const { t } = useTranslation();

  return (
    <Grid>
      <UnauthorizedHeader />
      <Wrapper>
        <Content>
          <FormWrapper>
            <StyledSubheadline mb="1rem">
              {t("PASSWORD_RECOVERY.ENTER_NEW_PASSWORD")}
            </StyledSubheadline>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <FormControl
                caption={(errors.password && errors.password.message) || ""}
                error={errors.password}
                mb="1rem"
              >
                <Input
                  type="password"
                  name="password"
                  defaultValue=""
                  placeholder={t("PASSWORD_RECOVERY.NEW_PASSWORD")}
                  ref={register({
                    required: t("PASSWORD_RECOVERY.PASSWORD_IS_REQUIRED"),
                    pattern: {
                      value: constants.PASSWORD_REGEX,
                      message: t("PASSWORD_RECOVERY.PASSWORD_CRITERIA_NOT_MET"),
                    },
                  })}
                />
              </FormControl>
              <FormControl
                caption={(errors.confirm_password && errors.confirm_password.message) || ""}
                error={errors.confirm_password}
                mb="1rem"
              >
                <Input
                  type="password"
                  name="confirm_password"
                  placeholder={t("PASSWORD_RECOVERY.CONFIRM_NEW_PASSWORD")}
                  ref={register({
                    required: t("PASSWORD_RECOVERY.CONFIRM_PASSWORD_IS_REQUIRED"),
                    pattern: {
                      value: constants.PASSWORD_REGEX,
                      message: t("PASSWORD_RECOVERY.PASSWORD_CRITERIA_NOT_MET"),
                    },
                    validate: () => {
                      if (getValues("password") !== getValues("confirm_password")) {
                        return t("PASSWORD_RECOVERY.PROVIDED_PASSWORDS_DONT_MATCH");
                      }

                      return true;
                    },
                  })}
                />
              </FormControl>
              <FormControl mb="1rem">
                <Button
                  shouldFitContainer
                  type="submit"
                  disabled={resetPasswordMutation.isLoading}
                  isLoading={resetPasswordMutation.isLoading}
                >
                  <span>{t("PASSWORD_RECOVERY.CHANGE_PASSWORD")}</span>
                </Button>
              </FormControl>
            </form>
          </FormWrapper>
        </Content>
      </Wrapper>
    </Grid>
  );
};

export default PasswordRecovery;
