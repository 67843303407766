import axios from "./httpInstance";
import constants from "../constants";

const login = (data) => {
  return axios.post("/user/login", data);
};

const register = (data) => {
  return axios({
    data,
    method: "POST",
    url: "user/registration/register",
    transformRequest: [
      function (data, headers) {
        headers.platform = constants.PLATFORM;
        headers["accept-version"] = constants.ACCEPT_VERSION.TWO;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const logout = () => {
  return axios.post("/user/logout");
};

const updateLeverage = (terminalId) => {
  return axios.get(`broker/get_terminal_data_from_broker/${terminalId}`);
};

const editTradingAccountName = (data) => {
  return axios.post("/broker/set_account_name", data);
};

const addNewTradingAccount = (data) => {
  return axios.post("/user/registration/create_account", data);
};

const saveKyc = (data) => {
  return axios({
    data,
    method: "POST",
    url: "/kyc/save",
    transformRequest: [
      function (data, headers) {
        headers.platform = "web-trader";
        headers["accept-version"] = constants.ACCEPT_VERSION.TWO;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const upgradeUser = () => {
  return axios({
    method: "POST",
    url: "/user/registration/upgrade",
    transformRequest: [
      function (data, headers) {
        headers.platform = "web-trader";
        headers["accept-version"] = constants.ACCEPT_VERSION.ONE;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const uploadDocument = (url, file) => {
  return fetch(url, {
    headers: {
      "Content-Type": file.type,
    },
    method: "PUT",
    body: file,
  });
};

const generateNewTradingAccountPassword = (data) => {
  return axios.post("/broker/generate_new_password", data);
};

const generateNewTokens = (token) => {
  return axios.post("/user/refresh_session", {
    refresh_token: token,
  });
};

const depositRequest = (data) => {
  return axios.post("/payments/mpsa/deposit", data);
};

const withdrawalRequest = (data) => {
  return axios.post("/payment/mpsa/withdraw", data);
};

const saveUserDocuments = (data) => {
  return axios.post("/user/documents/save", data);
};

const sendSmsCode = (phone) => {
  return axios.post("/sms/send", { phone_number: phone });
};

const verifyPhone = ({ phone, code }) => {
  return axios.post("/sms/verify", {
    phone_number: phone,
    verification_code: code,
  });
};

const checkIfUserExists = (data) => {
  return axios({
    data,
    method: "POST",
    url: "user/registration/user_exists",
    transformRequest: [
      function (data, headers) {
        headers.platform = constants.PLATFORM;
        headers["accept-version"] = constants.ACCEPT_VERSION.TWO;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const smsLookup = (phone) => {
  return axios.post("sms/lookup", { phone_number: phone });
};

const sendResetPasswordEmail = (data) => {
  return axios.post("user/password/send_verification_code", data);
};

const cancelWithdrawalRequest = (withdrawalId) => {
  return axios.post("payment/withdraw/cancel", { withdrawal_id: withdrawalId });
};

const changePassword = (data) => {
  return axios.post("user/password/change", data);
};

const resetPassword = (data) => {
  return axios.post("user/password/reset", data);
};

const tokenLogin = (data) => {
  return axios.post("user/auto_login", data);
};

const confirmEmail = (data) => {
  return axios({
    method: "POST",
    url: "/user/email/confirm",
    data: {
      email: data.email,
      user_id: data.userId,
    },
    transformRequest: [
      (data, headers) => {
        headers.platform = data.platform;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const enableMFA = (data) => {
  return axios({
    method: "POST",
    url: "/mfa/enable",
    data,
  });
};

const disableMFA = (data) => {
  return axios({
    method: "POST",
    url: "/mfa/disable",
    data,
  });
};

const validateMFA = (data) => {
  return axios({
    method: "POST",
    url: "/mfa/login",
    data,
  });
};

const validateMFARecovery = (code) => {
  return axios({
    method: "POST",
    url: "/mfa/login/recovery",
    data: {
      recovery_key: code,
    },
  });
};

const getDepositZotapayUri = (data) => {
  return axios({
    method: "POST",
    url: "/payments/zotapay/deposit_uri",
    data,
  });
};

const requestZotapayWithdrawal = (data) => {
  return axios({
    method: "POST",
    url: "/payment/zotapay/withdraw",
    data,
  });
};

const getDepositNacepayData = data => {
  return axios({
    method: 'POST',
    url: '/payments/nacepay/deposit',
    data,
  });
};

const requestNacepayWithdrawal = data => {
  return axios({
    method: 'POST',
    url: '/payments/nacepay/withdraw',
    data,
  });
};

const getDepositNucleus = data => {
  return axios({
    method: 'POST',
    url: '/payments/nucleus365/deposit/p2p',
    data,
  });
};

const requestNucleusWithdrawal = (data) => {
  return axios({
    method: "POST",
    url: "/payment/nucleus365/withdraw",
    data,
  });
};

const internalTransfer = (data) => {
  return axios({
    method: "POST",
    url: "/user/internal_transfer",
    data: {
      amount: data.amount,
      currency: data.currency,
      source_terminal: data.sourceTerminal,
      target_terminal: data.targetTerminal,
    },
  });
};

const updateLanguage = (language) => {
  return axios({
    method: "POST",
    url: "/user/profile/update",
    data: {
      app_language: language,
    },
  });
};

const mutations = {
  login,
  register,
  logout,
  updateLeverage,
  editTradingAccountName,
  addNewTradingAccount,
  saveKyc,
  upgradeUser,
  generateNewTradingAccountPassword,
  generateNewTokens,
  depositRequest,
  uploadDocument,
  saveUserDocuments,
  sendSmsCode,
  verifyPhone,
  checkIfUserExists,
  smsLookup,
  sendResetPasswordEmail,
  withdrawalRequest,
  cancelWithdrawalRequest,
  changePassword,
  resetPassword,
  confirmEmail,
  tokenLogin,
  enableMFA,
  disableMFA,
  validateMFA,
  validateMFARecovery,
  getDepositZotapayUri,
  requestZotapayWithdrawal,
  getDepositNacepayData,
  requestNacepayWithdrawal,
  internalTransfer,
  updateLanguage,
  getDepositNucleus,
  requestNucleusWithdrawal
};

export default mutations;
