import { useTable } from "react-table";
import styled from "styled-components";
import PropTypes from "prop-types";

const TableCustom = styled.table`
  border-spacing: 0;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
`;

const Th = styled.th`
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  color: ${(props) => props.theme.color.contentSecondary};
  font-size: ${(props) => props.theme.typography.size.smallText};
  font-weight: 400;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  text-align: left;
`;

const Td = styled.td`
  padding: 10px;
  font-size: ${(props) => props.theme.typography.size.smallText};
  font-weight: 350;
`;

const Table = (props) => {
  const { columns, data, noData } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  if ((!data || !data.length) && noData) {
    return noData;
  }

  return (
    <TableCustom {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </TableCustom>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  noData: PropTypes.object,
};

export default Table;
