import React, { Fragment, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Upload, X } from "react-feather";
import styled from "styled-components";
import { Button } from "@my-swipestox/components";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import Loader from "../loader/Loader";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 202px;
  min-width: 200px;
  height: 138px;
  padding: 0 10px;
  outline: 0;
  border: ${(props) =>
    props.fileSelected ? `1px solid ${props.theme.color.border}` : "1px dashed #7f93bc"};
  border-radius: 3px;
  cursor: ${(props) => (props.fileSelected ? "default" : "pointer")};
  background-color: ${(props) =>
    props.fileSelected ? props.theme.color.backgroundSecondary : "unset"};
  opacity: ${(props) => (props.isDragActive ? "0.7" : "1")};
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.color.backgroundHover};
    border: ${(props) =>
      props.fileSelected ? `1px solid ${props.theme.color.border}` : "1px dashed #7f93bc"};
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.color.contentPrimary};
  font-size: ${(props) => props.theme.typography.size.smallText};
  font-weight: 500;
  text-align: center;
`;

const Subtitle = styled.div`
  display: none;
  font-size: ${(props) => props.theme.typography.size.normalText};
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    display: block;
  }
`;

const Footer = styled.div`
  font-size: 11px;
  color: #7f93bc;
  margin-top: 24px;
`;

const Image = styled.img`
  max-width: 100%;
  min-width: 180px;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  display: ${(props) => (props.fileSelected ? "block" : "none")};
`;

const UploadIcon = styled(Upload)`
  color: #7f93bc;
  margin-bottom: 20px;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  padding: 6px;
  background-color: #000000;
  opacity: 0.7;
  border: 0;
  &:hover,
  &:focus {
    background-color: #000000;
    opacity: 0.9;
  }
  .icn {
    color: #ffffff;
    font-size: 22px;
  }
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  background-color: #00000070;
`;

const FileUpload = (props) => {
  const { t } = useTranslation();
  const {
    title,
    subtitle,
    footer,
    multiple = false,
    allowedFileTypes = constants.ALLOWED_IMAGE_TYPES,
    isFileSelected = false,
    dataUrlContent,
    uploadInProgress,
    onFileSelected,
    onFileUnselected,
    onUnsupportedFile = () => {},
  } = props;

  const isMobile = useIsMobile();

  const allowedExtensions = allowedFileTypes
    .map((fileType) => fileType.slice(fileType.indexOf("/") + 1).toUpperCase())
    .join(", ");

  const titleText =
    title || t(isMobile ? "FILE_UPLOAD.SELECT_FILE" : "FILE_UPLOAD.DRAG_AND_DROP_TO_UPLOAD");
  const subtitleText = subtitle || t("FILE_UPLOAD.OR_BROWSE_FILE");
  const footerText = footer || t("FILE_UPLOAD.SUPPORTED_FORMATS", { formats: allowedExtensions });

  const [fileSelected, setFileSelected] = useState(isFileSelected);
  const imageRef = useRef();

  const handleFileDrop = useCallback(
    (acceptedFiles) => {
      if (allowedFileTypes.indexOf(acceptedFiles[0].type) === -1) {
        onUnsupportedFile();
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        imageRef.current.src = event.target.result;
        setFileSelected(true);
      };

      reader.readAsDataURL(acceptedFiles[0]);
      onFileSelected(acceptedFiles[0]);
    },
    [allowedFileTypes, onFileSelected, onUnsupportedFile]
  );

  const handleFileUnselect = () => {
    setFileSelected(false);
    onFileUnselected();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedFileTypes,
    onDrop: handleFileDrop,
    disabled: fileSelected || uploadInProgress,
    multiple,
  });

  return (
    <Wrapper
      className={props.className}
      isDragActive={isDragActive}
      fileSelected={fileSelected}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {!fileSelected ? (
        <Fragment>
          <UploadIcon size={24} />
          <Title>{titleText}</Title>
          <Subtitle>{subtitleText}</Subtitle>
          <Footer>{footerText}</Footer>
        </Fragment>
      ) : (
        <Fragment>
          <RemoveButton disabled={uploadInProgress} onClick={handleFileUnselect}>
            <X size={16} />
          </RemoveButton>
          {uploadInProgress && <StyledLoader />}
        </Fragment>
      )}
      <Image
        ref={imageRef}
        fileSelected={fileSelected}
        src={dataUrlContent || "#"}
        alt="Uploaded file"
      />
    </Wrapper>
  );
};

FileUpload.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  onFileUnselected: PropTypes.func.isRequired,
  onUnsupportedFile: PropTypes.func,
  isFileSelected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
};

export default FileUpload;
