import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, ParagraphLarge } from "@my-swipestox/components";
import { useQuery } from "react-query";
import { AlertCircle } from "react-feather";

import utils from "../../utils";
import Loader from "../../components/loader/Loader";
import api from "../../api";
import constants from "../../constants";

const Wrapper = styled.div`
  text-align: center;
  padding: 20%;
`;

const StyledParagraph = styled(ParagraphLarge)`
  text-align: center;
  margin-left: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const TransactionStatus = styled.div`
  padding: 1rem;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const mpsaStatuses = {
  A0: `sucess`,
  A6: `pending`,
  TR00: `pending`,
};

const transactionStatuses = {
  sucess: "sucess",
  error: "error",
  pending: "pending",
};

const DepositStatus = () => {
  const { t } = useTranslation();

  const transactionInfoQuery = useQuery(constants.QUERY_NAMES.getTransactionInfo, () =>
    api.queries.getTransactionInfo({
      amount: utils.getParamByName("amount"),
      merchant_account: utils.getParamByName("merchant_account"),
      merchant_order: utils.getParamByName("merchant_order"),
    })
  );

  const getInfoMessage = () => {
    const transactionStatus =
      transactionInfo &&
      (mpsaStatuses[transactionInfo.transaction.status] || transactionStatuses.error);

    if (transactionStatus === transactionStatuses.sucess) {
      return t("MANAGE_MONEY.SUCESS");
    } else if (transactionStatus === transactionStatuses.pending) {
      return t("MANAGE_MONEY.PENDING");
    } else {
      return t("MANAGE_MONEY.ERROR");
    }
  };

  const gotoDefaultRoute = () => {
    window.top.location = "/";
  };

  const transactionInfo = transactionInfoQuery?.data?.data?.data;

  return (
    <Wrapper>
      {transactionInfoQuery.isLoading && (
        <Loader text="MANAGE_MONEY.DEPOSIT.LOADING_TRANSACTION_INFORMATION" />
      )}
      {!transactionInfoQuery.isLoading && transactionInfo && (
        <TransactionStatus>
          <div>
            <AlertCircle />
            <StyledParagraph> {getInfoMessage()} </StyledParagraph>
          </div>
          <StyledParagraph> {transactionInfo.transaction.message} </StyledParagraph>
          <StyledButton onClick={gotoDefaultRoute}>
            {t("MANAGE_MONEY.BACK_TO_MAIN_PAGE")}
          </StyledButton>
        </TransactionStatus>
      )}
    </Wrapper>
  );
};

export default DepositStatus;
