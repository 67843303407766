import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "@my-swipestox/components";

import DepositHistory from "./DepositHistory";
import WithdrawalHistory from "./WithdrawalHistory";
import TransferHistory from "./TransferHistory";

const HistoryWrapper = styled.div`
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const Tab = styled(Button)`
  &:first-of-type {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:last-of-type {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
`;

const TabPanel = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const History = (props) => {
  const { fullAccount } = props;
  const { t } = useTranslation();

  const tabs = [
    {
      name: "MANAGE_MONEY.HISTORY.DEPOSITS",
      value: 1,
    },
    {
      name: "MANAGE_MONEY.HISTORY.WITHDRAWALS",
      value: 2,
    },
    {
      name: "MANAGE_MONEY.HISTORY.TRANSFER_HISTORY",
      value: 3,
    },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <HistoryWrapper>
      <Tabs>
        {tabs.map((i) => (
          <Tab
            key={i.value}
            value={i.value}
            isSelected={selectedTab === i.value}
            onClick={() => setSelectedTab(i.value)}
            kind="secondary"
          >
            {t(i.name)}
          </Tab>
        ))}
      </Tabs>
      <TabPanel>
        {selectedTab === 1 && <DepositHistory fullAccount={fullAccount} />}
        {selectedTab === 2 && <WithdrawalHistory fullAccount={fullAccount} />}
        {selectedTab === 3 && <TransferHistory fullAccount={fullAccount} />}
      </TabPanel>
    </HistoryWrapper>
  );
};

export default History;
