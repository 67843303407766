import React, { Fragment, useState } from 'react';
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "@my-swipestox/components";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import state from "../../state";
import KycContinueLaterModal from './KycContinueLaterModal';

const KycHeaderWrapper = styled.div`
  .kyc-header__wrapper {
    display: grid;
    align-items: center;
    padding: 0 24px;
    height: 72px;
    box-shadow: 0px 1px 0px ${(props) => props.theme.color.shadow};
    @media (max-width: 768px) {
      min-height: 72px;
      height: fit-content;
    }
    margin-bottom: 32px;
  }
  .kyc-header__col1 {
    grid-template-columns: 1fr;
  }
  .kyc-header__col3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .kyc-header__progress {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      color: #7F93BC;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .kyc-header__title {
    text-align: center;
    @media (min-width: 768px) {
      color: #7F93BC;
    }
  }
  .kyc-header__title-mobile {
    color: #7F93BC;
    text-align: center;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .kyc-header__logo {
    img {
      max-height: 32px;
    }
  }
  .kyc-header__logo--transparent {
    width: calc(100vw - 48px);
    display: flex;
    justify-content: start;
  }
  .kyc-header__button {
    margin-left: 30px;
    padding: 14px 18px;
    border-radius: 4px;
    font-weight: 400;
    display: flex;
    font-size: 16px;
    border: none;
    align-items: center;
    color: ${(props) => props.theme.color.kycContinueLater};
    background-color: ${(props) => props.theme.color.kycCancelButtonBackground};
    &:hover {
      color: ${(props) => props.theme.color.headerFill};
    }
    @media (max-width: 768px) {
      padding: 0;
      height: 36px;
      width: 52px;
      margin: 0;
      justify-content: center;
    }
    span {
      white-space: nowrap;
      margin-right: 4px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const KycHeader = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile()
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const { currentStep, title, numberOfSteps, transparent = false } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleShowContinueLaterModal = () => {
    setIsOpen(!isOpen);
  };

  const getLogoIcon = () => {
    if (isMobile && !transparent) {
      return isDarkMode ? 'naga-trader-icon-mobile-dark.svg' : 'naga-trader-icon-mobile.svg';
    }
    return isDarkMode ? 'naga-trader-logo-light.svg' : 'naga-trader-logo-black.svg';
  };

  return (
    <KycHeaderWrapper>
      <div className={`kyc-header__wrapper ${currentStep ? 'kyc-header__col3' : 'kyc-header__col1'}`}>
        <a href="/" className={`kyc-header__logo ${transparent && 'kyc-header__logo--transparent'}`}>
          <img src={`${constants.IMAGES_URL}/${getLogoIcon()}`} alt="logo" />
        </a>
        {!transparent && (
          <Fragment>
            <div>
              <p className="kyc-header__title">{title}</p>
              {numberOfSteps ? <p className="kyc-header__title-mobile">{`${t('KYC.QUESTION')} ${currentStep}/${numberOfSteps}`}</p> : null}
            </div>
            <div className="kyc-header__progress">
              {numberOfSteps ? <p>{`${t('KYC.QUESTION')} ${currentStep}/${numberOfSteps}`}</p> : null}
              <Button className="kyc-header__button" bsStyle="primary" onClick={handleShowContinueLaterModal}>
                <span>{t('KYC.CONTINUE_LATER')}</span>
                <img src={`${constants.IMAGES_URL}/ico-x.svg`} alt="close" />
              </Button>
            </div>
          </Fragment>
        )}
        <KycContinueLaterModal onCancel={handleShowContinueLaterModal} isOpen={isOpen} handleShowContinueLaterModal={handleShowContinueLaterModal} />
      </div>
    </KycHeaderWrapper>
  );
};

export default KycHeader;
