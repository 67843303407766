import { format } from "date-fns";

import constants from "../constants";
import axios from "./httpInstance";

const userInfo = () => {
  return axios.get("/user/info");
};

const countries = () => {
  return axios.get("/country/all");
};

const getTradingAccounts = () => {
  return axios.post("/broker/list_linked_accounts");
};

const getCurrencies = async () => {
  const currencies = {};
  const finalResult = [];
  const response = await axios.get("currencies", {
    params: {
      tradable: true,
      include_fiat: true,
    },
  });

  const result = response.data.data.currencies.concat(response.data?.data.fiatCurrencies);

  result.forEach((currency) => {
    const currencyBrokers =
      (currency.additional_data && currency.additional_data.broker_servers) || [];

    currencyBrokers.forEach((brokerId) => {
      if (constants.MT4_BROKER_SERVER_IDS.indexOf(brokerId) > -1) {
        currency.isMT4 = true;
      }

      if (constants.MT5_BROKER_SERVER_IDS.indexOf(brokerId) > -1) {
        currency.isMT5 = true;
      }
    });

    currencies[currency.currency.toLowerCase()] = currency;
  });

  Object.keys(currencies).forEach((key) => {
    if (currencies[key].can_trade && currencies[key].isMT4) {
      finalResult.push(currencies[key]);
    }
  });

  return finalResult;
};

const kycForm = (brokerId, countryCode) => {
  return axios({
    method: "GET",
    url: `/kyc/form/${brokerId}`,
    params: { country_code: countryCode, type: `upgrade` },
    transformRequest: [
      function (data, headers) {
        headers["accept-version"] = constants.ACCEPT_VERSION.TWO;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const kycScores = () => {
  return axios({
    method: "GET",
    url: `/kyc/scores`,
    transformRequest: [
      function (data, headers) {
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const getTradingAccountPassword = (terminalId, accountNumber) => {
  return axios.get("/broker/get_password", {
    params: {
      terminal_id: terminalId,
      account_id: accountNumber,
    },
  });
};

const getTerminalLimits = (terminalId, destination) => {
  return axios.post("/payment/withdraw/limits", {
    terminal_id: terminalId,
    destination,
  });
};

const getCopiersStats = (terminalId) => {
  return axios.get(`/copy_wallet/get_copiers_stats/${terminalId}`);
};

const getNetAmountForWithdrawal = async (data) => {
  const response = await axios.post("/payment/withdraw/net_amount", data);

  return response?.data?.data;
};

const getDocumentTypes = (data) => {
  return axios.post("/user/documents/get_user_document_types", data);
};

const getVerificationData = () => {
  return axios({
    method: "GET",
    url: `/verification/data`,
    transformRequest: [
      function (data, headers) {
        headers.platform = constants.PLATFORM;
        headers["accept-version"] = constants.ACCEPT_VERSION.TWO;
        headers["Content-Type"] = "application/json";

        return JSON.stringify(data);
      },
    ],
  });
};

const getUploadUrl = (params) => {
  return axios.get("/user/upload/url", { params });
};

const getDepositTransactions = (terminalId) => {
  return axios.post("payment/transactions", { terminal_id: terminalId });
};

const getTransferHistory = (terminalId) => {
  return axios({
    method: 'GET',
    url: '/naga/wallet/transactions',
    params: {
      provider: 'internal',
      terminal_id: terminalId,
    },
  });
};

const getWithdrawalsTransactions = async (terminalId) => {
  const response = await axios.get("/payment/withdraw/history", {
    params: {
      event_type: "Withdrawal",
      terminal_id: terminalId,
    },
  });

  return response.data?.data || [];
};

const getDocument = async (documentId) => {
  const response = await axios.get(`/user/document/${documentId}`);

  const document = response.data.data || null;

  return document;
};

const getDocuments = async (documentTypeId) => {
  const response = await axios.post("/user/documents/get", {
    document_type_id: documentTypeId,
  });
  const documents = response.data.data || [];

  documents.forEach((document) => {
    document.document_id = parseInt(document.document_id, 10);
    document.creation_date = format(new Date(document.creation_date), "dd/MM/yyyy");
    document.expiration_date =
      document.expiration_date && format(new Date(document.expiration_date), "dd/MM/yyyy");
  });

  return documents;
};

const getDocumentRequests = async (requestStatuses) => {
  const response = await axios.get("/user/document_requests", {
    params: {
      request_statuses: (requestStatuses && requestStatuses.join(",")) || null,
    },
  });

  const documentRequests = response.data.data || [];

  const requests = documentRequests.reduce((requestList, request) => {
    request.creation_date =
      request.document_creation_date &&
      format(new Date(request.document_creation_date), "dd/MM/yyyy");
    request.expiration_date =
      request.document_expiration_date &&
      format(new Date(request.document_expiration_date), "dd/MM/yyyy");
    request.custom_message =
      (Array.isArray(request.translated_messages) &&
        request.translated_messages.length > 0 &&
        request.translated_messages[0]) ||
      request.reason_message ||
      request.message;
    request.isRequest = true;

    if (
      request.request_status === constants.DOCUMENT_REQUEST_STATUS.REQUESTED &&
      request.document_status === constants.DOCUMENT_STATUS.REJECTED
    ) {
      request.request_status = constants.DOCUMENT_REQUEST_STATUS.REJECTED;
    }

    requestList.push(request);

    return requestList;
  }, []);

  return requests;
};

const ibDepositLimits = (terminalId, provider) => {
  return axios.get("payments/ib_deposit_limits", {
    params: { terminal_id: terminalId, provider },
  });
};

const getLookups = async (lookupType, enabled = null) => {
  const response = await axios.get(`/lookups/${lookupType}/${enabled ? enabled : ""}`);

  return response.data.data;
};

const getPaymentGates = async (direction) => {
  const response = await axios.get("payments/payment_gates_endpoints", {
    params: {
      direction,
    },
  });

  return response.data.data || [];
};

const getTransactionInfo = (payload) => {
  return axios.post("/payments/mpsa/transaction_info", payload);
};

const getCrossRate = async (fromCurrency, toCurrency) => {
  const response = await axios.post("payment/cross_rate", {
    p_currency_from: fromCurrency,
    p_currency_to: toCurrency,
  });

  return response.data.data[0]?.get_cross_rate || 0;
};

const getMFAKeys = async () => {
  const response = await axios({
    method: "GET",
    url: "/mfa/new",
  });

  return response.data.data || [];
};

const getRecoveryCodes = async () => {
  const response = await axios({
    method: "GET",
    url: "/mfa/generate/recovery",
  });

  return response.data.data || [];
};

const getWalletAddressesAndRates = async () => {
  const response = await axios({
    method: "GET",
    url: "/naga/wallet/addresses",
  });
  return response.data.data || [];
};

const isCryptoAddressValid = data => {
  return axios({
    method: 'GET',
    url: '/naga/wallet/is_valid_address',
    params: {
      crypto_address: data.address,
      currency_code: data.currency,
    },
  });
};


const queries = {
  userInfo,
  countries,
  getTradingAccounts,
  getCurrencies,
  kycForm,
  kycScores,
  getTradingAccountPassword,
  getTerminalLimits,
  getCopiersStats,
  getNetAmountForWithdrawal,
  getDocumentTypes,
  getVerificationData,
  getUploadUrl,
  getDepositTransactions,
  getTransferHistory,
  getDocument,
  getDocuments,
  getDocumentRequests,
  ibDepositLimits,
  getLookups,
  getWithdrawalsTransactions,
  getPaymentGates,
  getTransactionInfo,
  getCrossRate,
  getMFAKeys,
  getRecoveryCodes,
  getWalletAddressesAndRates,
  isCryptoAddressValid,
};

export default queries;
