import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalButton, ModalHeader } from "@my-swipestox/components";

import utils from "../../utils";
import api from "../../api";
import constants from "../../constants";

import Loader from "../../components/loader/Loader";
import DocumentDatePicker from "./DocumentDatePicker";
import DocumentImageUpload from "./DocumentImageUpload";

const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
`;

const DocumentMessage = styled.div`
  margin-bottom: 20px;
`;

const DocumentCell = styled.div`
  display: flex;
  flex-direction: column;
  &:first-of-type {
    max-width: initial;
    padding-right: initial;
  }
  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    &:first-of-type {
      max-width: 55%;
      padding-right: 40px;
    }
    &:nth-of-type(n + 2) {
      margin-top: 0;
    }
  }
`;

const DocumentDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    flex-direction: row;
  }
`;

const DocumentImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 256px;
  max-width: 100%;
  margin-top: 30px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 3px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const DocumentModal = (props) => {
  const {
    document,
    setDocument,
    documents,
    setDocuments,
    documentRequests,
    setDocumentRequests,
  } = props;
  const { t } = useTranslation();

  const [documentExpirationDate, setDocumentExpirationDate] = useState(null);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [file, setFile] = useState(null);

  const documentsQuery = useQuery(
    constants.QUERY_NAMES.document,
    () => api.queries.getDocument(document.document_id),
    {
      enabled: !utils.isEmpty(document) && !document.isRequest,
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );

  const handleDocumentSubmit = async () => {
    try {
      if (!file) return;

      setUploadingDocument(true);

      const uploadUrlParams = { name: file.name, type: "document" };
      const response = await api.queries.getUploadUrl(uploadUrlParams);
      const responseData = response.data.data;
      const uploadUrl = responseData.upload_url;
      const downloadUrl = responseData.download_url.source;

      const documentPayload = {
        document_id: null,
        document_type: document.document_type,
        document_type_id: document.document_type_id,
        document_url: downloadUrl,
        expiration_date: documentExpirationDate || null,
        is_expired: false,
        reason_code: null,
        document_side: document.side || constants.DOCUMENT_SIDE.FRONT,
        status: null,
        additional_data: null,
      };

      await api.mutations.uploadDocument(uploadUrl, file);

      const documentResponse = await api.mutations.saveUserDocuments(documentPayload);
      const documentResponseData = documentResponse.data.data;
      const documentId = documentResponseData.save_document;

      documentPayload.document_id = parseInt(documentId, 10);
      documentPayload.creation_date = moment().format("DD/MM/YYY");
      documentPayload.expiration_date =
        documentPayload.expiration_date &&
        moment(documentPayload.expiration_date).format("DD/MM/YYY");
      documentPayload.document_group = document.document_group;
      documentPayload.status = constants.DOCUMENT_STATUS.NEW;

      setDocumentRequests(
        documentRequests.filter(
          (request) => request.document_request_id !== document.document_request_id
        )
      );
      setDocuments([documentPayload, ...documents]);
      closeModal();
      toast.success(t("DOCUMENTS.THE_DOCUMENT_HAS_BEEN_SUBMITTED"));
    } catch (err) {
      toast.error(t("DOCUMENTS.ERROR_OCCURRED_WHILE_SUBMITTING_DOCUMENT"));
    } finally {
      setUploadingDocument(false);
    }
  };

  const closeModal = () => {
    setFile(null);
    setDocumentExpirationDate(null);
    setUploadingDocument(false);
    setDocument({});
  };

  const expirationDateRequired = document.isRequest && !!document.min_expiry_days;

  return (
    <Modal isOpen={!utils.isEmpty(document)} dismissOnClickOutside={false} onClose={closeModal}>
      <ModalHeader>
        {t(document.isRequest ? "DOCUMENTS.REQUESTED_DOCUMENT" : "DOCUMENTS.UPLOADED_DOCUMENT")}
      </ModalHeader>
      <ModalBody>
        {document.custom_message && <DocumentMessage>{document.custom_message}</DocumentMessage>}
        <DocumentDetails>
          <DocumentCell>
            <Label>{t("DOCUMENTS.DOCUMENT_TYPE")}</Label>
            {document.document_type}
          </DocumentCell>
          <DocumentCell>
            <Label>
              {t(
                document.min_expiry_days > 0
                  ? "DOCUMENTS.EXPIRATION_DATE"
                  : "DOCUMENTS.ISSUING_DATE"
              )}
            </Label>
            {expirationDateRequired ? (
              <DocumentDatePicker
                date={documentExpirationDate}
                onDateChange={setDocumentExpirationDate}
                documentMinExpiryDays={document.min_expiry_days}
              />
            ) : (
              document.expiration_date || "-"
            )}
          </DocumentCell>
        </DocumentDetails>
        {document.isRequest ? (
          <DocumentImageUpload
            fileSelected={!!file}
            onFileSelected={setFile}
            onFileUnselected={() => setFile(null)}
            uploadInProgress={uploadingDocument}
          />
        ) : (
          <DocumentImage>
            {documentsQuery.isFetching ? (
              <Loader message={t("DOCUMENTS.LOADING_DOCUMENT_IMAGE")} />
            ) : (
              <img src={documentsQuery.data?.document_signed_url} alt="Document preview" />
            )}
          </DocumentImage>
        )}
      </ModalBody>
      {document.isRequest && (
        <ModalFooter>
          <ModalButton
            type="button"
            disabled={uploadingDocument}
            onClick={closeModal}
            kind="secondary"
          >
            {t("DOCUMENTS.CANCEL")}
          </ModalButton>
          <ModalButton
            type="button"
            disabled={
              !file || uploadingDocument || (expirationDateRequired && !documentExpirationDate)
            }
            onClick={handleDocumentSubmit}
          >
            {t("DOCUMENTS.SUBMIT")}
          </ModalButton>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DocumentModal;
