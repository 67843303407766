import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "@my-swipestox/components";

import state from "../../state";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";

import FileUpload from "../../components/file-upload/FileUpload";

const ImageUpload = styled(FileUpload)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 256px;
  max-width: 436px;
  margin-top: 30px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  border: 2px solid #ee3142;
  border-style: dashed;
  z-index: 1;
  overflow: hidden;
  &:after {
    content: "";
    background: ${(props) =>
      props.fileSelected ? "none" : `url("${constants.IMAGES_URL}/image-upload-bg.png")`};
    opacity: ${(props) => (props.isDarkMode ? "0.15" : "0.3")};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transition: transform 0.3s ease-out;
  }
  &:hover {
    border: 2px solid #ee3142;
    border-style: dashed;
    background-color: ${(props) => props.theme.color.backgroundPrimary};
    &:after {
      transform: scale(1.1);
      transition: transform 0.3s ease-in;
    }
  }
  > div {
    &:nth-of-type(1) {
      font-size: 1rem;
    }
    &:nth-of-type(2) {
      display: inline-block;
      color: #7f93bc;
      font-size: ${(props) => props.theme.typography.size.smallText};
      button {
        padding-left: 0px;
      }
    }
    &:nth-of-type(3) {
      display: none;
    }
  }
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 434px;
  }
`;

const ButtonLink = styled(Button)`
  color: ${(props) => props.theme.color.primary};
  background-color: transparent;
  border: 0;
  font-weight: 500;
`;

const DocumentImageUpload = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);

  return (
    <ImageUpload
      title={t("DOCUMENTS.UPLOAD_DOCUMENT")}
      subtitle={
        isMobile ? (
          t("DOCUMENTS.TAP_TO_UPLOAD")
        ) : (
          <Trans
            defaults="DOCUMENTS.DRAG_FILE_TO_UPLOAD"
            values={{ chooseFile: t("DOCUMENTS.CHOOSE_FILE") }}
            components={[<ButtonLink type="button" />]}
          />
        )
      }
      isDarkMode={isDarkMode}
      {...props}
    />
  );
};

export default DocumentImageUpload;
