import { useState, Fragment, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  Input,
  Button,
  FormControl,
  ParagraphSmall,
  ParagraphLarge,
} from "@my-swipestox/components";

import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";

import Geetest from "../../components/geetest/Geetest";
import UnauthorizedHeader from "../header/UnauthorizedHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const Content = styled.div`
  border-radius: 3px;
  max-width: 32rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const FormWrapper = styled.div`
  padding: 4rem 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    padding: 4rem;
  }
`;

const StyledSubheadline = styled(ParagraphLarge)`
  text-align: center;
`;

const StyledSmallText = styled(ParagraphSmall)`
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const StyledLink = styled.span`
  color: ${(props) => props.theme.color.primary};
  cursor: pointer;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ResetPassword = () => {
  const resetPasswordMutation = useMutation(api.mutations.sendResetPasswordEmail, {
    onError: () => toast.error(t("REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER")),
    onSuccess: () => setIsSuccess(true),
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const usernameRef = useRef(null);
  const onSubmit = (values) => {
    captcha.showCaptcha();
    usernameRef.current = values.username;
  };
  const onCaptchaSuccess = (captcha) => {
    const data = {
      login: usernameRef.current,
      captcha,
    };
    resetPasswordMutation.mutate(data);
  };
  const { handleSubmit, errors, control } = useForm();
  const { t } = useTranslation();
  const reason = utils.getParamByName("reason");
  const history = useHistory();
  const handleOnLoginClick = () => {
    if (reason) {
      return history.push(`/login?reason=${reason}`);
    }
    return history.push(`/login`);
  };

  return (
    <Grid>
      <UnauthorizedHeader />
      <Wrapper>
        <Content>
          {isSuccess ? (
            <FormWrapper>
              <StyledSubheadline mb="1rem">
                {t("RESET_PASSWORD.CHECK_YOUR_INBOX")}
              </StyledSubheadline>
              <StyledSmallText>
                {t("RESET_PASSWORD.WE_HAVE_SENT_PASSWORD_RESET_INSTRUCTIONS_TO_YOUR_EMAIL")}
              </StyledSmallText>
            </FormWrapper>
          ) : (
            <Fragment>
              <FormWrapper>
                <StyledSubheadline mb="1rem">
                  {t("RESET_PASSWORD.FORGOT_PASSWORD")}
                </StyledSubheadline>
                <StyledSmallText>
                  {t("RESET_PASSWORD.ENTER_THE_EMAIL_ADDRESS_ASSOCIATED_WITH_YOUR_ACCOUNT")}
                </StyledSmallText>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl
                    caption={(errors.username && errors.username.message) || ""}
                    error={errors.username}
                    mb="1rem"
                  >
                    <Controller
                      type="text"
                      name="username"
                      placeholder={t("RESET_PASSWORD.EMAIL_OR_USERNAME")}
                      rules={{
                        required: t("REGISTRATION.FIRST_NAME_IS_REQUIRED"),
                      }}
                      as={Input}
                      control={control}
                    />
                  </FormControl>
                  <FormControl mb="1rem">
                    <Button
                      shouldFitContainer
                      type="submit"
                      disabled={resetPasswordMutation.isLoading}
                      isLoading={resetPasswordMutation.isLoading}
                    >
                      <span>{t("RESET_PASSWORD.SEND_RESET_LINK")}</span>
                    </Button>
                  </FormControl>
                  <CaptchaWrapper>
                    <Geetest captcha={captcha} setCaptcha={setCaptcha} onSuccess={onCaptchaSuccess} />
                  </CaptchaWrapper>
                </form>
              </FormWrapper>
              <Footer>
                <ParagraphSmall as="span">
                  {t("RESET_PASSWORD.REMEMBERED_YOUR_PASSWORD")}{" "}
                  <StyledLink onClick={handleOnLoginClick}>
                    {t("RESET_PASSWORD.SIGN_IN")}
                  </StyledLink>
                </ParagraphSmall>
              </Footer>
            </Fragment>
          )}
        </Content>
      </Wrapper>
    </Grid>
  );
};

export default ResetPassword;
