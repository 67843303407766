import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from "@my-swipestox/components";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

import KycHeader from './KycHeader';

const KycLandingPageContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  background: ${(props) => props.theme.color.kycBackground};
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  white-space: pre-wrap;
  h4 {
    margin-bottom: 40px;
  }
  .kyc-introduction__btn {
    margin-top: 52px;
    margin-bottom: 16px;
    padding: 14px 80px;
    font-size: 16px;
  }
  .kyc-introduction__account-types {
    display: flex;
    span {
      color: #3179ff;
      cursor: pointer;
      margin: 0 4px;
    }
    p {
      color: #7f93bc;
    }
  }
  .kyc-introduction__titleWrapper {
    background: linear-gradient(270.55deg, #3179ff 21.86%, #0450de 101.99%);
    border-radius: 8px;
    margin-bottom: 48px;
    margin-top: 20px;
    width: 80%;
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
    }
  }
  .kyc-introduction__title {
    font-size: 32px;
    font-weight: 600;
    padding: 60px;
    color: white;
    white-space: pre-wrap;

  }
  .kyc-introduction__reassessment-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .kyc-introduction__reassessment-btn {
    margin-bottom: 56px;
    margin-top: 102px;
    width: 390px;
    @media (max-width: 768px) {
      width: 350px;
    }
  }
  .kyc-introduction__reassessment-content {
    margin-bottom: 16px;
    color: #7f93bc;
  }
  .kyc-introduction__reassessment-link {
    color: #3179FF;
    cursor: pointer;
  }
  .kyc-introduction__reassessment-illustration {
    margin-top: 72px;
    margin-bottom: 24px;
    width: 148px;
    height: 148px;
  }
  .kyc-introduction__content {
    white-space: pre-wrap;
    margin-bottom: 32px;
  }
  .kyc-introduction__warning {
    border-radius: 4px;
    margin-bottom: 48px;
    padding: 8px 32px;
    background-color: rgba(238, 49, 66, 0.1);
    display: flex;
    align-items: center;
    p {
      color: $app-red-color;
      margin: 0;
    }
    img {
      height: 18px;
      width: 18px;
      margin-right: 20px;
    }
  }
  .kyc-introduction__acc-types {
    margin-bottom: 32px;
  }
`;

const KycLandingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const startVerification = () => history.push('/live-account-questionnaire')

  return (
    <KycLandingPageContainer>
      <KycHeader transparent />
      <div className="kyc-introduction__titleWrapper">
        <h1 className="kyc-introduction__title">{t('KYC_INTRO.TITLE')}</h1>
      </div>
      <p className="kyc-introduction__content">{t('KYC_INTRO.CONTENT')}</p>
      <Button className="kyc-introduction__btn" bsStyle="primary" onClick={startVerification}>
        {t('KYC_INTRO.START_VERIFICATION')}
      </Button>
    </KycLandingPageContainer>
  );
};

export default KycLandingPage;
