import styled from "styled-components";
import { useTranslation } from "react-i18next";

import state from "../../state";

import constants from "../../constants";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const Image = styled.img`
  margin-right: 10px;
`;

const EmptyDocumentRequests = () => {
  const { t } = useTranslation();
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);

  return (
    <Wrapper>
      <Image
        src={`${constants.IMAGES_URL}/${isDarkMode ? "my-documents-dark.svg" : "my-documents.svg"}`}
        alt="All requested documents uploaded"
      />
      <div>
        <div>{t("DOCUMENTS.YOU_PROVIDED_ALL_REQUESTED_DOCUMENTS")}</div>
        <div>{t("DOCUMENTS.ADDITIONAL_REQUESTS_WILL_APPEAR_HERE")}</div>
      </div>
    </Wrapper>
  );
};

export default EmptyDocumentRequests;
