import MQTT from "async-mqtt";

import constants from "../constants";
import cacheHelper from "../helpers/cacheHelper";
import state from "../state";

const topics = [];
let client = null;

export async function connect() {
  const username = cacheHelper.getFingerprint() || "china-app";
  const password = state.useAuth.getState().token;
  try {
    const clientInstance = await MQTT.connectAsync(constants.MQTT_URL, {
      username,
      password,
    });

    clientInstance.on("error", (error) => {
      console.error("An error occurred while tying to connet to MQTT", error);
    });

    clientInstance.on("end", (payload) => {
      console.info("Connection to MQTT has ended", payload);
    });

    clientInstance.on("close", (payload) => {
      console.error("Connection to MQTT has been closed", payload);
    });

    clientInstance.on("disconnect", (payload) => {
      console.error("Connection to MQTT has been disconnected", payload);
    });

    clientInstance.on("connect", (payload) => {
      console.info("Client connected to MQTT sucessfully", payload);
    });

    client = clientInstance;
  } catch (error) {
    console.error("An error occurred while tying to connet to MQTT", error);
  }
}

export function on(event) {
  client.on(event, function (_topic, message) {
    const messageString = message && JSON.parse(message.toString());

    const userInfoState = state.useUserInfo.getState((state) => state.userInfo);

    state.useUserInfo.setState({
      userInfo: {
        ...userInfoState.userInfo,
        user_bo_status: messageString.user_bo_status,
      },
    });
    state.useMqttInfo.setState({ mqttInfo: messageString });
  });
}

export async function subscribe(topic) {
  try {
    topics.push(topic);
    await client.subscribe(topic);
  } catch (e) {
    if (!constants.IS_PROD_ENV) {
      console.error(e);
    }
  }
}

export async function unsubscribeAll() {
  try {
    await client.unsubscribe(topics);
    while (topics.length) {
      topics.pop();
    }
  } catch (e) {
    if (!constants.IS_PROD_ENV) {
      console.error(e);
    }
  }
}

export async function disconnect() {
  try {
    await client.end(true);
    state.useMqttInfo.setState({ mqttInfo: {} });
  } catch (e) {
    if (!constants.IS_PROD_ENV) {
      console.error(e);
    }
  }
}

const mqtt = {
  connect,
  subscribe,
  on,
  unsubscribeAll,
  disconnect,
};

export default mqtt;
