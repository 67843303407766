import styled from "styled-components";
import PropTypes from "prop-types";

const NoTransactionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  padding: 2rem;
  min-height: 20rem;
  text-align: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const NoDataTitle = styled.div`
  font-size: ${(props) => props.theme.typography.size.subheadline};
  margin-bottom: 1rem;
`;

const NoDataDesc = styled.div`
  color: ${(props) => props.theme.color.contentSecondary};
  font-size: ${(props) => props.theme.typography.size.smallText};
`;

const NoTransactions = (props) => {
  const { title, desc } = props;

  return (
    <NoTransactionsWrapper>
      <NoDataTitle>{title}</NoDataTitle>
      <NoDataDesc>{desc}</NoDataDesc>
    </NoTransactionsWrapper>
  );
};

NoTransactions.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default NoTransactions;
