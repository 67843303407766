import { useEffect, useState, Fragment } from "react";
import { MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button } from "@my-swipestox/components";
import { toast } from "react-toastify";
import styled from "styled-components";
import { StatefulPopover } from "@malcodeman/react-popover";

import api from "../../api";
import Amount from "../../components/amount/Amount";
import constants from "../../constants";
import WithdrawalHistoryContextMenu from "./WithdrawalHistoryContextMenu";
import Loader from "../../components/loader/Loader";
import utils from "../../utils";
import NoTransactions from "./NoTransactions";
import useIsMobile from "../../hooks/useIsMobile";

const TransactionContainer = styled.div`
  border: 1px solid ${(props) => props.theme.color.border};
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-column-gap: 0;
  grid-row-gap: 2px;
  background-color: ${(props) => props.theme.color.border};
  align-items: center;
`;

const HeaderCell = styled.span`
  font-size: 0.8rem;
  height: 100%;
  padding: 7px 10px;
  color: ${(props) => props.theme.color.contentSecondary};
  display: flex;
  align-items: center;
`;

const RowCell = styled.div`
  font-size: 0.9rem;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  padding: 1rem 7px;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 300;
  color: ${(props) => {
    if (props.status === constants.WITHDRAWAL_STATUS.PENDING) {
      return props.theme.color.warning;
    } else if (props.status === constants.WITHDRAWAL_STATUS.REJECTED) {
      return props.theme.color.negative;
    }
    return props.theme.color.contentPrimary;
  }};
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0;
`;

const MoreHorizontalCustom = styled(MoreHorizontal)`
  color: ${(props) => props.theme.color.contentSecondary};
  cursor: pointer;
`;

const MobileItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const MobileRow = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const MobileRowLabel = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.color.contentSecondary};
  &::after {
    content: ":";
  }
`;

const CancelButton = styled(Button)`
  margin-top: 10px;
`;

const WithdrawalHistory = (props) => {
  const { fullAccount } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [executedTransactions, setExecutedTransactions] = useState([]);
  const cancelRequestMutation = useMutation(
    (withdrawalId) => {
      return api.mutations.cancelWithdrawalRequest(withdrawalId);
    },
    {
      onSuccess: (res, params) => {
        toast.success(
          t("MANAGE_MONEY.WITHDRAWAL_HISTORY.CANCEL_SUCCESS", { withdrawalId: params })
        );
        queryClient.invalidateQueries(constants.QUERY_NAMES.withdrawalTransactions);
        queryClient.invalidateQueries(constants.QUERY_NAMES.terminalLimits);
        queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const withdrawalHistoryResponse = useQuery(
    [constants.QUERY_NAMES.withdrawalTransactions, fullAccount.terminal_id],
    () => {
      return api.queries.getWithdrawalsTransactions(fullAccount.terminal_id);
    }
  );

  useEffect(() => {
    if (withdrawalHistoryResponse.data) {
      setPendingTransactions(
        withdrawalHistoryResponse.data.filter(
          (f) => f.status === constants.WITHDRAWAL_STATUS.PENDING
        )
      );
      setExecutedTransactions(
        withdrawalHistoryResponse.data.filter(
          (f) => f.status !== constants.WITHDRAWAL_STATUS.PENDING
        )
      );
    }
  }, [withdrawalHistoryResponse.data]);

  const formatDate = (date) => {
    return date ? moment(date).format("DD.MM.YYYY HH:mm") : "-";
  };

  const renderTransactions = (data, showMoreButton) => {
    if (isMobile) {
      return data.map((i) => (
        <MobileItemContainer key={i.user_withdrawal_id}>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.AMOUNT")}</MobileRowLabel>
            <span>{i.amount_text}</span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.FEE")}</MobileRowLabel>
            <Amount
              amount={i.system_data.withdraw_fee}
              noColor
              symbol={fullAccount.currency_symbol}
              decimals={fullAccount.currency_digits}
            />
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.METHOD")}</MobileRowLabel>
            <span>
              {t(constants.WITHDRAW_DESTINATIONS_TRANSLATED[i.destination]) || i.destination}
            </span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.REQUEST_DATE")}</MobileRowLabel>
            <span>{formatDate(i.creation_date)}</span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.APPROVAL_DATE")}</MobileRowLabel>
            <span>{formatDate(i.bo_check_date)}</span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.WITHDRAWAL_ID")}</MobileRowLabel>
            <span>{i.user_withdrawal_id || "-"}</span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.WITHDRAWAL_DATE")}</MobileRowLabel>
            <span>
              {i.status_time && i.status === constants.WITHDRAWAL_STATUS.WITHDRAWN
                ? formatDate(i.status_time)
                : "-"}
            </span>
          </MobileRow>
          <MobileRow>
            <MobileRowLabel>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.STATUS")}</MobileRowLabel>
            <span>{t(constants.WITHDRAWAL_STATUS_TRANSLATIONS[i.status]) || "-"}</span>
          </MobileRow>
          {showMoreButton && (
            <CancelButton onClick={() => cancelRequestMutation.mutate(i.user_withdrawal_id)}>
              {t("MANAGE_MONEY.WITHDRAWAL_HISTORY.CANCEL_REQUEST")}
            </CancelButton>
          )}
        </MobileItemContainer>
      ));
    }

    return (
      <TransactionContainer>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.AMOUNT")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.FEE")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.METHOD")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.REQUEST_DATE")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.APPROVAL_DATE")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.WITHDRAWAL_ID")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.WITHDRAWAL_DATE")}</HeaderCell>
        <HeaderCell>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.STATUS")}</HeaderCell>
        <HeaderCell></HeaderCell>
        {data.map((i) => (
          <Fragment key={i.user_withdrawal_id}>
            <RowCell>{i.amount_text}</RowCell>
            <RowCell>
              <Amount
                amount={i.system_data.withdraw_fee}
                noColor
                symbol={fullAccount.currency_symbol}
                decimals={fullAccount.currency_digits}
              />
            </RowCell>
            <RowCell>
              {t(constants.WITHDRAW_DESTINATIONS_TRANSLATED[i.destination]) || i.destination}
            </RowCell>
            <RowCell>{formatDate(i.creation_date)}</RowCell>
            <RowCell>{formatDate(i.bo_check_date)}</RowCell>
            <RowCell>{i.user_withdrawal_id || "-"}</RowCell>
            <RowCell>
              {i.status_time && i.status === constants.WITHDRAWAL_STATUS.WITHDRAWN
                ? formatDate(i.status_time)
                : "-"}
            </RowCell>
            <RowCell status={i.status}>
              {t(constants.WITHDRAWAL_STATUS_TRANSLATIONS[i.status]) || "-"}
            </RowCell>
            <RowCell>
              {showMoreButton && (
                <StatefulPopover
                  placement="bottom-end"
                  content={() => (
                    <div>
                      <WithdrawalHistoryContextMenu withdrawalId={i.user_withdrawal_id} />
                    </div>
                  )}
                >
                  <MoreHorizontalCustom size={16} />
                </StatefulPopover>
              )}
            </RowCell>
          </Fragment>
        ))}
      </TransactionContainer>
    );
  };

  if (withdrawalHistoryResponse.isLoading) {
    return <Loader text={t("MANAGE_MONEY.HISTORY.WITHDRAWALS.LOADING_TRANSACTIONS")} />;
  }

  if (utils.isEmpty(withdrawalHistoryResponse.data)) {
    return (
      <NoTransactions
        title={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS")}
        desc={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS_DESC")}
      />
    );
  }

  return (
    <div>
      <SectionTitle>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.REQUESTS")}</SectionTitle>
      {!utils.isEmpty(pendingTransactions) ? (
        renderTransactions(pendingTransactions, true)
      ) : (
        <NoTransactions
          title={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS")}
          desc={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS_DESC_PENDING")}
        />
      )}
      <SectionTitle>{t("MANAGE_MONEY.WITHDRAWAL_HISTORY.PREVIOUS")}</SectionTitle>
      {!utils.isEmpty(executedTransactions) ? (
        renderTransactions(executedTransactions)
      ) : (
        <NoTransactions
          title={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS")}
          desc={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS_DESC")}
        />
      )}
    </div>
  );
};

export default WithdrawalHistory;
