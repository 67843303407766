export function getStyles({ theme, error, positive }) {
  function getColors(isFocused) {
    if (isFocused) {
      return {
        color: theme.color.foreground,
        borderColor: theme.color.borderFocus,
        backgroundColor: theme.color.inputFillActive,
      };
    } else if (error) {
      return {
        color: theme.color.foreground,
        borderColor: theme.color.inputBorderError,
        backgroundColor: theme.color.inputFillError,
      };
    } else if (positive) {
      return {
        color: theme.color.foreground,
        borderColor: theme.color.inputBorderPositive,
        backgroundColor: theme.color.inputFillPositive,
      };
    } else {
      return {
        color: theme.color.foreground,
        borderColor: theme.color.border,
        backgroundColor: theme.color.inputFill,
      };
    }
  }

  function getFont() {
    return {
      fontFamily: theme.typography.type.primary,
      fontSize: theme.typography.size.normalText,
      fontWeight: "normal",
      lineHeight: 1.5,
    };
  }

  const styles = {
    valueContainer: (base) => ({
      ...base,
      padding: "0.25rem 0.5rem",
    }),
    input: (base) => ({
      ...base,
      margin: "0",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0.25rem 0.5rem",
      color: theme.color.foreground,
      "&:hover": {
        color: theme.color.foreground,
      },
    }),
    control: (base, state) => ({
      ...base,
      minHeight: "36px",
      boxShadow: "none",
      borderWidth: "1px",
      borderRadius: "4px",
      ...getColors(state.isFocused),
      "&:hover": {
        ...getColors(state.isFocused),
      },
    }),
    singleValue: (base) => ({
      ...base,
      ...getFont(),
      color: theme.color.foreground,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme.color.menuFill,
    }),
    option: (base, state) => ({
      ...base,
      ...getFont(),
      ...getColors(state.isSelected),
      color: theme.color.foreground,
      "&:hover": {
        backgroundColor: theme.color.menuFillHover,
      },
    }),
    noOptionsMessage: (base) => ({
      ...base,
      ...getFont(),
    }),
    placeholder: (base) => {
      return {
        ...base,
        ...getFont(),
      };
    },
  };

  return styles;
}
