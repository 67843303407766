import { format } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import styled from "styled-components";

import api from "../../api";
import Loader from "../../components/loader/Loader";
import Table from "../../components/table/Table";
import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import NoTransactions from "./NoTransactions";

const AmountCell = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencyImage = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const MobileItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const MobileRow = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const MobileRowLabel = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.color.contentSecondary};
  &::after {
    content: ":";
  }
`;

const DepositHistory = (props) => {
  const { fullAccount } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { data, isLoading } = useQuery(
    [constants.QUERY_NAMES.depositTransactions, fullAccount?.terminal_id],
    () => {
      return api.queries.getDepositTransactions(fullAccount?.terminal_id);
    },
    {
      enabled: !!fullAccount?.terminal_id,
    }
  );

  const depositTransactions = data?.data?.data?.transactions || [];

  const columns = useMemo(
    () => [
      {
        ID: 1,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.AMOUNT"),
        accessor: "amount_text",
        Cell: ({ row: { original } }) => {
          return (
            <AmountCell>
              <CurrencyImage
                src={`${constants.IMAGES_URL}/${original.currency}.svg`}
                alt={original.currency}
              />
              {original.amount_text}
            </AmountCell>
          );
        },
      },
      {
        ID: 2,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.METHOD"),
        accessor: "payment_method",
        Cell: ({ row: { original } }) => {
          return original.payment_method || "-";
        },
      },
      {
        ID: 3,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.PAYMENT_DATE"),
        accessor: "creation_date",
        Cell: ({ row: { original } }) => {
          return original.creation_date
            ? format(new Date(original.creation_date), "dd.MM.yyyy HH:mm")
            : "-";
        },
      },
      {
        ID: 4,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.FUNDS_CREDITED"),
        accessor: "received_amount_text",
        Cell: ({ row: { original } }) => {
          return original.received_amount_text || "-";
        },
      },
      {
        ID: 5,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.CREDIT_DATE"),
        accessor: "fund_time",
        Cell: ({ row: { original } }) => {
          return original.fund_time
            ? format(new Date(original.fund_time), "dd.MM.yyyy HH:mm")
            : "-";
        },
      },
      {
        ID: 6,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.STATUS"),
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return t(constants.FUND_STATUS[original.status]);
        },
      },
    ],
    [t]
  );

  if (isLoading) {
    return <Loader text={t("MANAGE_MONEY.HISTORY.DEPOSITS.LOADING_TRANSACTIONS")} />;
  }

  if (isMobile) {
    return depositTransactions.map((i) => (
      <MobileItemContainer>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.AMOUNT")}</MobileRowLabel>
          <span>{i.amount_text}</span>
        </MobileRow>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.METHOD")}</MobileRowLabel>
          <span>{i.payment_method || "-"}</span>
        </MobileRow>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.PAYMENT_DATE")}</MobileRowLabel>
          <span>
            {i.creation_date ? format(new Date(i.creation_date), "dd.MM.yyyy HH:mm") : "-"}
          </span>
        </MobileRow>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.FUNDS_CREDITED")}</MobileRowLabel>
          <span>{i.received_amount_text || "-"}</span>
        </MobileRow>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.CREDIT_DATE")}</MobileRowLabel>
          <span>{i.fund_time ? format(new Date(i.fund_time), "dd.MM.yyyy HH:mm") : "-"}</span>
        </MobileRow>
        <MobileRow>
          <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.STATUS")}</MobileRowLabel>
          <span>{t(constants.FUND_STATUS[i.status])}</span>
        </MobileRow>
      </MobileItemContainer>
    ));
  }

  return (
    <Table
      columns={columns}
      data={depositTransactions}
      noData={
        <NoTransactions
          title={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS")}
          desc={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS_DESC")}
        />
      }
    />
  );
};

export default DepositHistory;
