import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";

import api from "../../api";
import constants from "../../constants";

const LobbyItemContextMenuWrapper = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.color.border};
  min-width: 8rem;
  border-radius: 3px;
  box-shadow: 0px 2px 8px ${(props) => props.theme.color.shadow};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const LobbyItemContextMenuItem = styled.div`
  padding: 5px 10px;
  font-size: ${(props) => props.theme.typography.size.smallText};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.color.backgroundPrimary};
  }
`;

const LobbyItemContextMenu = (props) => {
  const {
    item,
    setIsModalOpen,
    setActiveItem,
    setIsChangePasswordModalOpen,
    setIsShowPasswordModalOpen,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const onLeverageUpdateSuccess = () => {
    queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
  };

  const leverageMutation = useMutation(
    (terminalId) => api.mutations.updateLeverage(terminalId),
    {
      onSuccess: onLeverageUpdateSuccess,
    }
  );

  const getLeverage = () => {
    leverageMutation.mutate(item.terminal_id);
  };

  const editAccount = () => {
    setIsModalOpen(true);
    setActiveItem(item);
  };

  const showPassword = () => {
    setIsShowPasswordModalOpen(true);
    setActiveItem(item);
  };

  const changePassword = () => {
    setIsChangePasswordModalOpen(true);
    setActiveItem(item);
  };

  return (
    <LobbyItemContextMenuWrapper>
      <LobbyItemContextMenuItem onClick={editAccount}>
        {t("LOBBY.MENU.EDIT_ACCOUNT")}
      </LobbyItemContextMenuItem>
      {!item.leverage && (
        <LobbyItemContextMenuItem onClick={getLeverage}>
          {t("LOBBY.MENU.GET_LEVERAGE")}
        </LobbyItemContextMenuItem>
      )}
      {item.can_get_passwords && (
        <LobbyItemContextMenuItem onClick={showPassword}>
          {t("LOBBY.MENU.SHOW_PASSWORD")}
        </LobbyItemContextMenuItem>
      )}
      <LobbyItemContextMenuItem onClick={changePassword}>
        {t("LOBBY.MENU.CHANGE_PASSWORD")}
      </LobbyItemContextMenuItem>
    </LobbyItemContextMenuWrapper>
  );
};

export default LobbyItemContextMenu;
