const IS_PROD_ENV = process.env.REACT_APP_ENV === "production" ? true : false;
const API_URL = IS_PROD_ENV ? "https://api-v2.swipestox.com" : (process.env.REACT_APP_ENV === "local" ? "http://localhost:3000" : "https://api-v2.sxdev.io");

const NAGA_TRADER_URL_PROD = "https://app.naga-cn.com";
const NAGA_TRADER_URL_DEV = "https://app-china.sxdev.io";
const NAGA_TRADER_URL = IS_PROD_ENV ? NAGA_TRADER_URL_PROD : NAGA_TRADER_URL_DEV;

const BREAKPOINTS = {
  SMALL_DEVICES: "576px",
  MEDIUM_DEVICES: "768px",
  LARGE_DEVICES: "992px",
  EXTRA_LARGE_DEVICES: "1200px",
};
const MAGIC_SALT = "$2a$10$0.o/S/w/i/p/e/S/A/L/T/";
const ACCEPT_VERSION = {
  ONE: "1.*",
  TWO: "2.*",
  THREE: "3.*",
  THREE_ZERO: "3.0",
  THREE_ONE: "3.1",
};
const PLATFORM = "web-china";
const S3_CONTENT = "https://content.swipestox.com";
const WALLET_S3_CONTENT = `${S3_CONTENT}/wallet`;

const MT4_BROKER_SERVER_IDS_DEV = [67, 110];
const MT4_BROKER_SERVER_IDS_PROD = [99, 78];
const MT5_BROKER_SERVER_IDS_DEV = [181, 182];
const MT5_BROKER_SERVER_IDS_PROD = [128, 130];
const MT4_BROKER_SERVER_IDS = IS_PROD_ENV ? MT4_BROKER_SERVER_IDS_PROD : MT4_BROKER_SERVER_IDS_DEV;
const MT5_BROKER_SERVER_IDS = IS_PROD_ENV ? MT5_BROKER_SERVER_IDS_PROD : MT5_BROKER_SERVER_IDS_DEV;

const TERMINAL_STATUSES = {
  VERIFIED: "V",
  READ_ONLY: "L",
};

const MT_VERSIONS = {
  MT4: {
    NAME: "MT4",
  },
  MT5: {
    NAME: "MT5",
  },
};

const TERMINAL_TYPES = {
  D: {
    value: "D",
    text: "Demo",
  },
  R: {
    value: "R",
    text: "Live",
  },
};

const MAX_LIVE_ACCOUNTS_NUMBER = 2;

const USER_STATUS_TYPE = {
  approved: "A",
  new: "N",
  demo: "D",
  read_only: "RO",
  pending: "P",
  reviewed: "R",
  waiting_CY: "W",
  archived: "X",
  wallet_only: "WO",
};

const DEFAULT_AVATAR_URL = "https://d2f911aicdllsf.cloudfront.net/avatar_photos/default-avatar.png";

const USER_MEDIA_NEW_REPO_DEV = "https://s3-eu-west-1.amazonaws.com/sxphotoresized";
const USER_MEDIA_NEW_REPO_PROD = "https://s3-eu-west-1.amazonaws.com/swipestoxphotoresized";
const PHOTO_PROFILE_URL = `${
  IS_PROD_ENV ? USER_MEDIA_NEW_REPO_PROD : USER_MEDIA_NEW_REPO_DEV
}/profile/thumbnail/`;

const IMAGES_URL = "https://content.naga-cn.com/wt_images";
const RE_CAPTCHA_KEY = "6LdowFcUAAAAAO8iMJeHAqyzyOkX8XNL8bd2pi27";
const NUMBER_OF_LOGIN_RETRYS = 3;
const MQTT_URL = IS_PROD_ENV
  ? "wss://mqtt-k8s.naga.com:443/mqtt"
  : "wss://mqtt-k8s.sxdev.io:443/mqtt";

const QUERY_NAMES = {
  tradingAccounts: "trading_accounts",
  terminalLimits: "terminal_limits",
  copiersStats: "copiers_stats",
  netAmount: "net_amount",
  depositTransactions: "deposit_transactions_history",
  transferHistory: "transfer_history",
  userInfo: "user_info",
  document: "document",
  documents: "documents",
  documentRequests: "document_requests",
  ibDepositLimits: "ib_deposit_limits",
  lookups: "lookups",
  withdrawalTransactions: "withdrawal_transactions",
  paymentGates: "payment_gates",
  getTransactionInfo: "get_transaction_info",
  cross_rate: "cross_rate",
  getMFARecoveryCodes: "get_mfa_recovery_codes",
  getMFAKeys: "get_mfa_keys",
  getWalletAddressesAndRates: "get_wallet_addresses_and_rates",
  isCryptoAddressValid: "is_crypto_address_valid",
  documentTypes: "document_types" 
};

const ERRORS = {
  1030: "ERRORS.INVALID_AMOUNT",
  1062: "ERRORS.TOO_MANY_WITHDRAWALS",
  DEFAULT: "ERRORS.DEFAULT",
};

const FUND_STATUS = {
  funded: "FUND_STATUS.FUNDED",
  rejected: "FUND_STATUS.REJECTED",
};

const BO_STATUS = {
  DEMO: "D",
  APPROVED: "A",
  NEW: "N",
  PENDING: "P",
  REVIEWED: "R",
  READ_ONLY: "RO",
};

const DOCUMENT_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NEW: "NEW",
  DELETED: "X",
};

const DOCUMENT_SIDE = {
  FRONT: "front",
  BACK: "back",
};

const DOCUMENT_GROUP = {
  PROOF_OF_IDENTITY: "PoI",
  PROOF_OF_RESIDENCE: "PoR",
  PROOF_OF_SOURCE_OF_FUNDS: "PoF",
  PAYMENT_DOCUMENT: "PD",
  GENERAL: "G",
};

const DOCUMENT_REQUEST_STATUS = {
  REQUESTED: "Requested",
  PENDING: "Pending",
  REJECTED: "Rejected",
};

const PASSWORD_REGEX = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,15}$/;
const MFA_CODE_OR_RECOVERY_CRITERIA = /^([0-9]{6}|([a-z?0-9?]{5}-[a-z?0-9?]{5}))$/;
const MFA_CODE_CRITERIA = /^[0-9]{6}$/;

const MT4_DOWNLOAD_LINKS = {
  MAC: "https://files.naga.com/nm/NAGA_MT4.dmg",
  WINDOWS_NON_EU: "https://download.mql5.com/cdn/web/22466/mt4/nagacapital4setup.exe",
  ANDROID: "https://www.metatrader4.com/zh/mobile-trading/android",
  IOS: "https://download.mql5.com/cdn/mobile/mt4/ios?server=NAGAGlobal-Demo,NAGAGlobal-Live",
};

const MT5_DOWNLOAD_LINKS = {
  WINDOWS: "https://download.mql5.com/cdn/web/22012/mt5/nagacapital5setup.exe",
};

const NAGA_LEGAL_DOCUMENTATION_URL = "https://naga-investing.com/legal-documentation";
const NAGA_FLEXIBLE_PRIVACY_POLICY_URL = "https://files.naga.com/privacy-policy-nc-v3.pdf";

const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
// min and max limits are provided by mpsa and are expressed in CNY currency
const PAYMENT_OPTIONS = {
  peerToPeer: {
    label: "MANAGE_MONEY.PEER_TO_PEER",
    value: "P2P",
    minAmount: 100,
    maxAmount: 50000,
  }
};

const ZOTAPAY = {
  label: "MANAGE_MONEY.ZOTAPAY",
  value: "zotapay",
  minAmount: 100,
  maxAmount: 50000,
};

const NACEPAY = {
  label: "MANAGE_MONEY.NACEPAY",
  value: "nacepay",
  minAmount: 100,
  maxAmount: 50000,
};

const NUCLEUS = {
  label: "MANAGE_MONEY.NUCLEUS",
  value: "nucleus365",
  minAmount: 100,
  maxAmount: 80000,
};

const PAYMENT_PROVIDERS = {
  ZOTAPAY: "zotapay",
  NACEPAY: "nacepay",
  NUCLEUS: "nucleus365"
};

const WITHDRAWAL_STATUS_TRANSLATIONS = {
  P: "TRANSACTION_STATUS.PENDING_APPROVAL",
  X: "TRANSACTION_STATUS.CANCELLED",
  C: "TRANSACTION_STATUS.CANCELLED",
  W: "TRANSACTION_STATUS.WITHDRAWN",
  E: "TRANSACTION_STATUS.ERROR",
  R: "TRANSACTION_STATUS.REJECTED",
  N: "TRANSACTION_STATUS.NEW",
};

const WITHDRAWAL_STATUS = {
  PENDING: "P",
  CANCELLED: "X",
  NEW: "N",
  WITHDRAWN: "W",
  ERROR: "E",
  REJECTED: "R",
};

export const WITHDRAW_DESTINATIONS_TRANSLATED = {
  zotapay: "WITHDRAW.BANK_ACCOUNT",
  nganluong: "WITHDRAW.BANK_ACCOUNT",
  fasapay: "WITHDRAW.BANK_ACCOUNT",
  bank_transfer: "WITHDRAW.BANK_ACCOUNT",
  cardpay_apm: "WITHDRAW.BANK_ACCOUNT",
  safecharge: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  neteller: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  skrill: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  sofort: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  giropay: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  webmoney: "WITHDRAW.ALTERNATIVE_PAYMENT_METHOD",
  iban: "WITHDRAW.NAGA_CARD",
  card: "WITHDRAW.NAGA_CARD",
  cardpay: "PAYMENT_TYPES.CREDIT_CARD",
  paypal: "WITHDRAW.PAYPAL",
  mpsa: "WITHDRAW.MPSA",
};

const SUPPORTED_CURRENCIES = ["USD"];
const SUPPORTED_COUNTRY_CODES = ['TW', 'MO', 'CN'];
const SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'zh-hans', label: '汉语' },
  { value: 'zh-hant', label: '繁體中文' },
];

const SERVICE_EMAIL = "chinasupport@naga.com";
const SERVICE_PHONE_NUMBER = "+86108007142515";

const INTERCOM_APP_ID = "uzsmlojy";

// NEW

const MFA_STEPS = {
  INITIAL: "initial",
  ENTER_KEY: "enterKey",
  MFA_ENABLED: "mfaEnabled",
  RECOVERY_CODES: "recoveryCodes",
};

const MFA_CODE_LENGTH = 6;
const MFA_RECOVERY_CODE_LENGTH = 11;

const MFA_STATUS_CODES = {
  2403: "STATUS_CODE.INVALID_6_DIGIT_CODE",
  2402: "STATUS_CODE.INVALID_SECRET_KEY",
  2401: "STATUS_CODE.INVALID_OR_USED_RECOVERY_KEY",
  1080: "STATUS_CODE.INVALID_PLATFORM_LOGIN",
};

const LOGIN_STATUS_CODES = {
  1080: "STATUS_CODE.INVALID_PLATFORM_LOGIN",
}

const MODAL_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
};

const USER_TOKENS = {
  TOKEN: { name: "token", cacheKey: "token" },
  XSRF: { name: "xsrf", cacheKey: "xsrf" },
  REFRESH_TOKEN: { name: "refreshToken", cacheKey: "refreshToken" },
  REFRESH_TOKEN_EXPIRE_TIME: { name: "token_expiration", cacheKey: "refreshTokenExpireTime" },
};

const CACHED_ITEMS = {
  FINGERPRINT: "fingerprint",
  RETRY_COUNT: "retryCount",
  USER_INFO: "userInfo",
  DARK_MODE: "darkMode",
};

const TOKEN_STRATEGY = "JWT";

const REQUEST_RETRY_COUNT = 3;

const GEETEST_ID = IS_PROD_ENV ? 'b0e46157c31d2eb5e061401520acd3a9' : 'fbda7d8d677a7d32037edb7108412b3f';

const CRYPTO_CURRENCIES = ["NGC", "BTC", "ETH", "BNB", "UST"];

const TRANSACTION_STATUS = {
  ALL: 'all',
};

const TRANSACTION_STATUSES = [
  {
    key: 'TRANSACTION_STATUS.BEING_PROCESSED',
    value: 'Being processed',
  },
  {
    key: 'TRANSACTION_STATUS.PENDING',
    value: 'Pending',
  },
  {
    key: 'TRANSACTION_STATUS.PENDING_APPROVAL',
    value: 'Pending approval',
  },
  {
    key: 'TRANSACTION_STATUS.APPROVED',
    value: 'Approved',
  },
  {
    key: 'TRANSACTION_STATUS.DECLINED',
    value: 'Declined',
  },
  {
    key: 'TRANSACTION_STATUS.FUNDED',
    value: 'Funded',
  },
  {
    key: 'TRANSACTION_STATUS.ERROR',
    value: 'Error',
  },
  {
    key: 'TRANSACTION_STATUS.UNKNOWN',
    value: 'Unknown',
  },
  {
    key: 'TRANSACTION_STATUS.CANCELLED',
    value: 'Cancelled',
  },
  {
    key: 'TRANSACTION_STATUS.WITHDRAWN',
    value: 'Withdrawn',
  },
  {
    key: 'TRANSACTION_STATUS.REJECTED',
    value: 'Rejected',
  },
  {
    key: 'TRANSACTION_STATUS.NEW',
    value: 'New',
  },
  {
    key: 'TRANSACTION_STATUS.EARNED',
    value: 'Earned',
  },
  {
    key: 'TRANSACTION_STATUS.REFUNDED',
    value: 'Refunded',
  },
  {
    key: 'TRANSACTION_STATUS.ABANDONED',
    value: 'Abandoned',
  },
  {
    key: 'TRANSACTION_STATUS.SENT',
    value: 'Sent',
  },
  {
    key: 'TRANSACTION_STATUS.DEPOSITED',
    value: 'Deposited',
  },
  {
    key: 'TRANSACTION_STATUS.PROCESSING',
    value: 'Processing',
  },
  {
    key: 'TRANSACTION_STATUS.UNCONFIRMED',
    value: 'Unconfirmed',
  },
  {
    key: 'TRANSACTION_STATUS.ALL',
    value: 'All',
  },
  {
    key: 'TRANSACTION_STATUS.TRANSFERRED',
    value: 'Transferred',
  },
  {
    key: 'TRANSACTION_STATUS.CONFIRMED',
    value: 'Confirmed',
  },
  {
    key: 'TRANSACTION_STATUS.DELIVERED',
    value: 'Delivered',
  },
  {
    key: 'TRANSACTION_STATUS.CREATED',
    value: 'Created',
  },
  {
    key: 'TRANSACTION_STATUS.CREDITED',
    value: 'Credited',
  },
  {
    key: 'TRANSACTION_STATUS.CHARGED',
    value: 'Charged',
  },
  {
    key: 'TRANSACTION_STATUS.UNDER_REVIEW',
    value: 'Under review',
  },
  {
    key: 'TRANSACTION_STATUS.WAITING_FOR_CONFIRMATION',
    value: 'Waiting for confirmation',
  },
];

const DATE_PICKER_OPTIONS = {
  ALL: 'all',
  LAST_SEVEN: 'last_seven',
  LAST_THIRTY: 'last_thirty',
  LAST_YEAR: 'last_year',
};

const DATE_FORMAT = 'dd.MM.yyyy HH:mm';

const INITIAL_TRANSFER_HISTORY_FILTER = {
  amount: '',
  currency: '',
  paymentMethod: '',
  subject: '',
  paymentDate: '',
  creditDate: '',
  status: '',
};

const DOCUMENT_TYPE_ID = {
  NATIONAL_ID: IS_PROD_ENV ? 3 : 57
}
const WALLET_ICONS_URL = 'https://content.naga-cn.com/wallet/';

const NACEPAY_WITHDRAW_WALLETS = [
  {
    name: 'Bitcoin',
    symbol: 'BTC',
    icon: `${WALLET_ICONS_URL}btc.png`,
    type: 'coin',
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    icon: `${WALLET_ICONS_URL}eth.png`,
    type: 'coin',
  },
  {
    name: 'Litecoin',
    symbol: 'LTC',
    icon: `${WALLET_ICONS_URL}ltc.png`,
    type: 'coin',
  },
  {
    name: 'Bitcoin Cash',
    symbol: 'BCH',
    icon: `${WALLET_ICONS_URL}bch.png`,
    type: 'coin',
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    icon: `${WALLET_ICONS_URL}usdc.svg`,
    type: 'erc20token',
  },
  {
    name: 'Tether USD',
    symbol: 'USDT',
    icon: `${WALLET_ICONS_URL}usdt.png`,
    type: 'erc20token',
  },
  {
    name: 'Tether USD (TRON)',
    symbol: 'USDT_TRON',
    icon: `${WALLET_ICONS_URL}usdt_tron.svg`,
    type: 'trc20token',
  },
];

const DEFAULT_CRPYTO_VALIDATION_NETWORK = {
  erc20token: 'ETH',
  trc20token: 'TRX',
};

const NUCLEUS365_PAYMENT_STATUS = {
  ok: 'ok',
  notok: 'notok',
  pending: 'pending',
  processed: 'processed',
};

const CURRENCY_TYPE = {
  FIAT: 'fiat',
  CRYPTO: 'crypto',
  TOKEN: 'token',
};

const ACCOUNT_CURRENCIES = {
  usd: {
    name: 'TRADING_ACCOUNTS.US_DOLLAR',
    value: 'USD',
    symbol: '$',
    symbolIcon: `${WALLET_ICONS_URL}${'usd'}.png`,
    currency_type: CURRENCY_TYPE.FIAT,
    isMT5: true,
    isMT4: true,
    can_trade: true,
  },
  eur: {
    name: 'TRADING_ACCOUNTS.EURO',
    value: 'EUR',
    symbol: '€',
    symbolIcon: `${WALLET_ICONS_URL}${'eur'}.png`,
    currency_type: CURRENCY_TYPE.FIAT,
    isMT5: true,
    isMT4: true,
    can_trade: true,
  },
  gbp: {
    name: 'TRADING_ACCOUNTS.BRITISH_POUND',
    value: 'GBP',
    symbol: '£',
    symbolIcon: `${WALLET_ICONS_URL}${'gbp'}.png`,
    currency_type: CURRENCY_TYPE.FIAT,
    isMT5: true,
    isMT4: true,
    can_trade: true,
  },
  pln: {
    name: 'TRADING_ACCOUNTS.POLAND_ZLOTY',
    value: 'PLN',
    symbol: 'zł',
    symbolIcon: `${WALLET_ICONS_URL}${'pln'}.png`,
    currency_type: CURRENCY_TYPE.FIAT,
    isMT5: true,
    isMT4: true,
    can_trade: true,
  },
  ngc: {
    name: 'TRADING_ACCOUNTS.NAGA_COIN',
    value: 'NGC',
    symbol: 'NGC',
    symbolIcon: `${WALLET_ICONS_URL}${'ngc'}.png`,
    currency_type: CURRENCY_TYPE.TOKEN,
    isMT5: true,
    isMT4: true,
    can_trade: true,
  },
  bnb: {
    name: 'TRADING_ACCOUNTS.BINANCE_COIN',
    value: 'BNB',
    symbol: 'BNB',
    symbolIcon: `${WALLET_ICONS_URL}${'bnb'}.png`,
    currency_type: CURRENCY_TYPE.TOKEN,
    isMT5: false,
    isMT4: false,
    can_trade: true,
  },
  btc: {
    name: 'TRADING_ACCOUNTS.BITCOIN',
    value: 'BTC',
    symbol: 'BTC',
    symbolIcon: `${WALLET_ICONS_URL}${'btc'}.png`,
    currency_type: CURRENCY_TYPE.CRYPTO,
    isMT5: true,
    isMT4: false,
    can_trade: true,
  },
  eth: {
    name: 'TRADING_ACCOUNTS.ETHEREUM',
    value: 'ETH',
    symbol: 'ETH',
    symbolIcon: `${WALLET_ICONS_URL}${'eth'}.png`,
    currency_type: CURRENCY_TYPE.CRYPTO,
    isMT5: true,
    isMT4: false,
    can_trade: true,
  },
  ust: {
    name: 'TRADING_ACCOUNTS.USDT',
    value: 'UST',
    symbol: 'USDT',
    symbolIcon: `${WALLET_ICONS_URL}${'usdt'}.png`,
    currency_type: CURRENCY_TYPE.FIAT,
    isMT5: true,
    isMT4: false,
    can_trade: true,
  },
};

const CURRENCIES = {
  ...ACCOUNT_CURRENCIES,
  aud: {
    value: 'AUD',
    symbol: 'A$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  jpy: {
    value: 'JPY',
    symbol: '¥',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  cny: {
    value: 'CNY',
    symbol: '¥',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  rub: {
    value: 'RUB',
    symbol: '₽',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  clp: {
    value: 'CLP',
    symbol: 'Ch$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  idr: {
    value: 'IDR',
    symbol: 'Rp',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  myr: {
    value: 'MYR',
    symbol: 'RM',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  thb: {
    value: 'THB',
    symbol: '฿',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  vnd: {
    value: 'VND',
    symbol: '₫',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  brl: {
    value: 'BRL',
    symbol: 'R$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  mxn: {
    value: 'MXN',
    symbol: 'Mex$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  ngn: {
    value: 'NGN',
    symbol: '₦',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  ghs: {
    value: 'GHS',
    symbol: 'GH₵',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  zar: {
    value: 'ZAR',
    symbol: 'R',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  kes: {
    value: 'KES',
    symbol: 'KSh',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  sgd: {
    value: 'SGD',
    symbol: 'S$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  ugx: {
    value: 'UGX',
    symbol: 'USh',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  cop: {
    value: 'COP',
    symbol: 'Col$',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  crc: {
    value: 'CRC',
    symbol: '₡',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  gtq: {
    value: 'GTQ',
    symbol: 'Q',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  pen: {
    value: 'PEN',
    symbol: 'S/',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  php: {
    value: 'PHP',
    symbol: '₱',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  pab: {
    value: 'PAB',
    symbol: 'B/.',
    currency_type: CURRENCY_TYPE.FIAT,
  },
  inr: {
    value: 'INR',
    symbol: '₹',
    currency_type: CURRENCY_TYPE.FIAT,
  },
};


const constants = {
  API_URL,
  IS_PROD_ENV,
  BREAKPOINTS,
  MAGIC_SALT,
  ACCEPT_VERSION,
  PLATFORM,
  WALLET_S3_CONTENT,
  MT4_BROKER_SERVER_IDS,
  MT5_BROKER_SERVER_IDS,
  TERMINAL_STATUSES,
  MT_VERSIONS,
  TERMINAL_TYPES,
  USER_STATUS_TYPE,
  MAX_LIVE_ACCOUNTS_NUMBER,
  DEFAULT_AVATAR_URL,
  PHOTO_PROFILE_URL,
  IMAGES_URL,
  RE_CAPTCHA_KEY,
  NUMBER_OF_LOGIN_RETRYS,
  MQTT_URL,
  QUERY_NAMES,
  ERRORS,
  FUND_STATUS,
  BO_STATUS,
  PASSWORD_REGEX,
  MFA_CODE_OR_RECOVERY_CRITERIA,
  MFA_CODE_CRITERIA,
  MT4_DOWNLOAD_LINKS,
  MT5_DOWNLOAD_LINKS,
  NAGA_LEGAL_DOCUMENTATION_URL,
  NAGA_FLEXIBLE_PRIVACY_POLICY_URL,
  DOCUMENT_REQUEST_STATUS,
  DOCUMENT_STATUS,
  DOCUMENT_SIDE,
  DOCUMENT_GROUP,
  DOCUMENT_TYPE_ID,
  ALLOWED_IMAGE_TYPES,
  PAYMENT_OPTIONS,
  WITHDRAWAL_STATUS_TRANSLATIONS,
  WITHDRAWAL_STATUS,
  WITHDRAW_DESTINATIONS_TRANSLATED,
  SUPPORTED_CURRENCIES,
  SUPPORTED_COUNTRY_CODES,
  SUPPORTED_LANGUAGES,
  SERVICE_EMAIL,
  SERVICE_PHONE_NUMBER,
  INTERCOM_APP_ID,
  CACHED_ITEMS,
  USER_TOKENS,
  MODAL_TYPES,
  MFA_STATUS_CODES,
  MFA_RECOVERY_CODE_LENGTH,
  MFA_CODE_LENGTH,
  MFA_STEPS,
  TOKEN_STRATEGY,
  REQUEST_RETRY_COUNT,
  GEETEST_ID,
  ZOTAPAY,
  NACEPAY,
  NUCLEUS,
  PAYMENT_PROVIDERS,
  NAGA_TRADER_URL,
  CRYPTO_CURRENCIES,
  TRANSACTION_STATUS,
  TRANSACTION_STATUSES,
  DATE_PICKER_OPTIONS,
  INITIAL_TRANSFER_HISTORY_FILTER,
  DATE_FORMAT,
  NACEPAY_WITHDRAW_WALLETS,
  DEFAULT_CRPYTO_VALIDATION_NETWORK,
  NUCLEUS365_PAYMENT_STATUS,
  CURRENCIES,
  LOGIN_STATUS_CODES
};

export default constants;
