import FingerprintJS from "@fingerprintjs/fingerprintjs";
import cacheHelper from "../helpers/cacheHelper";

const getFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const visitorId = result.visitorId;

  cacheHelper.setFingerprint(visitorId);

  return visitorId;
};

export default getFingerprint;
