import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import constants from "../../constants";

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  border: 1px solid ${(props) => props.theme.color.border};
  min-width: 100px;
  box-shadow: 1px 2px 5px -1px;
`;

const MenuItem = styled.div`
  padding: 7px 10px;
  font-size: 0.8rem;
  &:hover {
    background-color: ${(props) => props.theme.color.backgroundPrimary};
    cursor: pointer;
  }
`;

const WithdrawalHistoryContextMenu = (props) => {
  const { withdrawalId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const cancelRequestMutation = useMutation(
    () => {
      return api.mutations.cancelWithdrawalRequest(withdrawalId);
    },
    {
      onSuccess: () => {
        toast.success(t("MANAGE_MONEY.WITHDRAWAL_HISTORY.CANCEL_SUCCESS", { withdrawalId }));
        queryClient.invalidateQueries(constants.QUERY_NAMES.withdrawalTransactions);
        queryClient.invalidateQueries(constants.QUERY_NAMES.terminalLimits);
        queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const onCancelClick = () => {
    cancelRequestMutation.mutate(withdrawalId);
  };

  return (
    <MenuWrapper>
      <MenuItem onClick={onCancelClick}>
        {t("MANAGE_MONEY.WITHDRAWAL_HISTORY.CANCEL_REQUEST")}
      </MenuItem>
    </MenuWrapper>
  );
};

WithdrawalHistoryContextMenu.propTypes = {
  withdrawalId: PropTypes.number.isRequired,
};

export default WithdrawalHistoryContextMenu;
