export const COUNTRIES = {
  AF: { label: "Afghanistan (‫افغانستان‬‎)", id: "AF", value: "+93" },
  AL: { label: "Albania (Shqipëri)", id: "AL", value: "+355" },
  DZ: { label: "Algeria (‫الجزائر‬‎)", id: "DZ", value: "+213" },
  AS: { label: "American Samoa", id: "AS", value: "+1684" },
  AD: { label: "Andorra", id: "AD", value: "+376" },
  AO: { label: "Angola", id: "AO", value: "+244" },
  AI: { label: "Anguilla", id: "AI", value: "+1264" },
  AG: { label: "Antigua and Barbuda", id: "AG", value: "+1268" },
  AR: { label: "Argentina", id: "AR", value: "+54" },
  AM: { label: "Armenia (Հայաստան)", id: "AM", value: "+374" },
  AW: { label: "Aruba", id: "AW", value: "+297" },
  AU: { label: "Australia", id: "AU", value: "+61" },
  AT: { label: "Austria (Österreich)", id: "AT", value: "+43" },
  AZ: { label: "Azerbaijan (Azərbaycan)", id: "AZ", value: "+994" },
  BS: { label: "Bahamas", id: "BS", value: "+1242" },
  BH: { label: "Bahrain (‫البحرين‬‎)", id: "BH", value: "+973" },
  BD: { label: "Bangladesh (বাংলাদেশ)", id: "BD", value: "+880" },
  BB: { label: "Barbados", id: "BB", value: "+1246" },
  BY: { label: "Belarus (Беларусь)", id: "BY", value: "+375" },
  BE: { label: "Belgium (België)", id: "BE", value: "+32" },
  BZ: { label: "Belize", id: "BZ", value: "+501" },
  BJ: { label: "Benin (Bénin)", id: "BJ", value: "+229" },
  BM: { label: "Bermuda", id: "BM", value: "+1441" },
  BT: { label: "Bhutan (འབྲུག)", id: "BT", value: "+975" },
  BO: { label: "Bolivia", id: "BO", value: "+591" },
  BA: {
    label: "Bosnia and Herzegovina (Босна и Херцеговина)",
    id: "BA",
    value: "+387",
  },
  BW: { label: "Botswana", id: "BW", value: "+267" },
  BR: { label: "Brazil (Brasil)", id: "BR", value: "+55" },
  IO: { label: "British Indian Ocean Territory", id: "IO", value: "+246" },
  VG: { label: "British Virgin Islands", id: "VG", value: "+1284" },
  BN: { label: "Brunei", id: "BN", value: "+673" },
  BG: { label: "Bulgaria (България)", id: "BG", value: "+359" },
  BF: { label: "Burkina Faso", id: "BF", value: "+226" },
  BI: { label: "Burundi (Uburundi)", id: "BI", value: "+257" },
  KH: { label: "Cambodia (កម្ពុជា)", id: "KH", value: "+855" },
  CM: { label: "Cameroon (Cameroun)", id: "CM", value: "+237" },
  CA: { label: "Canada", id: "CA", value: "+1" },
  CV: { label: "Cape Verde (Kabu Verdi)", id: "CV", value: "+238" },
  KY: { label: "Cayman Islands", id: "KY", value: "+1345" },
  CF: {
    label: "Central African Republic (République centrafricaine)",
    id: "CF",
    value: "+236",
  },
  TD: { label: "Chad (Tchad)", id: "TD", value: "+235" },
  CL: { label: "Chile", id: "CL", value: "+56" },
  CN: { label: "China (中国)", id: "CN", value: "+86" },
  CX: { label: "Christmas Island", id: "CX", value: "+61" },
  CC: { label: "Cocos (Keeling) Islands", id: "CC", value: "+61" },
  CO: { label: "Colombia", id: "CO", value: "+57" },
  KM: { label: "Comoros (‫جزر القمر‬‎)", id: "KM", value: "+269" },
  CD: {
    label: "Congo (DRC) (Jamhuri ya Kisoemokrasia ya Kongo)",
    id: "CD",
    value: "+243",
  },
  CG: {
    label: "Congo (Republic) (Congo-Brazzaville)",
    id: "CG",
    value: "+242",
  },
  CK: { label: "Cook Islands", id: "CK", value: "+682" },
  CR: { label: "Costa Rica", id: "CR", value: "+506" },
  CI: { label: "Côte d’Ivoire", id: "CI", value: "+225" },
  HR: { label: "Croatia (Hrvatska)", id: "HR", value: "+385" },
  CU: { label: "Cuba", id: "CU", value: "+53" },
  CW: { label: "Curaçao", id: "CW", value: "+599" },
  CY: { label: "Cyprus (Κύπρος)", id: "CY", value: "+357" },
  CZ: { label: "Czech Republic (Česká republika)", id: "CZ", value: "+420" },
  DK: { label: "Denmark (Danmark)", id: "DK", value: "+45" },
  DJ: { label: "Djibouti", id: "DJ", value: "+253" },
  DM: { label: "Dominica", id: "DM", value: "+1767" },
  DO: {
    label: "Dominican Republic (República Dominicana)",
    id: "DO",
    value: "+1",
  },
  EC: { label: "Ecuador", id: "EC", value: "+593" },
  EG: { label: "Egypt (‫مصر‬‎)", id: "EG", value: "+20" },
  SV: { label: "El Salvador", id: "SV", value: "+503" },
  GQ: {
    label: "Equatorial Guinea (Guinea Ecuatorial)",
    id: "GQ",
    value: "+240",
  },
  ER: { label: "Eritrea", id: "ER", value: "+291" },
  EE: { label: "Estonia (Eesti)", id: "EE", value: "+372" },
  ET: { label: "Ethiopia", id: "ET", value: "+251" },
  FK: {
    label: "Falkland Islands (Islas Malvinas)",
    id: "FK",
    value: "+500",
  },
  FO: { label: "Faroe Islands (Føroyar)", id: "FO", value: "+298" },
  FJ: { label: "Fiji", id: "FJ", value: "+679" },
  FI: { label: "Finland (Suomi)", id: "FI", value: "+358" },
  FR: { label: "France", id: "FR", value: "+33" },
  GF: { label: "French Guiana (Guyane française)", id: "GF", value: "+594" },
  PF: {
    label: "French Polynesia (Polynésie française)",
    id: "PF",
    value: "+689",
  },
  GA: { label: "Gabon", id: "GA", value: "+241" },
  GM: { label: "Gambia", id: "GM", value: "+220" },
  GE: { label: "Georgia (საქართველო)", id: "GE", value: "+995" },
  DE: { label: "Germany (Deutschland)", id: "DE", value: "+49" },
  GH: { label: "Ghana (Gaana)", id: "GH", value: "+233" },
  GI: { label: "Gibraltar", id: "GI", value: "+350" },
  GR: { label: "Greece (Ελλάδα)", id: "GR", value: "+30" },
  GL: { label: "Greenland (Kalaallit Nunaat)", id: "GL", value: "+299" },
  GD: { label: "Grenada", id: "GD", value: "+1473" },
  GP: { label: "Guadeloupe", id: "GP", value: "+590" },
  GU: { label: "Guam", id: "GU", value: "+1671" },
  GT: { label: "Guatemala", id: "GT", value: "+502" },
  GG: { label: "Guernsey", id: "GG", value: "+44" },
  GN: { label: "Guinea (Guinée)", id: "GN", value: "+224" },
  GW: { label: "Guinea-Bissau (Guiné Bissau)", id: "GW", value: "+245" },
  GY: { label: "Guyana", id: "GY", value: "+592" },
  HT: { label: "Haiti", id: "HT", value: "+509" },
  HN: { label: "Honduras", id: "HN", value: "+504" },
  HK: { label: "Hong Kong (香港)", id: "HK", value: "+852" },
  HU: { label: "Hungary (Magyarország)", id: "HU", value: "+36" },
  IS: { label: "Iceland (Ísland)", id: "IS", value: "+354" },
  IN: { label: "India (भारत)", id: "IN", value: "+91" },
  ID: { label: "Indonesia", id: "ID", value: "+62" },
  IR: { label: "Iran (‫ایران‬‎)", id: "IR", value: "+98" },
  IQ: { label: "Iraq (‫العراق‬‎)", id: "IQ", value: "+964" },
  IE: { label: "Ireland", id: "IE", value: "+353" },
  IM: { label: "Isle of Man", id: "IM", value: "+44" },
  IL: { label: "Israel (‫ישראל‬‎)", id: "IL", value: "+972" },
  IT: { label: "Italy (Italia)", id: "IT", value: "+39" },
  JM: { label: "Jamaica", id: "JM", value: "+1" },
  JP: { label: "Japan (日本)", id: "JP", value: "+81" },
  JE: { label: "Jersey", id: "JE", value: "+44" },
  JO: { label: "Jordan (‫الأردن‬‎)", id: "JO", value: "+962" },
  KZ: { label: "Kazakhstan (Казахстан)", id: "KZ", value: "+7" },
  KE: { label: "Kenya", id: "KE", value: "+254" },
  KI: { label: "Kiribati", id: "KI", value: "+686" },
  XK: { label: "Kosovo", id: "XK", value: "+383" },
  KW: { label: "Kuwait (‫الكويت‬‎)", id: "KW", value: "+965" },
  KG: { label: "Kyrgyzstan (Кыргызстан)", id: "KG", value: "+996" },
  LA: { label: "Laos (ລາວ)", id: "LA", value: "+856" },
  LV: { label: "Latvia (Latvija)", id: "LV", value: "+371" },
  LB: { label: "Lebanon (‫لبنان‬‎)", id: "LB", value: "+961" },
  LS: { label: "Lesotho", id: "LS", value: "+266" },
  LR: { label: "Liberia", id: "LR", value: "+231" },
  LY: { label: "Libya (‫ليبيا‬‎)", id: "LY", value: "+218" },
  LI: { label: "Liechtenstein", id: "LI", value: "+423" },
  LT: { label: "Lithuania (Lietuva)", id: "LT", value: "+370" },
  LU: { label: "Luxembourg", id: "LU", value: "+352" },
  MO: { label: "Macau (澳門)", id: "MO", value: "+853" },
  MK: {
    label: "North Macedonia (FYROM) (Македонија)",
    id: "MK",
    value: "+389",
  },
  MG: { label: "Madagascar (Madagasikara)", id: "MG", value: "+261" },
  MW: { label: "Malawi", id: "MW", value: "+265" },
  MY: { label: "Malaysia", id: "MY", value: "+60" },
  MV: { label: "Maldives", id: "MV", value: "+960" },
  ML: { label: "Mali", id: "ML", value: "+223" },
  MT: { label: "Malta", id: "MT", value: "+356" },
  MH: { label: "Marshall Islands", id: "MH", value: "+692" },
  MQ: { label: "Martinique", id: "MQ", value: "+596" },
  MR: { label: "Mauritania (‫موريتانيا‬‎)", id: "MR", value: "+222" },
  MU: { label: "Mauritius (Moris)", id: "MU", value: "+230" },
  YT: { label: "Mayotte", id: "YT", value: "+262" },
  MX: { label: "Mexico (México)", id: "MX", value: "+52" },
  FM: { label: "Micronesia", id: "FM", value: "+691" },
  MD: { label: "Moldova (Republica Moldova)", id: "MD", value: "+373" },
  MC: { label: "Monaco", id: "MC", value: "+377" },
  MN: { label: "Mongolia (Монгол)", id: "MN", value: "+976" },
  ME: { label: "Montenegro (Crna Gora)", id: "ME", value: "+382" },
  MS: { label: "Montserrat", id: "MS", value: "+1664" },
  MA: { label: "Morocco (‫المغرب‬‎)", id: "MA", value: "+212" },
  MZ: { label: "Mozambique (Moçambique)", id: "MZ", value: "+258" },
  MM: { label: "Myanmar (Burma) (မြန်မာ)", id: "MM", value: "+95" },
  NA: { label: "Namibia (Namibië)", id: "NA", value: "+264" },
  NR: { label: "Nauru", id: "NR", value: "+674" },
  NP: { label: "Nepal (नेपाल)", id: "NP", value: "+977" },
  NL: { label: "Netherlands (Nederland)", id: "NL", value: "+31" },
  NC: {
    label: "New Caledonia (Nouvelle-Calédonie)",
    id: "NC",
    value: "+687",
  },
  NZ: { label: "New Zealand", id: "NZ", value: "+64" },
  NI: { label: "Nicaragua", id: "NI", value: "+505" },
  NE: { label: "Niger (Nijar)", id: "NE", value: "+227" },
  NG: { label: "Nigeria", id: "NG", value: "+234" },
  NU: { label: "Niue", id: "NU", value: "+683" },
  NF: { label: "Norfolk Island", id: "NF", value: "+672" },
  KP: {
    label: "North Korea (조선 민주주의 인민 공화국)",
    id: "KP",
    value: "+850",
  },
  MP: { label: "Northern Mariana Islands", id: "MP", value: "+1670" },
  NO: { label: "Norway (Norge)", id: "NO", value: "+47" },
  OM: { label: "Oman (‫عُمان‬‎)", id: "OM", value: "+968" },
  PK: { label: "Pakistan (‫پاکستان‬‎)", id: "PK", value: "+92" },
  PW: { label: "Palau", id: "PW", value: "+680" },
  PS: { label: "Palestine (‫فلسطين‬‎)", id: "PS", value: "+970" },
  PA: { label: "Panama (Panamá)", id: "PA", value: "+507" },
  PG: { label: "Papua New Guinea", id: "PG", value: "+675" },
  PY: { label: "Paraguay", id: "PY", value: "+595" },
  PE: { label: "Peru (Perú)", id: "PE", value: "+51" },
  PH: { label: "Philippines", id: "PH", value: "+63" },
  PL: { label: "Poland (Polska)", id: "PL", value: "+48" },
  PT: { label: "Portugal", id: "PT", value: "+351" },
  PR: { label: "Puerto Rico", id: "PR", value: "+1" },
  QA: { label: "Qatar (‫قطر‬‎)", id: "QA", value: "+974" },
  RE: { label: "Réunion (La Réunion)", id: "RE", value: "+262" },
  RO: { label: "Romania (România)", id: "RO", value: "+40" },
  RU: { label: "Russia (Россия)", id: "RU", value: "+7" },
  RW: { label: "Rwanda", id: "RW", value: "+250" },
  BL: { label: "Saint Barthélemy", id: "BL", value: "+590" },
  SH: { label: "Saint Helena", id: "SH", value: "+290" },
  KN: { label: "Saint Kitts and Nevis", id: "KN", value: "+1869" },
  LC: { label: "Saint Lucia", id: "LC", value: "+1758" },
  MF: {
    label: "Saint Martin (Saint-Martin (partie française))",
    id: "MF",
    value: "+590",
  },
  PM: {
    label: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    id: "PM",
    value: "+508",
  },
  VC: {
    label: "Saint Vincent and the Grenadines",
    id: "VC",
    value: "+1784",
  },
  WS: { label: "Samoa", id: "WS", value: "+685" },
  SM: { label: "San Marino", id: "SM", value: "+378" },
  ST: {
    label: "São Tomé and Príncipe (São Tomé e Príncipe)",
    id: "ST",
    value: "+239",
  },
  SA: {
    label: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    id: "SA",
    value: "+966",
  },
  SN: { label: "Senegal (Sénégal)", id: "SN", value: "+221" },
  RS: { label: "Serbia (Србија)", id: "RS", value: "+381" },
  SC: { label: "Seychelles", id: "SC", value: "+248" },
  SL: { label: "Sierra Leone", id: "SL", value: "+232" },
  SG: { label: "Singapore", id: "SG", value: "+65" },
  SX: { label: "Sint Maarten", id: "SX", value: "+1721" },
  SK: { label: "Slovakia (Slovensko)", id: "SK", value: "+421" },
  SI: { label: "Slovenia (Slovenija)", id: "SI", value: "+386" },
  SB: { label: "Solomon Islands", id: "SB", value: "+677" },
  SO: { label: "Somalia (Soomaaliya)", id: "SO", value: "+252" },
  ZA: { label: "South Africa", id: "ZA", value: "+27" },
  KR: { label: "South Korea (대한민국)", id: "KR", value: "+82" },
  SS: { label: "South Sudan (‫جنوب السودان‬‎)", id: "SS", value: "+211" },
  ES: { label: "Spain (España)", id: "ES", value: "+34" },
  LK: { label: "Sri Lanka (ශ්‍රී ලංකාව)", id: "LK", value: "+94" },
  SD: { label: "Sudan (‫السودان‬‎)", id: "SD", value: "+249" },
  SR: { label: "Suriname", id: "SR", value: "+597" },
  SJ: { label: "Svalbard and Jan Mayen", id: "SJ", value: "+47" },
  SZ: { label: "Swaziland", id: "SZ", value: "+268" },
  SE: { label: "Sweden (Sverige)", id: "SE", value: "+46" },
  CH: { label: "Switzerland (Schweiz)", id: "CH", value: "+41" },
  SY: { label: "Syria (‫سوريا‬‎)", id: "SY", value: "+963" },
  TW: { label: "Taiwan (台灣)", id: "TW", value: "+886" },
  TJ: { label: "Tajikistan", id: "TJ", value: "+992" },
  TZ: { label: "Tanzania", id: "TZ", value: "+255" },
  TH: { label: "Thailand (ไทย)", id: "TH", value: "+66" },
  TL: { label: "Timor-Leste", id: "TL", value: "+670" },
  TG: { label: "Togo", id: "TG", value: "+228" },
  TK: { label: "Tokelau", id: "TK", value: "+690" },
  TO: { label: "Tonga", id: "TO", value: "+676" },
  TT: { label: "Trinisoad and Tobago", id: "TT", value: "+1868" },
  TN: { label: "Tunisia (‫تونس‬‎)", id: "TN", value: "+216" },
  TR: { label: "Turkey (Türkiye)", id: "TR", value: "+90" },
  TM: { label: "Turkmenistan", id: "TM", value: "+993" },
  TC: { label: "Turks and Caicos Islands", id: "TC", value: "+1649" },
  TV: { label: "Tuvalu", id: "TV", value: "+688" },
  VI: { label: "U.S. Virgin Islands", id: "VI", value: "+1340" },
  UG: { label: "Uganda", id: "UG", value: "+256" },
  UA: { label: "Ukraine (Україна)", id: "UA", value: "+380" },
  AE: {
    label: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    id: "AE",
    value: "+971",
  },
  GB: { label: "United Kingdom", id: "GB", value: "+44" },
  US: { label: "United States", id: "US", value: "+1" },
  UY: { label: "Uruguay", id: "UY", value: "+598" },
  UZ: { label: "Uzbekistan (Oʻzbekiston)", id: "UZ", value: "+998" },
  VU: { label: "Vanuatu", id: "VU", value: "+678" },
  VA: { label: "Vatican City (Città del Vaticano)", id: "VA", value: "+39" },
  VE: { label: "Venezuela", id: "VE", value: "+58" },
  VN: { label: "Vietnam (Việt Nam)", id: "VN", value: "+84" },
  WF: {
    label: "Wallis and Futuna (Wallis-et-Futuna)",
    id: "WF",
    value: "+681",
  },
  YE: { label: "Yemen (‫اليمن‬‎)", id: "YE", value: "+967" },
  ZM: { label: "Zambia", id: "ZM", value: "+260" },
  ZW: { label: "Zimbabwe", id: "ZW", value: "+263" },
  AX: { label: "Åland Islands", id: "AX", value: "+358" },
};
