import { format } from "date-fns";
import { useMemo, Fragment, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Filter } from "react-feather";
import styled from "styled-components";
import { Button } from "@my-swipestox/components";
import filter from "lodash/filter";

import api from "../../api";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import { getTranslation, compareDates } from "../../helpers/transferHistoryHelper";
import Table from "../../components/table/Table";
import NoTransactions from "./NoTransactions";
import Loader from "../../components/loader/Loader";
import TransactionsMobileFilter from "./TransactionsMobileFilter";

const FilterCustom = styled(Filter)`
  color: ${(props) => props.theme.color.primary};
  fill: ${(props) => props.theme.color.primary};
  height: 1rem;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

const FilterResetBox = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 26px;
  margin-bottom: 20px;
  span {
    padding-right: 5px;
    border-right: 1px solid ${(props) => props.theme.color.border};
    color: #7f93bc;
    margin-right: 5px;
    font-size: 14px;
  }
`;

const FilterToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.color.primary};
  }
`;

const ButtonLink = styled(Button)`
  color: ${(props) => props.theme.color.primary};
  background-color: transparent;
  border: 0;
  font-weight: 500;
  padding: 0;
`;

const AmountCell = styled.div`
  display: flex;
  align-items: center;
`;

const CurrencyImage = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const MobileItemContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

const MobileRow = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const MobileRowLabel = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.color.contentSecondary};
  &::after {
    content: ":";
  }
`;

const TransferHistory = (props) => {
  const { fullAccount } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);
  const [filters, setFilters] = useState(constants.INITIAL_TRANSFER_HISTORY_FILTER);

  const { data, isLoading } = useQuery(
    [constants.QUERY_NAMES.transferHistory, fullAccount?.terminal_id],
    () => {
      return api.queries.getTransferHistory(fullAccount?.terminal_id);
    },
    {
      enabled: !!fullAccount?.terminal_id,
    }
  );

  const applyAmountFilter = useCallback(
    (item) => {
      const { amount } = filters;

      return item.amount && item.amount_text.indexOf(amount) > -1;
    },
    [filters]
  );

  const applyPaymentMethodFilter = useCallback(
    (item) => {
      const { paymentMethod } = filters;

      return (
        item.payment_method &&
        item.payment_method.toLowerCase().indexOf(paymentMethod.toLowerCase()) > -1
      );
    },
    [filters]
  );

  const applyDescriptionFilter = useCallback(
    (item) => {
      const { subject } = filters;

      return (
        (item.description && item.description.toLowerCase().indexOf(subject.toLowerCase()) > -1) ||
        (!item.description && !subject)
      );
    },
    [filters]
  );

  const applyPaymentDateFilter = useCallback(
    (item) => {
      const { paymentDate } = filters;

      return (
        (item.payment_time && paymentDate && compareDates(item.payment_time, paymentDate.value)) ||
        !paymentDate
      );
    },
    [filters]
  );

  const applyCreditDateFilter = useCallback(
    (item) => {
      const { creditDate } = filters;

      return (
        (item.creation_date && creditDate && compareDates(item.creation_date, creditDate.value)) ||
        !creditDate
      );
    },
    [filters]
  );

  const applyTransactionStatusFilter = useCallback(
    (item) => {
      const { status } = filters;

      return (
        (item.status &&
          status &&
          status.value &&
          item.status.toLowerCase() === status.value.toLowerCase()) ||
        !status ||
        status.value.toLowerCase() === constants.TRANSACTION_STATUS.ALL
      );
    },
    [filters]
  );

  const updateTransferHistoryFilter = useCallback(
    (value, type) => {
      setFilters({ ...filters, [type]: value });
    },
    [filters]
  );

  const sourceTransferHistory = data?.data?.data?.transactions || [];
  const filteredTransferHistory = useMemo(() => {
    if (!filters) return sourceTransferHistory;
    return filter(
      sourceTransferHistory,
      (item) =>
        applyAmountFilter(item) &&
        applyPaymentMethodFilter(item) &&
        applyDescriptionFilter(item) &&
        applyPaymentDateFilter(item) &&
        applyCreditDateFilter(item) &&
        applyTransactionStatusFilter(item)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sourceTransferHistory]);

  const columns = useMemo(
    () => [
      {
        ID: 1,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.AMOUNT"),
        accessor: "amount_text",
        Cell: ({ row: { original } }) => {
          return (
            <AmountCell>
              <CurrencyImage
                src={`${constants.IMAGES_URL}/${original.currency}.svg`}
                alt={original.currency}
              />
              {original.amount_text}
            </AmountCell>
          );
        },
      },
      {
        ID: 2,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.METHOD"),
        accessor: "payment_method",
        Cell: ({ row: { original } }) => {
          return original.payment_method || "-";
        },
      },
      {
        ID: 3,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.TRANSFER_HISTORY.SUBJECT"),
        accessor: "description",
        Cell: ({ row: { original } }) => {
          return original.description || "-";
        },
      },
      {
        ID: 4,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.PAYMENT_DATE"),
        accessor: "payment_time",
        Cell: ({ row: { original } }) => {
          return original.payment_time
            ? format(new Date(original.payment_time), constants.DATE_FORMAT)
            : "-";
        },
      },
      {
        ID: 5,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.TRANSFER_HISTORY.CREDITED_FUNDS"),
        accessor: "received_amount_text",
        Cell: ({ row: { original } }) => {
          return original.token_amount_text || "-";
        },
      },
      {
        ID: 6,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.CREDIT_DATE"),
        accessor: "creation_date",
        Cell: ({ row: { original } }) => {
          return original.creation_date
            ? format(new Date(original.creation_date), constants.DATE_FORMAT)
            : "-";
        },
      },
      {
        ID: 7,
        width: 200,
        Header: t("MANAGE_MONEY.HISTORY.DEPOSITS.STATUS"),
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return t(...getTranslation(original.status, constants.TRANSACTION_STATUSES));
        },
      },
    ],
    [t]
  );

  if (isLoading) {
    return <Loader text={t("MANAGE_MONEY.HISTORY.TRANSFER_HISTORY.LOADING_TRANSACTIONS")} />;
  }

  if (isMobile) {
    if (displayMobileFilter) {
      return (
        <TransactionsMobileFilter
          setDisplayMobileFilter={setDisplayMobileFilter}
          filters={filters}
          updateTransferHistoryFilter={updateTransferHistoryFilter}
        />
      );
    }

    return (
      <Fragment>
        <FilterContainer>
          <FilterToggle>
            <FilterCustom />
            <span onClick={() => setDisplayMobileFilter(true)}>{t("PAYMENTS_HISTORY.FILTER")}</span>
          </FilterToggle>
          <ButtonLink onClick={() => setFilters(constants.INITIAL_TRANSFER_HISTORY_FILTER)}>
            {t("PAYMENTS_HISTORY.RESET_FILTERS")}
          </ButtonLink>
        </FilterContainer>
        {filteredTransferHistory.map((i, index) => (
          <MobileItemContainer key={`mobile-item-container-${index}`}>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.AMOUNT")}</MobileRowLabel>
              <span>{i.amount_text}</span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.METHOD")}</MobileRowLabel>
              <span>{i.payment_method || "-"}</span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.TRANSFER_HISTORY.SUBJECT")}</MobileRowLabel>
              <span>{i.description || "-"}</span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.PAYMENT_DATE")}</MobileRowLabel>
              <span>
                {i.payment_time ? format(new Date(i.payment_time), constants.DATE_FORMAT) : "-"}
              </span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>
                {t("MANAGE_MONEY.HISTORY.TRANSFER_HISTORY.CREDITED_FUNDS")}
              </MobileRowLabel>
              <span>{i.token_amount_text || "-"}</span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.CREDIT_DATE")}</MobileRowLabel>
              <span>
                {i.fund_time ? format(new Date(i.creation_date), constants.DATE_FORMAT) : "-"}
              </span>
            </MobileRow>
            <MobileRow>
              <MobileRowLabel>{t("MANAGE_MONEY.HISTORY.DEPOSITS.STATUS")}</MobileRowLabel>
              <span>{t(...getTranslation(i.status, constants.TRANSACTION_STATUSES))}</span>
            </MobileRow>
          </MobileItemContainer>
        ))}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TransactionsMobileFilter
        setDisplayMobileFilter={setDisplayMobileFilter}
        filters={filters}
        updateTransferHistoryFilter={updateTransferHistoryFilter}
      />
      <FilterResetBox>
        <span>{t("PAYMENTS_HISTORY.RESULTS_COUNT", { n: filteredTransferHistory.length })}</span>
        <ButtonLink onClick={() => setFilters(constants.INITIAL_TRANSFER_HISTORY_FILTER)}>
          {t("PAYMENTS_HISTORY.RESET_FILTERS")}
        </ButtonLink>
      </FilterResetBox>
      <Table
        columns={columns}
        data={filteredTransferHistory}
        noData={
          <NoTransactions
            title={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS")}
            desc={t("MANAGE_MONEY.HISTORY.DEPOSITS.NO_TRANSACTIONS_DESC")}
          />
        }
      />
    </Fragment>
  );
};

export default TransferHistory;
