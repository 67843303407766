import React from "react";
import ReactSelect from "react-select";
import { components } from "react-select";
import { useTheme } from "styled-components";
import { ChevronDown } from "react-feather";

import { getStyles } from "./styles";

function Select(props, ref) {
  const theme = useTheme();
  const { onChange, options, error, positive, disabled } = props;
  const styles = getStyles({ theme, error, positive });

  function DropdownIndicator(props) {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDown size={16} />
      </components.DropdownIndicator>
    );
  }

  return (
    <ReactSelect
      {...props}
      ref={ref}
      onChange={onChange}
      options={options}
      styles={styles}
      isDisabled={disabled}
      components={{ DropdownIndicator, ...props.components }}
    />
  );
}

export default React.forwardRef(Select);
