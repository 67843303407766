import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button } from "@my-swipestox/components";

import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";
import state from "../../state";

import Loader from "../../components/loader/Loader";
import UnauthorizedHeader from "../header/UnauthorizedHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const Content = styled.div`
  border-radius: 3px;
  max-width: 32rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    padding: 4rem;
  }
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.typography.size.title};
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Description = styled.div`
  margin-bottom: 30px;
`;

const ConfirmButton = styled(Button)`
  max-width: 380px;
`;

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  const confirmEmailMutation = useMutation(api.mutations.confirmEmail, {
    onError: () => {
      toast.error(t("REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER"));
      setConfirmEmailError(true);
    },
    onSuccess: (response) => {
      if (response.data.success && isLoggedIn) {
        setUserInfo({ ...userInfo, email_confirmed: true });
      }
    },
  });

  useEffect(() => {
    const data = {
      email: decodeURIComponent(utils.getParamByName("email") || ""),
      userId: decodeURIComponent(utils.getParamByName("user_id") || ""),
      platform: decodeURIComponent(utils.getParamByName("p") || constants.PLATFORM),
    };

    confirmEmailMutation.mutate(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    if (isLoggedIn) {
      history.push("/");
    } else {
      history.push("/login");
    }
  };

  const renderConfirmEmail = () => (
    <ContentWrapper>
      {confirmEmailMutation.isLoading ? (
        <Loader text={t("CONFIRM_EMAIL.PLEASE_WAIT")} />
      ) : (
        <Fragment>
          {!confirmEmailError && (
            <img src={`${constants.IMAGES_URL}/envelope-green.svg`} alt="envelope" />
          )}
          <Title>
            {t(confirmEmailError ? "CONFIRM_EMAIL.NOT_VERIFIED" : "CONFIRM_EMAIL.VERIFIED")}
          </Title>
          <Description>
            {t(
              confirmEmailError ? "CONFIRM_EMAIL.VERIFICATION_ERROR" : "CONFIRM_EMAIL.RETURN_TO_APP"
            )}
          </Description>
          <ConfirmButton type="button" shouldFitContainer onClick={handleButtonClick}>
            {isLoggedIn ? t("CONFIRM_EMAIL.GET_STARTED") : t("CONFIRM_EMAIL.LOGIN")}
          </ConfirmButton>
        </Fragment>
      )}
    </ContentWrapper>
  );

  if (!isLoggedIn) {
    return (
      <Grid>
        <UnauthorizedHeader />
        <Wrapper>
          <Content>{renderConfirmEmail()}</Content>
        </Wrapper>
      </Grid>
    );
  }

  return renderConfirmEmail();
};

export default ConfirmEmail;
