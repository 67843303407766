/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

import constants from "../../constants";

const NacepayWidget = (props) => {
  const { show, currency, amount, refId, closeModal } = props;

  const nacepayRef = useRef();

  useEffect(() => {
    if (show) {
      nacepayRef.current.click();

      closeModal();
    }
  }, [show]);

  useEffect(() => {
    setTimeout(() => {
    const script = document.createElement("script");
    const src = constants.IS_PROD_ENV
      ? "https://apps.newagecrypto.com/widget/nacepay.js?v4"
      : "https://digitalpaydev.com/widget/paydev.js?v4";
    script.src = src;
    script.async = true;

    document.body.appendChild(script);
    }, 500)

  }, []);

  if (constants.IS_PROD_ENV) {
    return (
      <div
        ref={nacepayRef}
        nacepay="Paywidget"
        nacepay_id="e079e3c0-be26-43df-bd14-1c05412f0a24"
        amount={amount}
        currency={currency}
        ref_id={refId}
        className="nacepaypaybtn"
        style={{ visibility: "hidden", width: 0, height: 0 }}
      />
    );
  }

  return (
    <div
      ref={nacepayRef}
      paydev="Paywidget"
      paydev_id="b259459b-3cb0-4b4a-bf73-08e22d4aaa99"
      amount={amount}
      currency={currency}
      ref_id={refId}
      className="paydevpaybtn"
      style={{visibility: 'hidden', width: 0, height: 0}}
    />
  );
};

export default NacepayWidget;
