import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button, ParagraphLarge } from "@my-swipestox/components";

import HelperFunctions from "../../../helpers/helper";
import constants from "../../../constants";

import { INFO_BLOCK_TYPE } from "../../../components/info-block/info-block-constants";
import InfoBlock from "../../../components/info-block/info-block";

const Wrapper = styled.div`
  text-align: center;
  padding: 20%;
`;

const StyledParagraph = styled(ParagraphLarge)`
  text-align: center;
  margin-left: 5px;
`;

const TransactionStatus = styled.div`
  padding: 1rem;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;
const getCurrencySymbol = (currency) => {
  if (!currency) return "";

  return constants.CURRENCIES[currency.toLowerCase()]?.symbol || "";
};

const TransactionCompleted = (props, context) => {
  const { t } = useTranslation();
  const { history } = props;

  const transId = HelperFunctions.getParameterByName("trans_id");
  const currency = HelperFunctions.getParameterByName("currency");
  const amount = HelperFunctions.getParameterByName("amount");
  const transactionStatus = HelperFunctions.getParameterByName("status");

  const failedRequest = transactionStatus === constants.NUCLEUS365_PAYMENT_STATUS.notok;

  const amountToShow = parseFloat(amount) / 100;

  const gotoDefaultRoute = () => {
    history.push("/manage-money/deposit");
  };

  const renderTryAgainMessage = () => {
    return transId
      ? t("FUND_ACCOUNT.NUCLEUS365.TRANSACTION_ERROR_TRY_AGAIN", { transId })
      : t("FUND_ACCOUNT.TRANSACTION_ERROR_TRY_AGAIN");
  };

  const renderMessageByStatus = () => {
    if (failedRequest) {
      return (
        <InfoBlock
          title={t("FUND_ACCOUNT.PROBLEM_WHILE_PROCESSING_TRANSACTION")}
          description={renderTryAgainMessage()}
          type={INFO_BLOCK_TYPE.ERROR}
        />
      );
    }
    switch (transactionStatus) {
      case constants.NUCLEUS365_PAYMENT_STATUS.notok:
        return (
          <InfoBlock
            title={t("FUND_ACCOUNT.PROBLEM_WHILE_PROCESSING_TRANSACTION")}
            description={renderTryAgainMessage()}
            type={INFO_BLOCK_TYPE.ERROR}
          />
        );
      case constants.NUCLEUS365_PAYMENT_STATUS.ok:
      case constants.NUCLEUS365_PAYMENT_STATUS.processed:
        return (
          <InfoBlock
            title={t("FUND_ACCOUNT.DEPOSIT_APPROVED_MESSAGE", {
              currency: getCurrencySymbol(currency),
              amount: amountToShow,
            })}
            type={INFO_BLOCK_TYPE.SUCCESS}
          />
        );
      case constants.NUCLEUS365_PAYMENT_STATUS.pending:
        return (
          <InfoBlock
            title={t("FUND_ACCOUNT.DEPOSIT_PENDING_MESSAGE")}
            type={INFO_BLOCK_TYPE.INFO}
          />
        );
      default:
        return (
          <InfoBlock
            title={t("FUND_ACCOUNT.COULD_NOT_CHECK_DEPOSIT")}
            type={INFO_BLOCK_TYPE.WARNING}
          />
        );
    }
  };

  return (
    <Wrapper>
      <TransactionStatus>
        <div>
          <StyledParagraph> {renderMessageByStatus()} </StyledParagraph>
        </div>
        <StyledButton onClick={gotoDefaultRoute}>
          {t("MANAGE_MONEY.BACK_TO_MAIN_PAGE")}
        </StyledButton>
      </TransactionStatus>
    </Wrapper>
  );
};

TransactionCompleted.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TransactionCompleted;
