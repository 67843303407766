import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { StatefulPopover } from "@malcodeman/react-popover";
import { LogOut, Moon, Shield, Sun, User } from "react-feather";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Button } from "@my-swipestox/components";
import { useEffect } from "react";

import constants from "../../constants";
import state from "../../state";
import api from "../../api";
import utils from "../../utils";

import Switch from "../../components/switch/Switch";
import mqtt from "../../utils/mqtt";
import cacheHelper from "../../helpers/cacheHelper";

const Wrapper = styled.div``;

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.color.headerFill};
  color: #fff;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 auto;
  max-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES};
  @media (min-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES}) {
    padding: 1rem 0;
  }
`;

const SubHeader = styled.div`
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  border-top: 1px solid ${(props) => props.theme.color.border};
  height: 50px;
`;

const SubHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  margin: 0 auto;
  max-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES};
  padding: 0 1rem;
  @media (min-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES}) {
    padding: 0;
  }
`;

const SubHeaderNavigation = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color.contentPrimary};
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 1rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &.active {
    color: ${(props) => props.theme.color.primary};
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.color.primary};
  }
  &:hover {
    color: ${(props) => props.theme.color.primary}7f;
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.color.primary};
  }
`;

const StyledAccount = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledPopover = styled.div`
  border-radius: 4px;
  padding: 0.25rem;
  border: 2px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    width: 240px;
  }
`;

const EmailWrapper = styled.div`
  color: ${(props) => props.theme.color.contentPrimary};
  padding: 0.5rem;
  border-bottom: 2px solid ${(props) => props.theme.color.border};
  word-wrap: anywhere;
`;

const SmallText = styled.span`
  font-size: 0.8rem;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 3px;
  svg {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.color.contentPrimary};
  }
  &:hover {
    background-color: ${(props) => props.theme.color.backgroundPrimary};
  }
  ${SmallText} {
    color: ${(props) => props.theme.color.contentPrimary};
  }
`;

const StyledSwitch = styled(Switch)`
  margin-left: auto;
  display: none;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    display: flex;
  }
`;

const Image = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 50%;
  background-image: url(${(props) => props.imageUrl}), url(${constants.DEFAULT_AVATAR_URL});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Logo = styled.img`
  height: 32px;
`;

const Header = () => {
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const userInfoQuery = useQuery(constants.QUERY_NAMES.userInfo, () => api.queries.userInfo());
  const history = useHistory();
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const mqttInfo = state.useMqttInfo((state) => state.mqttInfo);
  const { t } = useTranslation();
  const setIsDarkMode = state.useDarkMode((state) => state.setIsDarkMode);
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const isManageMoneyEnabled =
    [
      constants.BO_STATUS.APPROVED,
      constants.BO_STATUS.NEW,
      constants.BO_STATUS.PENDING,
      constants.BO_STATUS.REVIEWED,
      constants.BO_STATUS.READ_ONLY,
    ].indexOf(userInfo.user_bo_status) > -1;
  const userImageUrl = `${constants.PHOTO_PROFILE_URL}${
    userInfo.user_id
  }.jpg?${new Date().getTime()}`;
  const location = useLocation();

  useEffect(() => {
    if (userInfoQuery.isFetched && userInfoQuery.isSuccess) {
      const dbUserInfo = userInfoQuery.data?.data?.data || {};
      setUserInfo({ ...userInfo, ...dbUserInfo });
    }
  }, [userInfoQuery, setUserInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const logoutMutation = useMutation(api.mutations.logout, {
    onSettled: async () => {
      setIsLoggedIn(false);
      await mqtt.unsubscribeAll();
      mqtt.disconnect();
      cacheHelper.clearUserTokens();
      cacheHelper.clearRetryCount();
      history.push("/");
    },
  });

  const handleLogOut = async () => {
    logoutMutation.mutate();
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const PopoverContent = () => {
    return (
      <StyledPopover>
        <EmailWrapper>
          <SmallText>{userInfo.email}</SmallText>
        </EmailWrapper>
        <ListItem onClick={toggleDarkMode}>
          {isDarkMode ? <Sun size={16} /> : <Moon size={16} />}
          <SmallText>{isDarkMode ? t("HEADER.LIGHT_MODE") : t("HEADER.DARK_MODE")}</SmallText>
          <StyledSwitch checked={isDarkMode} />
        </ListItem>
        <Link to="/account">
          <ListItem>
            <User size={16} />
            <SmallText>{t("Account")}</SmallText>
          </ListItem>
        </Link>
        <Link to="/security">
          <ListItem>
            <Shield size={16} />
            <SmallText>{t("HEADER.SECURITY")}</SmallText>
          </ListItem>
        </Link>
        <ListItem onClick={handleLogOut}>
          <LogOut size={16} />
          <SmallText>{t("Log out")}</SmallText>
        </ListItem>
      </StyledPopover>
    );
  };

  const renderCtaButton = () => {
    const isDemoUser = !utils.isEmpty(mqttInfo)
      ? mqttInfo.user_bo_status === constants.USER_STATUS_TYPE.demo
      : userInfo.user_bo_status === constants.USER_STATUS_TYPE.demo;
    const isNewUser = !utils.isEmpty(mqttInfo)
      ? mqttInfo.user_bo_status === constants.USER_STATUS_TYPE.new
      : userInfo.user_bo_status === constants.USER_STATUS_TYPE.new;

    if (isDemoUser && !location.pathname.startsWith("/live-account")) {
      return (
        <StyledLink to="/live-account">
          <Button>{t("HEADER.UPGRADE_ACCOUNT")}</Button>
        </StyledLink>
      );
    } else if (
      (isNewUser ||
        mqttInfo.PoIStatus === constants.DOCUMENT_STATUS.REJECTED ||
        mqttInfo.PoIStatus === constants.DOCUMENT_STATUS.DELETED ||
        mqttInfo.PoRStatus === constants.DOCUMENT_STATUS.REJECTED ||
        mqttInfo.PoRStatus === constants.DOCUMENT_STATUS.DELETED) &&
      location.pathname !== "/verification"
    ) {
      return (
        <StyledLink to="/verification">
          <Button>{t("HEADER.VERIFY_ACCOUNT")}</Button>
        </StyledLink>
      );
    }
    return null;
  };

  return (
    <Wrapper>
      <StyledHeader>
        <HeaderContent>
          <Logo src={`${constants.IMAGES_URL}/naga-logo-white.svg`} alt="logo" />
          <div>
            <StatefulPopover
              placement="bottom-end"
              content={() => (
                <div>
                  <PopoverContent />
                </div>
              )}
            >
              <StyledAccount>
                <Image imageUrl={userImageUrl} />
              </StyledAccount>
            </StatefulPopover>
          </div>
        </HeaderContent>
      </StyledHeader>
      <SubHeader>
        <SubHeaderContent>
          <SubHeaderNavigation>
            <StyledNavLink to="/" exact>
              <span>{t("Home")}</span>
            </StyledNavLink>
            {isManageMoneyEnabled && (
              <StyledNavLink to="/manage-money/deposit">
                <span>{t("Deposit")}</span>
              </StyledNavLink>
            )}
            <StyledNavLink to="/metatrader">
              <span>{t("NAV.METATRADER")}</span>
            </StyledNavLink>
            {utils.canAccessDocuments(userInfo.user_bo_status) && (
              <StyledNavLink to="/documents">
                <span>{t("NAV.DOCUMENTS")}</span>
              </StyledNavLink>
            )}
            <StyledNavLink to="/help">
              <span>{t("NAV.HELP")}</span>
            </StyledNavLink>
          </SubHeaderNavigation>
          {renderCtaButton()}
        </SubHeaderContent>
      </SubHeader>
    </Wrapper>
  );
};

export default Header;
