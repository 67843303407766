import create from "zustand";
import cacheHelper from "./helpers/cacheHelper";

const useAuth = create((set) => ({
  isLoggedIn: Boolean(cacheHelper.getToken()) || false,
  token: cacheHelper.getToken() || null,
  setIsLoggedIn: (value, token) => {
    if (value && token) {
      cacheHelper.setToken(token);
      set({ token });
      return set({ isLoggedIn: value });
    }
    cacheHelper.clearToken();
    return set({ isLoggedIn: value });
  },
}));

const useCrossSite = create((set) => ({
  xsrf: Boolean(cacheHelper.getXsrf()) || "",
  setXsrf: (value) => {
    if (value) {
      cacheHelper.setXsrf(value);
      return set({ xsrf: value });
    }
    cacheHelper.clearXsrf();
    return set({ xsrf: value });
  },
}));

const useRefreshToken = create((set) => ({
  refreshToken: Boolean(cacheHelper.getRefreshToken()) || "",
  refreshTokenExpireTime: Boolean(cacheHelper.getRefreshTokenExpTime()) || "",
  setRefreshToken: (value) => {
    if (value) {
      cacheHelper.setRefreshToken(value);
      return set({ refreshToken: value });
    }
    cacheHelper.clearRefreshToken();
    return set({ refreshToken: value });
  },
  setRefreshTokenExpireTime: (value) => {
    if (value) {
      cacheHelper.setRefreshTokenExpTime(value);
      return set({ refreshTokenExpireTime: value });
    }
    cacheHelper.clearRefreshTokenExpTime();
    return set({ refreshTokenExpireTime: value });
  },
}));

const useDarkMode = create((set) => ({
  isDarkMode: cacheHelper.getDarkMode(),
  setIsDarkMode: (value) => {
    cacheHelper.setDarkMode(value);
    return set({ isDarkMode: value });
  },
}));

const useUserInfo = create((set) => ({
  userInfo: cacheHelper.getUserInfo(),
  setUserInfo: (value) => {
    cacheHelper.setUserInfo(value);
    return set({ userInfo: value });
  },
}));

const useMqttInfo = create((set) => ({
  mqttInfo: {},
  setMqttInfo: (value) => {
    return set({ mqttInfo: value });
  },
}));

const state = {
  useAuth,
  useCrossSite,
  useDarkMode,
  useRefreshToken,
  useUserInfo,
  useMqttInfo,
};

export default state;
