import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Input, FormControl } from "@my-swipestox/components";
import { useTranslation } from "react-i18next";
import moment from "moment";

import api from "../../api";
import constants from "../../constants";
import Select from "../../components/select/Select";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Account = () => {
  const { data } = useQuery(constants.QUERY_NAMES.userInfo, () => api.queries.userInfo());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const info = data?.data?.data || {};
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const updateLanguageMutation = useMutation(api.mutations.updateLanguage);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (info) {
      const userLanguage = constants.SUPPORTED_LANGUAGES.find(el => el.value === info.app_language);
      setLanguage(userLanguage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.app_language]);

  const onLanguageChange = option => {
    updateLanguageMutation.mutate(option.value);
    i18n.changeLanguage(option.value);
    moment.locale(option.value === 'en' ? 'en' : 'zh-cn');
    setLanguage(option);
    queryClient.invalidateQueries(constants.QUERY_NAMES.documentTypes)
    queryClient.setQueryData(constants.QUERY_NAMES.userInfo, { data: { ...data.data, data: {...data.data.data, app_language: option.value }}});
  };

  return (
    <Grid>
      <FormControl label={t("ACCOUNT.USERNAME")}>
        <Input type="text" value={info.user_name} readOnly />
      </FormControl>
      <FormControl label={t("ACCOUNT.EMAIL")}>
        <Input type="text" value={info.email} readOnly />
      </FormControl>
      <FormControl label={t("ACCOUNT.FIRST_NAME")}>
        <Input type="text" value={info.first_name} readOnly />
      </FormControl>
      <FormControl label={t("ACCOUNT.LAST_NAME")}>
        <Input type="text" value={info.last_name} readOnly />
      </FormControl>
      <FormControl label={t("ACCOUNT.PHONE_NUMBER")}>
        <Input type="text" value={info.phone_number} readOnly />
      </FormControl>
      <FormControl label={t("ACCOUNT.LANGUAGE")}>
        <Select
          name="provider"
          options={constants.SUPPORTED_LANGUAGES}
          onChange={onLanguageChange}
          value={language}
        />
      </FormControl>
    </Grid>
  );
};

export default Account;
