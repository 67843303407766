import * as R from "ramda";

import fingerprint from "./fingerprint";
import crypto from "./crypto";
import mqtt from "./mqtt";

import constants from "../constants";

const isPlainObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const isEmpty = (obj) => {
  for (let i in obj) return false;
  return true;
};

const getParamByName = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);

  return param;
};

const canAccessDocuments = (status) =>
  status === constants.USER_STATUS_TYPE.approved ||
  status === constants.USER_STATUS_TYPE.read_only ||
  status === constants.USER_STATUS_TYPE.pending ||
  status === constants.USER_STATUS_TYPE.reviewed;

const isCrypto = (currency) => {
  return R.includes(currency, constants.CRYPTO_CURRENCIES);
};

const isSupported = (currency) => {
  return R.includes(currency, constants.SUPPORTED_CURRENCIES);
};

const utils = {
  fingerprint,
  crypto,
  mqtt,
  isPlainObject,
  isValidDate,
  isEmpty,
  getParamByName,
  canAccessDocuments,
  isCrypto,
  isSupported,
};

export default utils;
