import constants from "../../constants";

export const getDocumentGroupTranslation = (documentGroup) => {
  switch (documentGroup) {
    case constants.DOCUMENT_GROUP.PROOF_OF_IDENTITY:
      return "DOCUMENT_GROUP.PROOF_OF_IDENTITY";
    case constants.DOCUMENT_GROUP.PROOF_OF_RESIDENCE:
      return "DOCUMENT_GROUP.PROOF_OF_RESIDENCE";
    case constants.DOCUMENT_GROUP.PROOF_OF_SOURCE_OF_FUNDS:
      return "DOCUMENT_GROUP.PROOF_OF_SOURCE_OF_FUNDS";
    case constants.DOCUMENT_GROUP.PAYMENT_DOCUMENT:
      return "DOCUMENT_GROUP.PAYMENT_DOCUMENT";
    case constants.DOCUMENT_GROUP.GENERAL:
      return "DOCUMENT_GROUP.GENERAL";
    default:
      return documentGroup;
  }
};

export const getDocumentStatusTranslation = (documentStatus) => {
  switch (documentStatus) {
    case constants.DOCUMENT_STATUS.APPROVED:
      return "DOCUMENT_STATUS.APPROVED";
    case constants.DOCUMENT_STATUS.REJECTED:
      return "DOCUMENT_STATUS.REJECTED";
    case constants.DOCUMENT_STATUS.NEW:
      return "DOCUMENT_STATUS.IN_REVIEW";
    default:
      return documentStatus;
  }
};

export const getDocumentSideTranslation = (documentSide) => {
  switch (documentSide) {
    case constants.DOCUMENT_SIDE.FRONT:
      return "DOCUMENT_SIDE.FRONT";
    case constants.DOCUMENT_SIDE.BACK:
      return "DOCUMENT_SIDE.BACK";
    default:
      return "";
  }
};

export const getRequestStatusTranslation = (requestStatus) => {
  switch (requestStatus) {
    case constants.DOCUMENT_REQUEST_STATUS.REQUESTED:
      return "DOCUMENT_REQUEST_STATUS.REQUESTED";
    case constants.DOCUMENT_REQUEST_STATUS.REJECTED:
      return "DOCUMENT_REQUEST_STATUS.REJECTED";
    default:
      return requestStatus;
  }
};
