import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import get from "lodash/get";
import styled from "styled-components";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  FormControl,
  ParagraphSmall,
  ParagraphLarge,
} from "@my-swipestox/components";

import api from "../../api";
import utils from "../../utils";
import state from "../../state";
import constants from "../../constants";

import UnauthorizedHeader from "../header/UnauthorizedHeader";
import cacheHelper from "../../helpers/cacheHelper";
import LastLoginAttemptModal from './LastLoginAttemptModal';
import Geetest from "../../components/geetest/Geetest";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const Content = styled.div`
  border-radius: 3px;
  max-width: 32rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const FormWrapper = styled.div`
  padding: 4rem 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    padding: 4rem;
  }
`;

const Footer = styled.footer`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const StyledLink = styled.span`
  color: ${(props) => props.theme.color.primary};
  cursor: pointer;
`;

const Error = styled.div`
  color: #ee3142;
  font-size: 14px;
  font-weight: 300;
`;

const StyledSubheadline = styled(ParagraphLarge)`
  text-align: center;
`;

const ForgotPasswordText = styled(ParagraphSmall)`
  text-align: right;
  margin-bottom: 1rem;
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const [showLastLoginAttemptModal, setShowLastLoginAttemptModal] = useState(false);
  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState();
  const [blockedFor, setBlockedFor] = useState();
  const [error, setError] = useState('');
  const [captcha, setCaptcha] = useState(null);
  const valuesRef = useRef(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const setRefreshTokenExpireTime = state.useRefreshToken(
    (state) => state.setRefreshTokenExpireTime
  );
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const history = useHistory();
  const { t } = useTranslation();

  const closeLastLoginAttemptModal = () => {
    setShowLastLoginAttemptModal(false);
  };

  const onSuccess = (data) => {
    const token = data.data.info.token;
    const xsrf = data.data.info.xsrf;
    const userInfo = data.data.data;
    setXsrf(xsrf);
    setRefreshToken(data.data.info.refreshToken);
    setRefreshTokenExpireTime(data.data.info.token_expiration);
    setUserInfo(userInfo);

    if (userInfo.mfa_enabled) {
      cacheHelper.setToken(token);
      history.push("/mfa");
    } else {
      setIsLoggedIn(true, token);
      history.push("/");
    }
  };
  const handleOnError = (data) => {
    const status = data?.response?.status;
    const { login_attempts_left, login_attempts_limit, blocked_for } = data?.response?.data?.info || {};
    let err = '';

    if (status === 403 && data?.response?.data && parseInt(data.response.data.code, 10) === 1071) {
      captcha.showCaptcha();
      setShowCaptcha(true);
      return;
    }

    if ([450, 451].includes(status)) {
      if (login_attempts_left === undefined) {
        err = t("LOGIN.USERNAME_OR_PASSWORD_MISMATCH");
      } else if (login_attempts_left <= 1) {
        setShowLastLoginAttemptModal(true);
        setLoginAttemptsLeft(login_attempts_left);
        setBlockedFor(blocked_for);
      } else if (login_attempts_left === login_attempts_limit - 1) {
        err = t('LOGIN.LOGIN_NOT_SUCCESSFULL_FIRST_TIME');
      } else if (login_attempts_left > 1) {
        err = t('LOGIN.LOGIN_NOT_SUCCESSFULL_OTHER_TIME', { login_attempts_left });
      }
    } else {
      const errorCode = get(data, "response.data.info.errorCode");

      err = t(constants.LOGIN_STATUS_CODES[errorCode] || "REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER");
    }

    setError(err);
  };
  const loginMutation = useMutation(api.mutations.login, {
    onSuccess,
    onError: handleOnError,
  });
  const onSubmit = async (values) => {
    valuesRef.current = values;
    if (showCaptcha) {
      captcha.showCaptcha();
    } else {
      onCaptchaSuccess()
    }
  };
  const onCaptchaSuccess = async (captcha) => {
    const device_uuid = await utils.fingerprint();
    const password = utils.crypto.encode(valuesRef.current.password);
    const data = {
      device_uuid,
      password,
      user_name: valuesRef.current.username,
      captcha,
      get_user_info: true,
    };

    loginMutation.mutate(data);
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t("LOGIN.EMAIL_OR_USERNAME_IS_REQUIRED")),
    password: Yup.string().required(t("LOGIN.PASSWORD_IS_REQUIRED")),
  });
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const reason = utils.getParamByName("reason");
  const handleOnRegisterClick = () => {
    if (reason) {
      return history.push(`/register?reason=${reason}`);
    }
    return history.push(`/register`);
  };
  const handleOnResetClick = () => {
    if (reason) {
      return history.push(`/reset-password?reason=${reason}`);
    }
    return history.push(`/reset-password`);
  };

  return (
    <Grid>
      <UnauthorizedHeader />
      <LastLoginAttemptModal
        isOpen={showLastLoginAttemptModal}
        onClose={closeLastLoginAttemptModal}
        loginAttemptsLeft={loginAttemptsLeft}
        loginAttemptsBlockedFor={blockedFor}
      />
      <Wrapper>
        <Content>
          <FormWrapper>
            <StyledSubheadline mb="1rem">{t("REGISTRATION.WELCOME")}</StyledSubheadline>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                caption={(formik.touched.username && formik.errors.username) || ""}
                error={Boolean(formik.errors.username && formik.touched.username)}
                mb="1rem"
              >
                <Input
                  type="text"
                  name="username"
                  placeholder={t("LOGIN.EMAIL_OR_USERNAME")}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                caption={(formik.touched.password && formik.errors.password) || ""}
                error={Boolean(formik.errors.password && formik.touched.password)}
                mb="1rem"
              >
                <Input
                  type="password"
                  name="password"
                  placeholder={t("LOGIN.PASSWORD")}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              {error && <Error>{error}</Error>}
              <Button style={{ margin: '16px 0' }} shouldFitContainer type="submit" isLoading={loginMutation.isLoading}>
                <span>{t("LOGIN.SIGN_IN")}</span>
              </Button>
              <ForgotPasswordText>
                <StyledLink onClick={handleOnResetClick}>{t("LOGIN.FORGOT_PASSWORD")}</StyledLink>
              </ForgotPasswordText>
            </form>
            <CaptchaWrapper>
              <Geetest captcha={captcha} setCaptcha={setCaptcha} onSuccess={onCaptchaSuccess} />
            </CaptchaWrapper>
          </FormWrapper>
          <Footer>
            <ParagraphSmall as="span">
              {t("LOGIN.DONT_HAVE_AN_ACCOUNT")}{" "}
              <StyledLink onClick={handleOnRegisterClick}>{t("LOGIN.SIGN_UP")}</StyledLink>
            </ParagraphSmall>
          </Footer>
        </Content>
      </Wrapper>
    </Grid>
  );
};

export default Login;
