import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spinner } from "@my-swipestox/components";

import utils from "../../utils";
import api from "../../api";
import constants from "../../constants";
import state from "../../state";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const AutoLoginViaToken = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = utils.getParamByName("page");
  const terminalId = utils.getParamByName("terminal_id");
  const userId = utils.getParamByName("user_id");
  const token = utils.getParamByName("token");
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const setRefreshTokenExpireTime = state.useRefreshToken(
    (state) => state.setRefreshTokenExpireTime
  );
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);
  const tokenLoginMutation = useMutation(api.mutations.tokenLogin, {
    onError: (error) => {
      const code = error?.response?.data?.info?.errorCode || null;
      toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
    },
    onSuccess: (data) => {
      const token = data.data.info.token;
      const xsrf = data.data.info.xsrf;
      const userInfo = data.data.data;

      setIsLoggedIn(true, token);
      setXsrf(xsrf);
      setRefreshToken(data.data.info.refreshToken);
      setRefreshTokenExpireTime(data.data.info.token_expiration);
      setUserInfo(userInfo);
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      if (page === "upgrade") {
        return history.push("/live-account");
      }
      return history.push("/");
    }
  }, [isLoggedIn, page, history]);

  useEffect(() => {
    async function handleLogin() {
      const deviceUuid = await utils.fingerprint();
      const data = {
        user_id: userId,
        terminal_id: terminalId ? parseInt(terminalId, 10) : null,
        device_uuid: deviceUuid,
        get_user_info: true,
        token,
      };
      tokenLoginMutation.mutate(data);
    }
    if (!tokenLoginMutation.isLoading) {
      handleLogin();
    }
  }, [terminalId, userId, token, tokenLoginMutation]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default AutoLoginViaToken;
