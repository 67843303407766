import {
  ParagraphSmall,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "@my-swipestox/components";
import { CheckCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ModalHeaderContent = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 1rem;
    color: ${(props) => props.theme.color.positive};
  }
`;

const InternalTransferSuccessModal = (props) => {
  const { isOpen = false, onClose, data } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        <ModalHeaderContent>
          <CheckCircle size={16} />
          {t("MANAGE_MONEY.TRANSFER.INTERNAL_TRANSFER")}
        </ModalHeaderContent>
      </ModalHeader>
      <ModalBody>
        <ParagraphSmall mb="1rem">
          {t("MANAGE_MONEY.TRANSFER.YOU_HAVE_SUCCESSFULY_TRANSFERED", {
            currency: data.currency,
            amount: data.amount,
            customNameWithLogin: data.customNameWithLogin,
          })}
        </ParagraphSmall>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose}>{t("OK")}</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default InternalTransferSuccessModal;
