import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import constants from "../../constants";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Image = styled.img`
  width: 2.1rem;
  margin-right: 7px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountName = styled.label`
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const AccountNumber = styled.div`
  font-size: ${(props) => props.theme.typography.size.smallText};
  color: ${(props) => props.theme.color.contentSecondary};
`;

const Leverage = styled.span`
  margin-left: 10px;
`;

const TradingAccountShortDesc = (props) => {
  const { currency, name, accountNumber, leverage } = props;
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Image src={`${constants.WALLET_S3_CONTENT}/${currency.toLowerCase()}.png`} />
      <DetailsWrapper>
        <AccountName>{name}</AccountName>
        <AccountNumber>
          <span>#{accountNumber}</span>
          {leverage && (
            <Leverage>
              {t("LOBBY.LEVERAGE")} {leverage}
            </Leverage>
          )}
        </AccountNumber>
      </DetailsWrapper>
    </Wrapper>
  );
};

TradingAccountShortDesc.propTypes = {
  currency: PropTypes.string,
  name: PropTypes.string,
  accountNumber: PropTypes.string,
  leverage: PropTypes.string,
};

export default TradingAccountShortDesc;
