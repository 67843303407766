import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import styled from "styled-components";

const Positive = styled(NumberFormat)`
  color: ${(props) => props.theme.color.positive};
`;
const Negative = styled(NumberFormat)`
  color: ${(props) => props.theme.color.negative};
`;
const Neutral = styled(NumberFormat)`
  color: inherit;
`;

const Amount = (props) => {
  const { amount, decimals, symbol, noColor } = props;

  const componentProps = {
    value: parseFloat(amount || 0).toFixed(decimals),
    prefix: symbol,
    displayType: "text",
    thousandSeparator: true,
    decimalScale: decimals >= 0 ? decimals : 2,
    ...props,
  };

  if (noColor || parseInt(componentProps.value, 10) === 0) {
    return <Neutral {...componentProps} />;
  }
  if (componentProps.value > 0) {
    return <Positive {...componentProps} />;
  }
  return <Negative {...componentProps} />;
};

Amount.propTypes = {
  amount: PropTypes.any,
  symbol: PropTypes.string,
  decimals: PropTypes.number,
  noColor: PropTypes.bool,
};

export default Amount;
