import { useTranslation } from "react-i18next";
import { StatefulPopover } from "@malcodeman/react-popover";
import { Info } from "react-feather";
import styled from "styled-components";
import { Button } from "@my-swipestox/components";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";

import {
  getDocumentGroupTranslation,
  getDocumentStatusTranslation,
  getDocumentSideTranslation,
  getRequestStatusTranslation,
} from "./DocumentsHelper";

const Table = styled.div`
  position: relative;
  left: calc(-1rem - 1px);
  width: calc(100% + 2rem + 2px);
  border: 0;
  border-radius: 0;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    position: unset;
    left: unset;
    right: unset;
    width: auto;
    border: 1px solid ${(props) => props.theme.color.border};
    border-radius: 3px;
  }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  color: ${(props) => props.theme.color.contentSecondary};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  font-size: ${(props) => props.theme.typography.size.normalText};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  &:nth-of-type(n + 3) {
    margin-top: 8px;
  }
`;

const TableRowMobile = styled(TableRow)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.color.border};
  font-size: ${(props) => props.theme.typography.size.normalText};
  &:nth-of-type(n + 2) {
    margin-top: 8px;
  }
`;

const TableCellMobile = styled.div`
  display: flex;
  &:nth-of-type(n + 2) {
    margin-top: 15px;
  }
  > span {
    &:first-of-type {
      color: #7f93bc;
      font-weight: 500;
      margin-right: 12px;
    }
  }
  > button {
    line-height: 20px;
  }
`;

const TableCell = styled.span`
  display: inline-block;
  font-size: ${(props) => props.theme.typography.size.normalText};
  &:nth-of-type(1),
  &:nth-of-type(2) {
    width: 18%;
  }
  &:nth-of-type(3) {
    width: 12%;
  }
  &:nth-of-type(4),
  &:nth-of-type(6) {
    width: 14%;
  }
  &:nth-of-type(5) {
    width: 13%;
  }
  &:nth-of-type(7) {
    width: 11%;
  }
`;

const DocumentStatusPanel = styled.div`
  padding: 8px;
  max-width: 200px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 3px;
  > div {
    &:first-of-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 8px;
      > svg {
        margin-right: 5px;
      }
    }
  }
`;

const DocumentStatus = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-left: 4px;
    min-width: ${(props) => props.theme.typography.size.normalText};
  }
`;

const DocumentStatusRed = styled(DocumentStatus)`
  color: #ee3142;
`;

const DocumentStatusBlue = styled(DocumentStatus)`
  color: ${(props) => props.theme.color.primary};
`;

const StyledButton = styled(Button)`
  font-size: ${(props) => props.theme.typography.size.normalText};
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    color: ${(props) => props.theme.color.primary};
    background-color: transparent;
    border: 0;
    font-weight: 500;
  }
`;

const DocumentsTable = (props) => {
  const { t } = useTranslation();
  const { documents, setDocument } = props;

  const isMobile = useIsMobile();

  const handleActionClick = (document) => {
    setDocument(document);
  };

  const renderDocumentStatus = (document) => {
    let DocumentStatusComponent = DocumentStatus;
    const documentStatus = t(
      document.isRequest
        ? getRequestStatusTranslation(document.request_status)
        : getDocumentStatusTranslation(document.status)
    );

    if (document.isRequest) {
      DocumentStatusComponent =
        document.request_status === constants.DOCUMENT_REQUEST_STATUS.REJECTED
          ? DocumentStatusRed
          : DocumentStatusBlue;
    }

    if (document.custom_message) {
      return (
        <StatefulPopover
          placement="top"
          triggerType="hover"
          content={() => (
            <DocumentStatusPanel>
              <div>
                <Info size={14} color="yellow" />
                {documentStatus}
              </div>
              <div>{document.custom_message}</div>
            </DocumentStatusPanel>
          )}
        >
          <DocumentStatusComponent>
            {documentStatus} <Info size={14} />
          </DocumentStatusComponent>
        </StatefulPopover>
      );
    }

    return <DocumentStatusComponent>{documentStatus}</DocumentStatusComponent>;
  };

  const renderDocumentAction = (document) => {
    let actionText = null;

    if (document.isRequest) {
      if (document.request_status === constants.DOCUMENT_REQUEST_STATUS.REQUESTED) {
        actionText = "DOCUMENTS.UPLOAD";
      } else if (document.request_status === constants.DOCUMENT_REQUEST_STATUS.REJECTED) {
        actionText = "DOCUMENTS.REUPLOAD";
      }
    }

    if (!actionText) {
      return null;
    }

    return (
      <StyledButton type="button" onClick={() => handleActionClick(document)}>
        {t(actionText)}
      </StyledButton>
    );
  };

  if (isMobile) {
    return (
      <Table>
        {documents.map((document, index) => (
          <TableRowMobile key={index}>
            <TableCellMobile>
              <span>{t("DOCUMENTS.DOCUMENT_CATEGORY")}</span>
              <span>{t(getDocumentGroupTranslation(document.document_group))}</span>
            </TableCellMobile>
            <TableCellMobile>
              <span>{t("DOCUMENTS.DOCUMENT_TYPE")}</span>
              <span>{document.document_type}</span>
            </TableCellMobile>
            <TableCellMobile>
              <span>{t("DOCUMENTS.SIDE")}</span>
              <span>{document.side ? t(getDocumentSideTranslation(document.side)) : "-"}</span>
            </TableCellMobile>
            <TableCellMobile>
              <span>{t("DOCUMENTS.UPLOAD_DATE")}</span>
              <span>{document.creation_date || "-"}</span>
            </TableCellMobile>
            <TableCellMobile>
              <span>{t("DOCUMENTS.STATUS")}</span>
              {renderDocumentStatus(document)}
            </TableCellMobile>
            <TableCellMobile>
              <span>{t("DOCUMENTS.ISSUING_EXPIRATION_DATE")}</span>
              <span>{document.expiration_date || "-"}</span>
            </TableCellMobile>
            <TableCellMobile>{renderDocumentAction(document)}</TableCellMobile>
          </TableRowMobile>
        ))}
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableCell>{t("DOCUMENTS.DOCUMENT_CATEGORY")}</TableCell>
        <TableCell>{t("DOCUMENTS.DOCUMENT_TYPE")}</TableCell>
        <TableCell>{t("DOCUMENTS.SIDE")}</TableCell>
        <TableCell>{t("DOCUMENTS.UPLOAD_DATE")}</TableCell>
        <TableCell>{t("DOCUMENTS.STATUS")}</TableCell>
        <TableCell>{t("DOCUMENTS.ISSUING_EXPIRATION_DATE")}</TableCell>
        <TableCell></TableCell>
      </TableHeader>
      {documents.map((document, index) => (
        <TableRow key={index}>
          <TableCell>{t(getDocumentGroupTranslation(document.document_group))}</TableCell>
          <TableCell>{document.document_type}</TableCell>
          <TableCell>
            {document.side ? t(getDocumentSideTranslation(document.side)) : "-"}
          </TableCell>
          <TableCell>{document.creation_date || "-"}</TableCell>
          <TableCell>{renderDocumentStatus(document)}</TableCell>
          <TableCell>{document.expiration_date || "-"}</TableCell>
          <TableCell>{renderDocumentAction(document)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default DocumentsTable;
