import React from "react";
import styled from "styled-components";

import constants from "../constants";
import Header from "../features/header/Header";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Main = styled.main`
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  padding: 1rem;
`;

const Container = styled.div`
  margin: 0rem auto;
  max-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES};
  height: 100%;
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 3px;
`;

function Layout(props) {
  const { children } = props;

  return (
    <Grid>
      <Header />
      <Main>
        <Container>{children}</Container>
      </Main>
    </Grid>
  );
}

export default Layout;
