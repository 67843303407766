import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, ModalBody, ModalButton, ModalHeader } from "@my-swipestox/components";
import constants from "../../constants";

const KycContinueLaterModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 472px;
  
  @media (max-width: 768px) {
    width: 100%;
    align-self: flex-end;
  }
  .modal-header {
    border: 0;
    font-weight: 500;
    font-size: 20px;
    button {
      background-color: var(--modalCloseBtnBackgroundColor);
      font-weight: 300;
      text-shadow: none;
      color: var(--appGrey1);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      opacity: 1;
    }
  }
  .kyc-continue-later-modal__button {
    height: 48px;
    width: 100%;
  }
  .kyc-continue-later-modal__button:last-child {
    margin-top: 16px;
    color: ${(props) => props.theme.color.kycContinueButton};
    background-color: ${(props) => props.theme.color.kycCancelButtonBackground};
    border: none;
    &:hover {
      color: ${(props) => props.theme.color.headerFill};
    }
  }
  .kyc-continue-later-modal__footer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-color: transparent;
    padding-left: 24px;
    padding-bottom: 24px;
  }
  .kyc-continue-later-modal__description {
    margin: 24px;
    text-align: center;
  }
  .kyc-continue-later-modal__header {
    border-radius: 15px 15px 0 0;
    text-align: center;
    font-size: 20px;
  }
  .kyc-continue-later-modal__body {
    border-radius: 0 0 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .kyc-continue-later-modal__icon {
    width: 148px;
    height: 148px;
  }
`;

const KycContinueLaterModal = (props, context) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isOpen, handleShowContinueLaterModal, onCancel } = props;

  const onSaveAndExit = () => {
    history.push("/");
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleShowContinueLaterModal}>
      <KycContinueLaterModalWrapper>
        <ModalHeader closeButton>
          <div className="kyc-continue-later-modal__header">
            {t('KYC.EXIT')}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="kyc-continue-later-modal__body">
            <img className="kyc-continue-later-modal__icon" alt="success" src={`${constants.IMAGES_URL}/illustration-autocopy.svg`} />
            <p className="kyc-continue-later-modal__description">{t('KYC.CONTINUE_LATER_MODAL_CONTENT')}</p>
          </div>
        </ModalBody>
        <div className="kyc-continue-later-modal__footer-wrapper">
          <ModalButton bsStyle="secondary" className="kyc-continue-later-modal__button" onClick={onSaveAndExit}>
            {t('KYC.SAVE_AND_EXIT')}
          </ModalButton>
          <ModalButton bsStyle="secondary" className="kyc-continue-later-modal__button" onClick={handleShowContinueLaterModal}>
            {t('KYC.CONTINUE_VERIFICATION')}
          </ModalButton>
        </div>
      </KycContinueLaterModalWrapper>
    </Modal>
  );
};

export default KycContinueLaterModal;
