import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "@my-swipestox/components";
import { ChevronLeft } from "react-feather";

import constants from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import Select from "../../components/select/Select";

const ButtonLink = styled(Button)`
  color: ${(props) => props.theme.color.primary};
  background-color: transparent;
  border: 0;
  font-weight: 500;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0;
`;

const TransactionsFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 42px;
`;

const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 128px;
  margin-bottom: 5px;
  margin-right: 5px;
  @media screen and (max-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    color: #7f93bc;
    text-align: left;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const TransactionSelect = styled(Select)`
  width: 100%;
`;

const FilterItemInput = styled.input`
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid ${(props) => props.theme.color.border};
  height: 36px;
  padding-left: 5px;
  max-width: 128px;
  background-color: ${(props) => props.theme.color.inputFill};
  color: ${(props) => props.theme.color.contentPrimary};

  @media screen and (max-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 100%;
    max-width: none;
  }
`;
const TransactionsMobileFilterContainer = styled.div`
  @media screen and (max-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    padding: 10px 20px 20px 20px;
  }
`;

const TransactionsMobileFilterHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const TransactionsMobileFilterTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  color: ${(props) => props.theme.color.contentPrimary};
  letter-spacing: 0;
  line-height: 32px;
`;

const TransactionsMobileFilter = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { setDisplayMobileFilter, updateTransferHistoryFilter, filters } = props;

  const getDataPickerValues = () => {
    return [
      {
        value: constants.DATE_PICKER_OPTIONS.ALL,
        label: t("DATEPICKER.ALL"),
      },
      {
        value: constants.DATE_PICKER_OPTIONS.LAST_SEVEN,
        label: t("DATEPICKER.LAST_SEVEN"),
      },
      {
        value: constants.DATE_PICKER_OPTIONS.LAST_THIRTY,
        label: t("DATEPICKER.LAST_THIRTY"),
      },
      {
        value: constants.DATE_PICKER_OPTIONS.LAST_YEAR,
        label: t("DATEPICKER.LAST_YEAR"),
      },
    ];
  };

  const getTransactionStatusPickerValues = () => {
    return [
      {
        value: "all",
        label: t("TRANSACTION_STATUS.ALL"),
      },
      {
        value: "Transferred",
        label: t("TRANSACTION_STATUS.TRANSFERRED"),
      },
      {
        value: "Confirmed",
        label: t("TRANSACTION_STATUS.CONFIRMED"),
      },
      {
        value: "Pending",
        label: t("TRANSACTION_STATUS.PENDING"),
      },
      {
        value: "Received",
        label: t("TRANSACTION_STATUS.RECEIVED"),
      },
      {
        value: "Cancelled",
        label: t("TRANSACTION_STATUS.CANCELLED"),
      },
      {
        value: "Rejected",
        label: t("TRANSACTION_STATUS.REJECTED"),
      },
      {
        value: "Declined",
        label: t("TRANSACTION_STATUS.DECLINED"),
      },
      {
        value: "Earned",
        label: t("TRANSACTION_STATUS.EARNED"),
      },
      {
        value: "Refunded",
        label: t("TRANSACTION_STATUS.REFUNDED"),
      },
      {
        value: "Abandoned",
        label: t("TRANSACTION_STATUS.ABANDONED"),
      },
      {
        value: "Sent",
        label: t("TRANSACTION_STATUS.SENT"),
      },
      {
        value: "Deposited",
        label: t("TRANSACTION_STATUS.DEPOSITED"),
      },
      {
        value: "Processing",
        label: t("TRANSACTION_STATUS.PROCESSING"),
      },
      {
        value: "Unconfirmed",
        label: t("TRANSACTION_STATUS.UNCONFIRMED"),
      },
      {
        value: "Waiting for confirmation",
        label: t("TRANSACTION_STATUS.WAITING_FOR_CONFIRMATION"),
      },
      {
        value: "Error",
        label: t("TRANSACTION_STATUS.ERROR"),
      },
    ];
  };

  const renderTransactionFilter = () => {
    const { amount, paymentMethod, subject, paymentDate, creditDate, status } = filters;

    return (
      <TransactionsFilter>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.AMOUNT_INVESTED_FILTER")} </p>
          <FilterItemInput
            type="number"
            value={amount}
            onChange={(event) => updateTransferHistoryFilter(event.currentTarget.value, "amount")}
          />
        </FilterItem>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.PAYMENT_METHOD_FILTER")} </p>
          <FilterItemInput
            type="text"
            value={paymentMethod}
            onChange={(event) =>
              updateTransferHistoryFilter(event.currentTarget.value, "paymentMethod")
            }
          />
        </FilterItem>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.SUBJECT_FILTER")} </p>
          <FilterItemInput
            type="text"
            value={subject}
            onChange={(event) => updateTransferHistoryFilter(event.currentTarget.value, "subject")}
          />
        </FilterItem>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.PAYMENT_DATE_FILTER")} </p>
          <TransactionSelect
            onChange={(event) => updateTransferHistoryFilter(event, "paymentDate")}
            options={getDataPickerValues()}
            placeholder={t("PAYMENT_HISTORY.PAYMENT_DATE_FILTER")}
            value={paymentDate || getDataPickerValues()[0]}
          />
        </FilterItem>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.CREDIT_DATE_FILTER")} </p>
          <TransactionSelect
            onChange={(event) => updateTransferHistoryFilter(event, "creditDate")}
            options={getDataPickerValues()}
            placeholder={t("PAYMENT_HISTORY.CREDIT_DATE_FILTER")}
            value={creditDate || getDataPickerValues()[0]}
          />
        </FilterItem>
        <FilterItem>
          <p> {t("PAYMENT_HISTORY.STATUS_FILTER")} </p>
          <TransactionSelect
            onChange={(event) => updateTransferHistoryFilter(event, "status")}
            options={getTransactionStatusPickerValues()}
            placeholder={t("PAYMENT_HISTORY.STATUS_FILTER")}
            value={status || getTransactionStatusPickerValues()[0]}
          />
        </FilterItem>
      </TransactionsFilter>
    );
  };

  return (
    <TransactionsMobileFilterContainer>
      {isMobile && (
        <TransactionsMobileFilterHeader>
          <ButtonLink onClick={() => setDisplayMobileFilter(false)}>
            <ChevronLeft /> <span> {t("PAYMENT_HISTORY.BACK")} </span>
          </ButtonLink>
          <TransactionsMobileFilterTitle>
            {t("PAYMENT_HISTORY.FILTER_TRANSFER_HISTORY")}
          </TransactionsMobileFilterTitle>
        </TransactionsMobileFilterHeader>
      )}
      {renderTransactionFilter()}
    </TransactionsMobileFilterContainer>
  );
};

export default TransactionsMobileFilter;
