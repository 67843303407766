/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import styled from "styled-components";
import { VerifyIdentity, VerifyResidence } from "@my-swipestox/document-verfication";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { Info, Check, Clock } from "react-feather";
import { Button } from "@my-swipestox/components";

import state from "../../state";
import api from "../../api";
import constants from "../../constants";
import kycConstants from "./constants";
import utils from "../../utils";

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  padding: 1rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
`;

const TooltipContent = styled.div`
  margin-top: 1rem;
  color: #fff;
  background-color: #3b3d72;
  padding: 1rem;
  z-index: 1;
  border-radius: 4px;
`;

const TooltipGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 140px);
  padding-bottom: 48px;
`;

const TooltipItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  svg {
    color: #ffbf3d;
    margin-right: 10px;
  }
`;

const TooltipTitle = styled.span`
  font-size: 1rem;
`;

const TooltipImage = styled.img`
  width: 140px;
  height: 140px;
  margin-bottom: 8px;
`;

const TooltipLabel = styled.span`
  font-size: 0.8rem;
`;

const SkipButton = styled(Button)`
  margin-right: 0.5rem;
`;

const AlreadyApprovedContainer = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AlreadyApprovedTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 12px;
`;

const AlreadyApprovedText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.color.contentSecondary};
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    max-width: 50%;
  }
`;

const StyledCheckIcon = styled(Check)`
  margin-bottom: 48px;
  color: ${(props) => props.theme.color.positive};
`;

const StyledClockIcon = styled(Clock)`
  margin-bottom: 48px;
  color: ${(props) => props.theme.color.warning};
`;

const Verification = () => {
  const { t } = useTranslation();
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const documentTypesQuery = useQuery(constants.QUERY_NAMES.documentTypes, () =>
    api.queries.getDocumentTypes({ document_groups: ["PoI", "PoR"] })
  );

  const verificationDataQuery = useQuery("verificationData", () =>
    api.queries.getVerificationData()
  );
  const documentVerificationData = verificationDataQuery.data?.data?.data || {
    poi: { front: {}, back: {} },
    por: { front: {}, back: {} },
  };
  const documentTypes = documentTypesQuery.data?.data?.data || [];
  const poiDocuments =
    documentTypes[0]?.documents
      .filter((item) => !item.document_type.includes('SumSub') && !item.document_type.includes('Selfie'))
      .map((item) => {
        return {
          ...item,
          label: item.document_type,
          value: item.document_type_id,
        };
      })
      .sort((a, b) => a.position - b.position) || [];
  const porDocuments =
    documentTypes[1]?.documents
      .filter((item) => !item.document_type.includes('SumSub') && !item.document_type.includes('Onfido'))
      .map((item) => {
        return {
          ...item,
          label: item.document_type,
          value: item.document_type_id,
        };
      }) || [];
  const [poiDocument, setPoiDocument] = useState({
    label: "",
    value: "",
    min_expiry_days: 0,
  });
  const [porDocument, setPorDocument] = useState({
    label: "",
    value: "",
    min_expiry_days: 0,
  });
  const [documentUploadIsLoading, setDocumentUploadIsLoading] = useState(false);
  const [documentUploadIsSuccess, setDocumentUploadIsSuccess] = useState(false);
  const history = useHistory();
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const isPORRequired = poiDocument.document_type_id !== constants.DOCUMENT_TYPE_ID.NATIONAL_ID;

  const getIssuingDateMin = () => {
    return moment(new Date()).subtract(Math.abs(porDocument.min_expiry_days), "days").toDate();
  };
  const getExpirationDateMin = () => {
    return moment(new Date())
      .add(poiDocument.min_expiry_days + 1, "days")
      .toDate();
  };

  const filesInitial = {
    poiFront: { file: null, document: { expiration_date: "" } },
    poiBack: { file: null, document: { expiration_date: "" } },
    por: {
      file: null,
      document: { expiration_date: getIssuingDateMin() },
    },
  };
  const [files, setFiles] = useState(filesInitial);
  const [expirationDate, setExpirationDate] = useState("");
  const [issuingDate, setIssuingDate] = useState("");
  const expirationDateModifiers = {
    disabled: [
      {
        before: getExpirationDateMin(),
      },
    ],
  };
  const issuingDateModifiers = {
    disabled: [
      {
        before: getIssuingDateMin(),
        after: new Date(),
      },
    ],
  };
  const documentsExistsSettings = {
    [constants.USER_STATUS_TYPE.approved]: {
      image: <StyledCheckIcon size="64" />,
      title: t("DOCUMENT_VERIFICATION.ALREADY_APPROVED_TITLE"),
      text: t("DOCUMENT_VERIFICATION.ALREADY_APPROVED_TEXT"),
    },
    [constants.USER_STATUS_TYPE.pending]: {
      image: <StyledClockIcon size="64" />,
      title: t("DOCUMENT_VERIFICATION.PENDING_TITLE"),
      text: t("DOCUMENT_VERIFICATION.PENDING_TEXT"),
    },
  };

  const getFileName = (name) => {
    const split = name.split(".");

    return `${userInfo.user_id}/${split[0]}_${Date.now()}.${split[1]}`;
  };

  const poiDocumentFrontOnDrop = (props) => {
    setFiles({
      ...files,
      poiFront: {
        file: props[0],
        name: getFileName(props[0].name),
        document: {
          ...poiDocument,
          ...files.poiFront.document,
          document_side: kycConstants.DOCUMENT_SIDE.FRONT,
        },
      },
    });
  };

  const poiDocumentBackOnDrop = (props) => {
    setFiles({
      ...files,
      poiBack: {
        file: props[0],
        name: getFileName(props[0].name),
        document: {
          ...poiDocument,
          ...files.poiBack.document,
          document_side: kycConstants.DOCUMENT_SIDE.BACK,
        },
      },
    });
  };

  const porDocumentOnDrop = (props) => {
    setFiles({
      ...files,
      por: {
        file: props[0],
        name: getFileName(props[0].name),
        document: {
          ...porDocument,
          ...files.por.document,
          document_side: kycConstants.DOCUMENT_SIDE.FRONT,
        },
      },
    });
  };

  const expirationDateOnChange = (date) => {
    setExpirationDate(date);
  };

  const issuingDateOnChange = (date) => {
    setIssuingDate(date);
  };

  const porOnClose = () => {
    setFiles({
      ...files,
      por: filesInitial.por,
    });
  };

  const poiFrontOnClose = () => {
    setFiles({
      ...files,
      poiFront: filesInitial.poiFront,
    });
  };

  const poiBackOnClose = () => {
    setFiles({
      ...files,
      poiBack: filesInitial.poiBack,
    });
  };

  const renderTooltipContent = (content) => {
    return (
      <TooltipContent>
        <TooltipHeader>
          <Info size={16} />
          <TooltipTitle>{t(content.title)}</TooltipTitle>
        </TooltipHeader>
        <TooltipGrid>
          {content.list.map((item) => {
            return (
              <TooltipItem key={item.label}>
                <TooltipImage src={item.image} alt="" />
                <TooltipLabel>{t(item.label)}</TooltipLabel>
              </TooltipItem>
            );
          })}
        </TooltipGrid>
      </TooltipContent>
    );
  };

  const hidden = poiDocument.has_sides === false ? [kycConstants.DOCUMENT_SIDE.BACK] : [];
  const poiInfo = renderTooltipContent(kycConstants.POI_TOOLTIP);
  const porInfo = renderTooltipContent(kycConstants.POR_TOOLTIP);
  const getPorCaption = () => {
    switch (documentVerificationData.por.status) {
      case kycConstants.DOCUMENT_STATUS.APPROVED:
        return t("KYC.DOCUMENT_HAS_BEEN_APPROVED");
      case kycConstants.DOCUMENT_STATUS.REJECTED:
        return documentVerificationData.por.reasons[0];
      case kycConstants.DOCUMENT_STATUS.NEW:
        return t("KYC.VERIFICATION_IN_PROGRESS");
      default:
        return "";
    }
  };

  const getPoiCaption = (side) => {
    switch (documentVerificationData.poi[side].status) {
      case kycConstants.DOCUMENT_STATUS.APPROVED:
        return t("KYC.DOCUMENT_HAS_BEEN_APPROVED");
      case kycConstants.DOCUMENT_STATUS.REJECTED:
        return documentVerificationData.poi[side].reasons[0];
      case kycConstants.DOCUMENT_STATUS.NEW:
        return t("KYC.VERIFICATION_IN_PROGRESS");
      default:
        return "";
    }
  };

  const handleVerifyResidenceDisabled = () => {
    const disabled =
      documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.NEW;

    return disabled || !porDocuments.length;
  };

  const handleVerifyIdentityDisabled = () => {
    const poiFront =
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.NEW;
    const poiBack =
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.NEW;

    return (poiFront && poiBack) || !poiDocuments.length;
  };

  const verifyIdentityProps = {
    poiFrontCaption: getPoiCaption(kycConstants.DOCUMENT_SIDE.FRONT),
    poiBackCaption: getPoiCaption(kycConstants.DOCUMENT_SIDE.BACK),
    documentTypeDisabled: handleVerifyIdentityDisabled(),
    expirationDateDisabled: handleVerifyIdentityDisabled(),
    poiFrontImageUploaderDisabled: handleVerifyIdentityDisabled(),
    poiBackImageUploaderDisabled: handleVerifyIdentityDisabled(),
    poiFrontPositive:
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.APPROVED,
    poiBackPositive:
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.APPROVED,
    poiFrontError:
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.REJECTED,
    poiBackError:
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.REJECTED,
    poiFrontIsUploaded:
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.NEW,
    poiBackIsUploaded:
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.NEW,
    poiFrontIsLoading:
      documentUploadIsLoading &&
      documentVerificationData.poi.front.status !== kycConstants.DOCUMENT_STATUS.NEW &&
      documentVerificationData.poi.front.status !== kycConstants.DOCUMENT_STATUS.APPROVED,
    poiBackIsLoading:
      documentUploadIsLoading &&
      documentVerificationData.poi.back.status !== kycConstants.DOCUMENT_STATUS.NEW &&
      documentVerificationData.poi.back.status !== kycConstants.DOCUMENT_STATUS.APPROVED,
    expirationDateInitialMonth: getExpirationDateMin(),
    expirationDateInfo: t("KYC.POI_DATE_INFO"),
  };

  const verifyResidenceProps = {
    documentTypeDisabled: handleVerifyResidenceDisabled(),
    issuingDateDisabled: handleVerifyResidenceDisabled(),
    porImageUploaderDisabled: handleVerifyResidenceDisabled(),
    porPositive: documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.APPROVED,
    porError: documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.REJECTED,
    porCaption: getPorCaption(),
    porIsUploaded: documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.NEW,
    porIsLoading:
      documentUploadIsLoading &&
      documentVerificationData.por.status !== kycConstants.DOCUMENT_STATUS.NEW &&
      documentVerificationData.por.status !== kycConstants.DOCUMENT_STATUS.APPROVED,
    issuingDateInfo: t("KYC.POR_DATE_INFO"),
  };

  const handleCtaDisabled = () => {
    const poiFront =
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.poi.front.status === kycConstants.DOCUMENT_STATUS.NEW;
    const poiBack =
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.poi.back.status === kycConstants.DOCUMENT_STATUS.NEW;
    const por =
      documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.APPROVED ||
      documentVerificationData.por.status === kycConstants.DOCUMENT_STATUS.NEW;

    if (!utils.isValidDate(expirationDate) || (!utils.isValidDate(issuingDate) && isPORRequired)) {
      return true;
    }
    if (poiFront && poiBack && por) {
      return true;
    }
    if (documentUploadIsLoading) {
      return true;
    }
    if (verificationDataQuery.isLoading) {
      return true;
    }
    if (!files.por.file && !por && isPORRequired) {
      return true;
    }
    if (!files.poiFront.file && !poiFront) {
      return true;
    }
    if (!files.poiBack.file && !poiBack && poiDocument.has_sides === true) {
      return true;
    }
    return false;
  };

  const ctaDisabled = handleCtaDisabled();

  const submitDocuments = async () => {
    try {
      setDocumentUploadIsLoading(true);
      for (const file of Object.values(files)) {
        if (!file.file) {
          continue;
        }
        const params = { name: file.name, type: "document" };
        const response = await api.queries.getUploadUrl(params);
        const responseData = response.data.data;
        const uploadUrl = responseData.upload_url;
        const downloadUrl = responseData.download_url.source;

        const payload = {
          additional_data: null,
          document_id: null,
          document_type: "",
          document_type_id: file.document.document_type_id,
          document_url: downloadUrl,
          expiration_date: file.document.expiration_date,
          is_expired: false,
          reason_code: null,
          document_side: file.document.document_side,
          status: null,
        };

        await api.mutations.uploadDocument(uploadUrl, file.file);
        await api.mutations.saveUserDocuments(payload);
        const userInfoResponse = await api.queries.userInfo();
        setUserInfo(userInfoResponse?.data?.data || {});
      }
      setDocumentUploadIsSuccess(true);
    } finally {
      setDocumentUploadIsLoading(false);
    }
  };

  useEffect(() => {
    if (poiDocument.has_sides === false) {
      setFiles({
        ...files,
        poiBack: filesInitial.poiBack,
      });
    }
  }, [poiDocument]);

  useEffect(() => {
    if (poiDocuments.length) {
      if (handleVerifyIdentityDisabled()) {
        const doc = poiDocuments.find((item) => item.status && item.upload_date) || {
          label: "",
          value: "",
          min_expiry_days: 0,
        };

        setPoiDocument(doc);
      } else {
        setPoiDocument(poiDocuments[0]);
      }
    }

    if (porDocuments.length) {
      if (handleVerifyResidenceDisabled()) {
        const doc = porDocuments.find((item) => item.status && item.upload_date) || {
          label: "",
          value: "",
          min_expiry_days: 0,
        };

        setPorDocument(doc);
      } else {
        setPorDocument(porDocuments[0]);
      }
    }
  }, [
    poiDocuments.length,
    porDocuments.length,
    documentVerificationData,
    documentTypesQuery.dataUpdatedAt,
  ]);

  useEffect(() => {
    setFiles({
      ...files,
      poiFront: {
        ...files.poiFront,
        document: {
          ...files.poiFront.document,
          expiration_date: expirationDate,
        },
      },
      poiBack: {
        ...files.poiBack,
        document: {
          ...files.poiBack.document,
          expiration_date: expirationDate,
        },
      },
    });
  }, [expirationDate]);

  useEffect(() => {
    setFiles({
      ...files,
      por: {
        ...files.por,
        document: {
          ...files.por.document,
          expiration_date: issuingDate,
        },
      },
    });
  }, [issuingDate]);

  useEffect(() => {
    if (poiDocument.upload_date) {
      const date = moment(poiDocument.expiration_date).toDate();

      setExpirationDate(date);
    }
    if (porDocument.upload_date) {
      const date = moment(porDocument.expiration_date)
        .subtract(Math.abs(porDocument.min_expiry_days), "days")
        .toDate();

      setIssuingDate(date);
    }
  }, [poiDocument.expiration_date, porDocument.expiration_date]);

  useEffect(() => {
    if (documentUploadIsSuccess) {
      toast.success(t("KYC.DOCUMENTS_UPLOADED_SUCCESSFULLY"));
      history.push("/manage-money/deposit");
      setFiles(filesInitial);
    }
  }, [documentUploadIsSuccess]);

  useEffect(() => {
    verificationDataQuery.refetch();
  }, []);

  if (
    userInfo.user_bo_status === constants.USER_STATUS_TYPE.approved ||
    userInfo.user_bo_status === constants.USER_STATUS_TYPE.pending
  ) {
    const config = documentsExistsSettings[userInfo.user_bo_status];
    return (
      <AlreadyApprovedContainer>
        {config.image}
        <AlreadyApprovedTitle>{config.title}</AlreadyApprovedTitle>
        <AlreadyApprovedText>{config.text}</AlreadyApprovedText>
      </AlreadyApprovedContainer>
    );
  }

  return (
    <Grid>
      <div>
        <VerifyIdentity
          darkMode={isDarkMode}
          poiDocuments={poiDocuments}
          poiDocument={poiDocument}
          poiDocumentOnChange={setPoiDocument}
          poiDocumentFrontOnDrop={poiDocumentFrontOnDrop}
          poiDocumentBackOnDrop={poiDocumentBackOnDrop}
          expirationDate={expirationDate}
          expirationDateOnChange={expirationDateOnChange}
          expirationDateModifiers={expirationDateModifiers}
          poiFrontTitle={t("KYC.FRONT_SIDE")}
          poiBackTitle={t("KYC.BACK_SIDE")}
          poiFrontSubtitle={t("KYC.DRAG_FILES_TO_UPLOAD")}
          poiBackSubtitle={t("KYC.DRAG_FILES_TO_UPLOAD")}
          poiFrontOnClose={poiFrontOnClose}
          poiBackOnClose={poiBackOnClose}
          hidden={hidden}
          title={t("KYC.VERIFY_IDENTITY_NUMBER")}
          info={poiInfo}
          expirationDateLabel={t("DOCUMENTS.EXPIRATION_DATE")}
          documentTypeLabel={t("DOCUMENTS.DOCUMENT_TYPE")}
          {...verifyIdentityProps}
        />
      </div>
      {isPORRequired && (
        <div>
          <VerifyResidence
            darkMode={isDarkMode}
            porDocuments={porDocuments}
            porDocument={porDocument}
            porDocumentOnChange={setPorDocument}
            porDocumentOnDrop={porDocumentOnDrop}
            issuingDate={issuingDate}
            issuingDateOnChange={issuingDateOnChange}
            issuingDateModifiers={issuingDateModifiers}
            porTitle={t("KYC.UPLOAD_DOCUMENT_SCAN")}
            porSubtitle={t("KYC.DRAG_FILES_TO_UPLOAD")}
            porOnClose={porOnClose}
            title={t("KYC.VERIFY_RESIDENCE_NUMBER")}
            info={porInfo}
            issuingDateLabel={t("DOCUMENTS.ISSUING_DATE")}
            documentTypeLabel={t("DOCUMENTS.DOCUMENT_TYPE")}
            {...verifyResidenceProps}
          />
        </div>
      )}
      <Footer>
        <SkipButton kind="secondary" onClick={() => history.push("/manage-money/deposit")}>
          {t("KYC.SKIP")}
        </SkipButton>
        <Button
          onClick={submitDocuments}
          disabled={ctaDisabled}
          isLoading={documentUploadIsLoading}
        >
          {t("KYC.VERIFY_ACCOUNT")}
        </Button>
      </Footer>
    </Grid>
  );
};

export default Verification;
