import en from "./en.json";
import zn from "./zn.json";

const resources = {
  en: {
    translation: en,
  },
  'zh-Hans': {
    translation: zn,
  },
};

export default resources;
