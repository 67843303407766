import constants from "../constants";

const mapPaymentOptions = (paymentGates) => {
  const options = Object.values(constants.PAYMENT_OPTIONS);
  const zotapay =
    paymentGates.data &&
    paymentGates.data.find((item) => item.provider === constants.PAYMENT_PROVIDERS.ZOTAPAY);
  const nacepay =
    paymentGates.data &&
    paymentGates.data.find((item) => item.provider === constants.PAYMENT_PROVIDERS.NACEPAY);
  const nucleus =
    paymentGates.data &&
    paymentGates.data.find((item) => item.provider === constants.PAYMENT_PROVIDERS.NUCLEUS);
  if (zotapay) {
    const zotaPayConfig = {
      ...constants.ZOTAPAY,
      endpoint_id: zotapay.endpoint_id,
      currency: zotapay.currency,
    };
    options.push(zotaPayConfig);
  }

  if (nacepay) {
    const zotaPayConfig = {
      ...constants.NACEPAY,
      endpoint_id: nacepay.endpoint_id,
      currency: nacepay.currency,
    };
    options.push(zotaPayConfig);
  }

  if (nucleus) {
    const nucleusConfig = {
      ...constants.NUCLEUS,
      endpoint_id: nucleus.endpoint_id,
      currency: nucleus.currency,
    };
    options.push(nucleusConfig);
  }

  return options;
};

const moneyHelper = {
  mapPaymentOptions,
};

export default moneyHelper;
