import { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import styled from "styled-components";
import { Wizard, Step } from "@my-swipestox/kyc";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, HeadingXSmall, ParagraphSmall, Spinner } from "@my-swipestox/components";
import { CheckCircle } from "react-feather";

import api from "../../api";
import kycUtils from "./utils";
import kycConstants from "./constants";

import state from "../../state";
import constants from "../../constants";
import KycSuccessModal from "./KycSuccessModal";
import KycHeader from "./KycHeader";

const KycContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  background: ${(props) => props.theme.color.kycBackground};
`;

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  background: ${(props) => props.theme.color.kycBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 1rem;
  height: 100%;
`;

const StyledStep = styled(Step)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 48px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const SubmitButton = styled(Button)`
  width: 390px;
  padding: 14px 18px;
  border-radius: 4px;
  font-weight: 400;
  display: flex;
  font-size: 16px;
  border: none;
  align-items: center;
  margin-top: 1rem;
  color: white !important;
  background-color: #3179ff !important;
  opacity: ${(props)=>props.disabled ? '.4' : '1'} !important;
`;

const UpgradedAccount = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const UpgradedCheckIcon = styled(CheckCircle)`
  width: 100px;
  height: 100px;
  margin: 50px;
  color: ${(props) => props.theme.color.primary};
`;

const Heading = styled(HeadingXSmall)`
  font-weight: 500;
`;

const Subtitle = styled(ParagraphSmall)`
  color: #7f93bc;
  margin-top: 1rem;
  font-weight: 300;
`;

const ImageInSelect = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
  span {
    margin-left: 8px;
  }
`;

const ArrowLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 2.1rem;
  cursor: pointer;
  @media (max-width: 768px) {
    left: 16px;
    top: 80px;
  }
`;

const getTemplateAreasNonEu = (step) => {
  const map = {
    1: `"country_expected_source_of_funds"`,
    2: `"first_name" "last_name" "date_of_birth"`,
    3: `"address" "addr_street" "addr_city" "addr_street_no" "addr_zip"`,
    4: `"pep" "pep_info" "citizen"`,
    5: `"employment_status"`,
    6: `"employment_type"`,
    7: `"annual_income"`,
    8: `"net_worth"`,
    9: `"source_of_income"`,
    10: `"trading_experience_years"`,
    11: `"investment_objectives_naga"`,
    12: `"currency"`,
    13: `"terms"`,
  };
  return map[step];
};

const initialValuesNonEu = {
  country_expected_source_of_funds: { value: '', label: '' },
  addr_country: { value: '', label: '' },
  first_name: '',
  last_name: '',
  date_of_birth: '',
  addr_city: '',
  addr_street_no: '',
  addr_street: '',
  addr_zip: '',
  address: '',
  pep_info: '',
  pep: '',
  citizen: '',
  currency: '',
  employment_status: '',
  employment_type: '',
  trading_experience_years: '',
  investment_objectives_naga: '',
  annual_income: '',
  net_worth: '',
  source_of_income: '',
  terms: '',
};
const getValidationSchemaNonEu = ({ step, t, country }) => {
  switch (step) {
    case 0:
      return Yup.object().shape({});
    case 1:
      return Yup.object().shape({
        first_name: Yup.string()
          .required(t('REGISTRATION.FIRST_NAME_IS_REQUIRED'))
          .max(kycConstants.FIRST_LAST_NAME_MAX_LENGTH, t('REGISTRATION.MAXIMUM_CHARACTERS_REQUIREMENT', { num: kycConstants.FIRST_LAST_NAME_MAX_LENGTH }))
          .test('firstNameValidation', t('REGISTRATION.ONLY_ENGLISH_LETTERS'), value => {
            return value && kycConstants.FIRST_LAST_NAME_CRITERIA.test(value);
          })
          .min(kycConstants.FIRST_LAST_NAME_MIN_LENGTH, t('REGISTRATION.MINIMUM_CHARACTERS_REQUIREMENT', { num: kycConstants.FIRST_LAST_NAME_MIN_LENGTH }))
          .nullable(),
        last_name: Yup.string()
          .required(t('REGISTRATION.LAST_NAME_IS_REQUIRED'))
          .max(kycConstants.FIRST_LAST_NAME_MAX_LENGTH, t('REGISTRATION.MAXIMUM_CHARACTERS_REQUIREMENT', { num: kycConstants.FIRST_LAST_NAME_MAX_LENGTH }))
          .test('lastnameValidation', t('REGISTRATION.ONLY_ENGLISH_LETTERS'), value => {
            return value && kycConstants.FIRST_LAST_NAME_CRITERIA.test(value);
          })
          .min(kycConstants.FIRST_LAST_NAME_MIN_LENGTH, t('REGISTRATION.MINIMUM_CHARACTERS_REQUIREMENT', { num: kycConstants.FIRST_LAST_NAME_MIN_LENGTH }))
          .nullable(),
        date_of_birth: Yup.string()
          .required(t('KYC.FIELD_IS_REQUIRED'))
          .test('Check if date is valid', t('REGISTRATION.INVALID_DATE'), item => {
            const isValid = moment(item, 'YYYY-MM-DD', true).isValid();
            return isValid;
          })
          .test('Check person age', t('REGISTRATION.18_YEARS_OLD'), item => {
            return moment().diff(moment(item, 'YYYY-MM-DD'), 'years') >= 18;
          })
          .test('Check person age', t('REGISTRATION.UNDER_100_YEARS_OLD'), item => {
            return moment().diff(moment(item, 'YYYY-MM-DD'), 'years') <= 100;
          }),
      });
    case 2:
      return Yup.object().shape({
        addr_city: Yup.string()
          .required(t('KYC.FIELD_IS_REQUIRED'))
          .matches(kycConstants.ENGLISH_LETTERS_ONLY_AND_SPACES_REGEX, t('KYC.ENGLISH_LETTERS_ONLY_AND_SPACES_REGEX')),
        addr_street: Yup.string()
          .required(t('KYC.FIELD_IS_REQUIRED'))
          .max(kycConstants.MAX_LENGTH_STREET_ADDRESS, t('REGISTRATION.MAXIMUM_CHARACTERS_REQUIREMENT', { num: kycConstants.MAX_LENGTH_STREET_ADDRESS }))
          .matches(kycConstants.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX, t('KYC.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX')),
        addr_street_no: Yup.string()
          .required(t('KYC.FIELD_IS_REQUIRED'))
          .matches(kycConstants.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX, t('KYC.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX')),
        addr_zip: Yup.string()
          .required(t('KYC.FIELD_IS_REQUIRED'))
          .test({
            name: 'test zip code',
            test: function (value) {
              return kycUtils.validateZipCode({ value, country, t }, this);
            },
          }),
      });
    case 3:
      return Yup.object().shape({
        citizen: Yup.string()
          .required('Field is required')
          .test('is-us', t('REGISTRATION.US_CLIENTS_NOT_ALLOWED_TO_REGISTER'), value => value === '0'),
        pep: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
        pep_info: Yup.string().when('pep', {
          is: val => val === '1',
          then: Yup.string()
            .required(t('KYC.FIELD_IS_REQUIRED'))
            .test('pepInfoValidation', t('REGISTRATION.ONLY_ENGLISH_LETTERS'), value => {
              return value && kycConstants.LATIN_CHARACTERS_ONLY_REGEX.test(value);
            }),
        }),
      });
    case 4:
      return Yup.object().shape({
        employment_status: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 5:
      return Yup.object().shape({
        employment_type: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 6:
      return Yup.object().shape({
        annual_income: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 7:
      return Yup.object().shape({
        net_worth: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 8:
      return Yup.object().shape({
        source_of_income: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 9:
      return Yup.object().shape({
        trading_experience_years: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 10:
      return Yup.object().shape({
        investment_objectives_naga: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 11:
      return Yup.object().shape({
        currency: Yup.string().required(t('KYC.FIELD_IS_REQUIRED')),
      });
    case 12:
      return Yup.object().shape({});
    default:
      return null;
  }
};
const brokerId = constants.IS_PROD_ENV ? 24 : 14;
const hiddenFields = ["telephone"];
const country = { code: 'CN', label: 'China' };

const Kyc = () => {
  const kycFormQuery = useQuery("kycForm", () => api.queries.kycForm(brokerId, country.code));
  const kycScoresQuery = useQuery("kycScores", () => api.queries.kycScores());
  const countriesQuery = useQuery("countries", () => api.queries.countries());
  const userInfoQuery = useQuery(constants.QUERY_NAMES.userInfo, () => api.queries.userInfo(), {
    enabled: false,
  });
  const tradingAccountsQuery = useQuery(
    constants.QUERY_NAMES.tradingAccounts,
    () => api.queries.getTradingAccounts(),
    {
      enabled: false,
    }
  );
  const saveKycMutation = useMutation(api.mutations.saveKyc);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const kycForm = kycFormQuery.data?.data?.data || null;
  const kyc = kycUtils.parseKyc(kycForm);
  const isUserUpgraded = kycScoresQuery.data?.data?.data?.last_kyc_date;
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const [stepNumber, setStepNumber] = useState(0);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesNonEu);
  const upgradeUserMutation = useMutation(api.mutations.upgradeUser, {
    onSuccess: () => {
      setSuccessModalIsOpen(true);
      userInfoQuery.refetch({ enabled: true });
      tradingAccountsQuery.refetch({ enabled: true });
      kycScoresQuery.refetch({ enabled: true });
    },
  });

  const steps = kyc?.kyc_form?.screens?.map(item => {
    const title = t(item.name_translation_key);

    return {
      title,
      description: t(item.description_translation_key),
      img_url: item.icon_url,
      ...item
    };
  });

  useEffect(() => {
    if (steps) {
      kycFormQuery.refetch({ enabled: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserInfo(userInfoQuery.data?.data?.data || {});
  }, [userInfoQuery.data, setUserInfo]);

  useEffect(() => {
    const userProfile = userInfoQuery.data?.data?.data;
    if (steps?.length) {
      setStepNumber(getStepNumber());
    }
    if (steps?.length && userProfile && countriesQuery.data) {
      const firstName = userProfile.first_name;
      const lastName = userProfile.last_name;
      const values = getSavedKycValues();
      const countries = countriesQuery.data.data.data;
      const countryCode = values.country_expected_source_of_funds;
      const label = getCountryName(countries.find(item => item.code === (countryCode || country.code)));
      const sourceOfFunds = countries.find(item => item.code === countryCode);

      setTimeout(() => {
        setInitialValues({
          ...initialValues,
          ...values,
          first_name: firstName,
          last_name: lastName,
          addr_country: { value: country.code, label },
          country_expected_source_of_funds: { value: sourceOfFunds ? sourceOfFunds.code : country.code, label },
        });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycFormQuery.data, userInfoQuery.data, countriesQuery.data]);

  const getSavedKycValues = () => {
    let values = {};

    steps?.map(screen => {
      screen?.fields?.map(field => {
        if (field.value) {
          values = { ...values, [field.id]: field.value };
        }
        return field;
      });
      return screen;
    });

    return values;
  };

  const getStepNumber = () => {
    const values = getSavedKycValues();

    const screen = steps?.find(screen => {
      return screen?.fields?.find(field => {
        if (!field?.value && field?.required !== 'false') {
          if (field?.required === 'rule') {
            return kycUtils.handleIsRequiredRules(field, values);
          }
          return true;
        }
        return false;
      });
    });

    return screen?.order - 1 || 0;
  };

  const shouldSkipEmploymentTypeStep = values => {
    if (values.employment_status === `retired` || values.employment_status === `unemployed`) {
      return true;
    }
    return false;
  };

  const isSingleChoice = () => {
    if (!steps?.[stepNumber]?.fields?.length) {
      return false;
    }
    if (steps[stepNumber].fields.length === 1 && steps[stepNumber].fields[0].type === 'buttonGroup') {
      return true;
    }
    return false;
  };

  const successModalOnVerify = () => {
    setSuccessModalIsOpen(false);
    history.push("/verification");
  };

  const successModalOnFundAccount = () => {
    setSuccessModalIsOpen(false);
    history.push("/manage-money/deposit");
  }

  const saveKycValues = async (values) => {
    const answers = kycUtils.getAnswers(values);
    const payload = {
      p_answers: answers,
      p_broker_id: brokerId,
      p_screen_num: stepNumber,
      questionnaire_type: `upgrade`
    };

    await saveKycMutation.mutateAsync(payload);
  };

  const onNext = async ({ formik }) => {
    await saveKycValues(formik.values);
    formik.setSubmitting(false);
    const area = getTemplateAreasNonEu(stepNumber + 1);
    if (area === `"employment_status"` && shouldSkipEmploymentTypeStep(formik.values)) {
      setStepNumber(stepNumber + 2);
    } else {
      setStepNumber(stepNumber + 1);
    }
  };

  const onPrevious = ({ formik }) => {
    const area = getTemplateAreasNonEu(stepNumber - 1);
    if (area === `"employment_status"` && shouldSkipEmploymentTypeStep(formik.values)) {
      setStepNumber(stepNumber - 2);
    } else {
      setStepNumber(stepNumber - 1);
    }
  };

  const onSubmit = async (formik) => {
    await saveKycValues(formik.values);
    upgradeUserMutation.mutate();
  };

  const renderFooter = ({ isLastStep, formik, previous, isSubmitting }) => {
    const handlePrevious = () => {
      if (isSubmitting) {
        return;
      }
      if (stepNumber === 0) {
        return history.push('/live-account');
      }
      previous();
    };

    return (
      <>
        {stepNumber >= 0 && (
          <ArrowLeft onClick={handlePrevious}>
            <img src={`${constants.IMAGES_URL}/ico-android-arrowback.svg`} alt="back" />
          </ArrowLeft>
        )}
        {isSingleChoice() ? null : (
          <SubmitButton type="submit" disabled={!formik.isValid || upgradeUserMutation.isLoading}>
            {isLastStep ? t("KYC.SUBMIT") : t("KYC.CONTINUE")}
          </SubmitButton>
        )}
      </>
    );
  };

  const handleOptionLabel = (field) => {
    if (field.translation_key) {
      if (field.translation_key === "REGISTRATION.NON_EU_TERMS_AND_CONDITIONS") {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: t("REGISTRATION.NON_EU_TERMS_AND_CONDITIONS", {
                link: `<a href=${
                  constants.NAGA_LEGAL_DOCUMENTATION_URL
                } target="_blank" rel="noopener noreferrer">${t(
                  "REGISTRATION.TERMS_AND_CONDITIONS"
                )}</a>`,
              }),
            }}
          />
        );
      }
      return t(field.translation_key);
    }
    return field.label;
  };
  const getCountryName = country => {
    return `${country.name}${country.native_name ? ` (${country.native_name})` : ''}`;
  };
  const getNationalityLabel = country => kycConstants.NATIONALITIES.find(item => item.value === country.code)?.label || getCountryName(country);
  const getLabel = (label, icon) => (
    <ImageInSelect>
      {icon && <img src={icon} alt="" />}
      {label && <span>{label}</span>}
    </ImageInSelect>
  );
  const countryOptions = useMemo(() => {
    return (isNational = false) =>
      countriesQuery.data?.data?.data?.filter(item => constants.SUPPORTED_COUNTRY_CODES.includes(item.code)).map(item => {
        const countryValue = isNational ? getNationalityLabel(item) : getCountryName(item);
        return {
          value: item.code,
          label: getLabel(countryValue, item.icon),
          icon: item.icon,
          phoneCode: item.phone_code,
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesQuery.data]);
  const options = {
    country_of_birth: countryOptions(),
    country_expected_source_of_funds: countryOptions(),
    country_source_funds: countryOptions(),
    date_of_birth: Array.from({ length: 12 }, (_, i) => i).map(value => ({
      label: moment()
        .locale(i18n.language)
        .month(value)
        .format('MMMM'),
      value: moment()
        .month(value)
        .format('MM'),
    })),
  };

  const handleLabel = field => {
    if (field.type === 'checkbox') {
      return null;
    }
    return t(field.translation_key);
  };

  const labelsAdditional = {
    addr_street: t('KYC.STREET'),
    addr_city: t('KYC.CITY'),
    addr_street_no: t('REGISTRATION.STREET_NAME_NO'),
    addr_zip: t('KYC.POSTAL_NUM'),
    first_name: t('KYC.FIRST_NAME'),
    middle_name: t('KYC.MIDDLE_NAME_2'),
    last_name: t('KYC.LAST_NAME'),
    terms: t('KYC.TAC_DESCRIPTION'),
    country_expected_source_of_funds: t('REGISTRATION.COUNTRY'),
    telephone: t('REGISTRATION.PHONE_NUMBER'),
    country_phone_code: t('REGISTRATION.COUNTRY_CODE'),
    date_of_birth: {
      day: t('REGISTRATION.DAY'),
      month: t('REGISTRATION.MONTH'),
      year: t('REGISTRATION.YEAR'),
    },
    national_id: t('KYC.PASSPORT_ID'),
  };

  const renderWizard = () => {
    if (kycFormQuery.isFetching) return <SpinnerContainer><Spinner/></SpinnerContainer>;
    // User upgraded and he's trying to upgrade again

    if (successModalIsOpen && kycScoresQuery.data?.data?.data?.trading_experience) {
      return <KycSuccessModal tradingExperience={kycScoresQuery.data?.data?.data?.trading_experience} onVerify={successModalOnVerify} onFundAccount={successModalOnFundAccount} />
    }

    if (isUserUpgraded) {
      return (
        <UpgradedAccount>
          <UpgradedCheckIcon />
          <Heading>{t("KYC.ALREADY_UPGRADED_TITLE")}</Heading>
          <Subtitle>{t("KYC.ALREADY_UPGRADED_SUBTITLE")}</Subtitle>
        </UpgradedAccount>
      );
    }

    return (
      <KycContainer>
        <KycHeader
          numberOfSteps={steps && steps.length}
          title={steps && steps.length ? steps[stepNumber].title : ''}
          currentStep={stepNumber + 1}
        />
        <Wizard
          initialValues={initialValues}
          darkMode={isDarkMode}
          footer={renderFooter}
          header={null}
          onSubmit={onSubmit}
          onNext={onNext}
          onPrevious={onPrevious}
          handleOptionLabel={handleOptionLabel}
          handleLabel={handleLabel}
          options={options}
          stepNumber={stepNumber}
          tooltipTitle={t("KYC_TOOLTIP_TITLE")}
          hiddenFields={hiddenFields}
          labelsAdditional={labelsAdditional}
          isSingleChoice={isSingleChoice()}
        >
          {steps?.map((item, index) => {
            const fieldsWithTooltips = item.fields.map((item) => {
              return {
                ...item,
                info: kycUtils.getFieldTooltip({
                  id: item.id,
                  t,
                }),
              };
            });
            const countryOption = countriesQuery.data?.data?.data?.find(item => item.code === country.code);

            return (
              <StyledStep
                key={item.name}
                fields={fieldsWithTooltips}
                validationSchema={() => getValidationSchemaNonEu({ step: index, t, country: countryOption })}
                templateAreas={getTemplateAreasNonEu(index)}
                isSingleChoice={isSingleChoice()}
              />
            );
          })}
        </Wizard>
      </KycContainer>
    );
  };

  return (
    <Wrapper>
      {renderWizard()}
    </Wrapper>
  );
};

export default Kyc;
