import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "@my-swipestox/components";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const ErrorTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const ErrorButton = styled(Button)`
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const DocumentsErrorFetching = (props) => {
  const { refetch = () => {} } = props;
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <ErrorTitle>{t("DOCUMENTS.ERROR_WHILE_FETCHING_DOCUMENTS")}</ErrorTitle>
      <ErrorButton onClick={refetch}>{t("DOCUMENTS.TRY_AGAIN")}</ErrorButton>
    </ErrorContainer>
  );
};

export default DocumentsErrorFetching;
