import {
    ParagraphSmall,
    Modal,
    ModalHeader,
    ModalBody,
    ModalButton,
  } from "@my-swipestox/components";
  import styled from "styled-components";
  import { useTranslation } from "react-i18next";
  import { useIntercom } from "react-use-intercom";
  import { useHistory } from "react-router";
  
  const ModalHeaderContent = styled.div`
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-top: 8px;
  `;

  const StyledModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    background: none;
    border-color: transparent;
    padding-bottom: 24px;
    padding-right: 20px;
    padding-left: 20px;
  `;

  const ForgotPasswordButton = styled(ModalButton)`
    padding-top: 16px;
    padding-bottom: 16px;
    margin-right: 0 !important;
    font-size: 16px;
  `;

  const ContactSupportButton = styled(ModalButton)`
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    color: ${(props) => props.theme.color.headerFill};
    background-color: ${(props) => props.theme.color.kycCancelButtonBackground};
    border: none;
  `;
  
  const RegistrationDisabledModal = (props) => {
    const { isOpen = false, onClose, loginAttemptsLeft, loginAttemptsBlockedFor } = props;
    const { t } = useTranslation();
    const { boot, show } = useIntercom();
    const history = useHistory();

    const handleForgotPassword = () => {
        history.push('/reset-password');
        onClose();
      };
    
      const handleOpenSupport = () => {
        boot();
        show();
        onClose();
      };
    
      const getMessage = () => {
        const isFirstBlockedMessage = loginAttemptsBlockedFor === 7200; // 2h
    
        if (loginAttemptsLeft === 1) {
          return t('LOGIN.LOGIN_NOT_SUCCESSFULL_LAST_TIME');
        } else if (isFirstBlockedMessage) {
          return t('LOGIN.ACCOUNT_LOCKED_FOR', { time: `2 ${t('HOURS')}` });
        } else {
          let time;
          const minute = Math.floor(loginAttemptsBlockedFor / 60);
          const minuteString = minute % 10 === 1 ? t('MINUTE') : t('MINUTES');
          if (loginAttemptsBlockedFor === 3600) {
            time = `1 ${t('HOUR')}`;
          } else if (loginAttemptsBlockedFor < 3600) {
            time = `${minute} ${minuteString}`;
          } else {
            time = `1 ${t('HOUR')} and ${minute - 60} ${minuteString}`;
          }
          return t('LOGIN.ACCOUNT_LOCKED_FOR', { time });
        }
      };

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>
          <ModalHeaderContent>
            {t(loginAttemptsLeft === 1 ? 'LOGIN.WARNING' : 'LOGIN.ACCOUNT_LOCKED')}
          </ModalHeaderContent>
        </ModalHeader>
        <ModalBody>
          <ParagraphSmall mb="1rem">
            {getMessage()}
          </ParagraphSmall>
        </ModalBody>
        <StyledModalFooter>
          <ForgotPasswordButton onClick={handleForgotPassword}>{t("LOGIN.FORGOT_PASSWORD")}</ForgotPasswordButton>
          <ContactSupportButton onClick={handleOpenSupport}>{t("LOGIN.CONTACT_SUPPORT")}</ContactSupportButton>
        </StyledModalFooter>
      </Modal>
    );
  };
  
  export default RegistrationDisabledModal;
  