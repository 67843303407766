import moment from "moment";

import constants from "../constants";

export const getTranslation = (text, list) => {
    if (text) {
        const result = list.find(({ value }) => value && value.toLowerCase() === text.toLowerCase());
        if (result) {
            return [result.key, {}];
        }

        // Check for payment methods with variable values included, eg. Transfer from 12345, Sent to example@email.com
        const resultWithRegex = list.find(({ regex }) => regex && regex.test(text.toLowerCase()));

        if (resultWithRegex) {
            const regexResult = resultWithRegex.regex.exec(text.toLowerCase());

            return [resultWithRegex.key, { value: regexResult[1] }];
        }
        return [text, {}];
    }
    return ['', {}];
};

export const compareDates = (dateTime, type) => {
    let date_to = "";
    const date_from = moment(dateTime);
    if (type === constants.DATE_PICKER_OPTIONS.ALL) {
        return true;
    } else if (type === constants.DATE_PICKER_OPTIONS.LAST_SEVEN) {
        date_to = moment().subtract(7, "days");
    } else if (type === constants.DATE_PICKER_OPTIONS.LAST_THIRTY) {
        date_to = moment().subtract(30, "days");
    } else if (type === constants.DATE_PICKER_OPTIONS.LAST_YEAR) {
        date_to = moment().subtract(1, "years");
    }

    return date_from.isAfter(date_to);
};
