import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, ParagraphMedium } from "@my-swipestox/components";

import constants from "../../constants";
import AppStoreBadge from "../../icons/AppStore";

const Wrapper = styled.div`
  padding: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
  button {
    width: 100%;
  }
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    flex-direction: row;
    button {
      width: auto;
    }
  }
  svg {
    max-height: 44px;
    max-width: 174px;
    margin-bottom: 1rem;
    @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

const Image = styled.img`
  max-height: 44px;
  max-width: 174px;
  margin-bottom: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

const MetaTrader = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ParagraphMedium mb="1rem">{t("METATRADER.DOWNLOAD_MT4")}</ParagraphMedium>
      <Grid>
        <GridItem>
          <Image src={`${constants.IMAGES_URL}/mt-logo.svg`} />
          <Button onClick={() => window.open(constants.MT4_DOWNLOAD_LINKS.ANDROID, "_blank")}>
            {t("METATRADER.DOWNLOAD_FOR_ANDROID")}
          </Button>
        </GridItem>
        <GridItem>
          <AppStoreBadge />
          <Button onClick={() => window.open(constants.MT4_DOWNLOAD_LINKS.IOS, "_blank")}>
            {t("METATRADER.DOWNLOAD_FOR_IOS")}
          </Button>
        </GridItem>
        <GridItem>
          <Image src={`${constants.IMAGES_URL}/mt-logo.svg`} />
          <Button onClick={() => window.open(constants.MT4_DOWNLOAD_LINKS.MAC, "_self")}>
            {t("METATRADER.DOWNLOAD_FOR_MAC")}
          </Button>
        </GridItem>
        <GridItem>
          <Image src={`${constants.IMAGES_URL}/mt-logo.svg`} />
          <Button onClick={() => window.open(constants.MT4_DOWNLOAD_LINKS.WINDOWS_NON_EU, "_self")}>
            {t("METATRADER.DOWNLOAD_FOR_WINDOWS")}
          </Button>
        </GridItem>
      </Grid>
      <ParagraphMedium mb="1rem" mt="1rem">
        {t("METATRADER.DOWNLOAD_MT5")}
      </ParagraphMedium>
      <Grid>
        <GridItem>
          <Image src={`${constants.IMAGES_URL}/mt5-logo.png`} />
          <Button onClick={() => window.open(constants.MT5_DOWNLOAD_LINKS.WINDOWS, "_self")}>
            {t("METATRADER.DOWNLOAD_FOR_WINDOWS")}
          </Button>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

export default MetaTrader;
