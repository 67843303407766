import styled from "styled-components";

import constants from "../../constants";

const Wrapper = styled.div``;

const StyledHeader = styled.header`
  color: #fff;
  background-color: ${(props) => props.theme.color.headerFill};
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 auto;
  max-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES};
  @media (min-width: ${constants.BREAKPOINTS.EXTRA_LARGE_DEVICES}) {
    padding: 1rem 0;
  }
`;

const Logo = styled.img`
  height: 32px;
`;

const UnauthorizedHeader = () => {
  return (
    <Wrapper>
      <StyledHeader>
        <HeaderContent>
          <Logo src={`${constants.IMAGES_URL}/naga-logo-white.svg`} alt="" />
        </HeaderContent>
      </StyledHeader>
    </Wrapper>
  );
};

export default UnauthorizedHeader;
