import React from "react";
import { components } from "react-select";
import styled from "styled-components";

import Amount from "../../components/amount/Amount";
import constants from "../../constants";

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SymbolImage = styled.img`
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
`;

const AccountDtails = styled.div`
  width: calc(100% - 40px);
  line-height: 1.2;
`;

const AccountType = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.color.contentSecondary};
`;

const AccountOption = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <OptionContainer>
        <SymbolImage
          src={`${constants.WALLET_S3_CONTENT}/${data.currency.toLowerCase()}.png`}
          alt={data.currency}
        />
        <AccountDtails>
          {data.label}
          <div>
            <AccountType type={data.type}>#{data.accountNumber}</AccountType>
            <Amount amount={data.balance} symbol={data.currencySymbol} decimals={data.digits} />
          </div>
        </AccountDtails>
      </OptionContainer>
    </components.Option>
  );
};

const SingleAccountOption = (props) => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <OptionContainer>
        <SymbolImage
          src={`${constants.WALLET_S3_CONTENT}/${data.currency.toLowerCase()}.png`}
          alt={data.currency}
        />
        <AccountDtails>{data.label}</AccountDtails>
      </OptionContainer>
    </components.SingleValue>
  );
};

const compOptions = { AccountOption, SingleAccountOption };

export default compOptions;
