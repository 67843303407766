import { useEffect } from "react";

import constants from "../../constants";
import api from "../../api";

const Geetest = ({ captcha, setCaptcha, onSuccess }) => {
  const geetestLanguageMapping = {
    'zh-hans': 'zho',
    'zh-hant': 'zho-tw',
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    let userInfoResponse;
    try {
      userInfoResponse = await api.queries.userInfo();
    } catch (err) {}
    const info = userInfoResponse?.data?.data || {};
    window.initGeetest4(
      {
        captchaId: constants.GEETEST_ID,
        language: geetestLanguageMapping[info.app_language] || 'eng',
        product: 'bind',
      },
      (captchaObj) => {
        captchaObj.onReady(() => {
          setCaptcha(captchaObj);
        });
        captchaObj.onSuccess(() => {
          onSuccess(captchaObj.getValidate());
          captchaObj.reset();
        });
      }
    );

    return () => {
      if (captcha) {
        captcha.destroy();
        setCaptcha(null);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Geetest;
