import utils from "../../utils";
import kycConstants from "./constants";
import constants from "../../constants";
import isEmpty from 'lodash/isEmpty';

const parseKyc = (kyc) => {
  if (!kyc) {
    return null;
  }
  return {
    ...kyc,
    kyc_form: {
      ...kyc.kyc_form,
      screens: kyc.kyc_form.screens.map((item) => {
        return {
          ...item,
          fields: item.fields.map((field) => {
            const options = field.field_options.map((option) => {
              return {
                ...option,
                label: option.text,
              };
            });

            if (field.id === "terms") {
              return {
                ...field,
                options,
                name: field.id,
                label: null,
              };
            }

            if (field.id === "date_of_birth") {
              return {
                ...field,
                options,
                name: field.id,
                type: "birthday",
              };
            }

            return {
              ...field,
              options,
              name: field.id,
              type: field.type === "radio" ? "buttonGroup" : field.type,
            };
          }),
        };
      }),
    },
  };
};

const getAnswers = (values) => {
  const answers = Object.fromEntries(
    Object.entries(values).map((item) => {
      if (utils.isPlainObject(item[1])) {
        return [item[0], item[1].value];
      }
      if (Array.isArray(item[1]) && item[1].length) {
        return [item[0], item[1][0].value];
      }
      return item;
    })
  );

  return answers;
};

const getFieldTooltip = ({ id, t }) => {
  switch (id) {
    case 'country_expected_source_of_funds':
      return t('KYC.TOOLTIP.COUNTRY_EXPECTED_SOURCE_OF_FUNDS');
    case 'telephone':
      return t('KYC_TOOLTIP.TELEPHONE');
    case 'pep':
      return t('KYC_TOOLTIP.PEP');
    case 'date_of_birth':
      return t('KYC_TOOLTIP.DATE_OF_BIRTH');
    case 'citizen':
      return t('KYC_TOOLTIP.CITIZEN');
    case 'employment_status':
      return t('KYC_TOOLTIP.EMPLOYMENT_STATUS');
    case 'annual_income':
      return t('KYC_TOOLTIP.ANNUAL_INCOME');
    case 'source_of_income':
      return t('KYC_TOOLTIP.SOURCE_OF_INCOME');
    case 'net_worth':
      return t('KYC_TOOLTIP.NET_WORTH');
    case 'anticipated_amount':
      return t('KYC_TOOLTIP.ANTICIPATED_AMOUNT');
    case 'currency':
      return t('KYC_TOOLTIP.CURRENCY');
    default:
      return null;
  }
};

const validateZipCode = (data, context) => {
  const { value, country, t } = data;
  let zipRegex = kycConstants.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX;
  let message = t('KYC.ENGLISH_LETTERS_ONLY_AND_SPACES_DASHES_DIGITS_REGEX');
  if (country?.zip_regex) {
    zipRegex = new RegExp(country.zip_regex);
    message = t('KYC.ZIP_CODE_ERROR', { value, country: country.name });
  }
  const isValid = zipRegex.test(value);

  return isValid
    ? true
    : context.createError({
        message,
        path: 'addr_zip',
      });
};

const handleIsRequiredRules = (item, values) => {
  const OPERATIONS = {
    equal: 'eq',
    notEqual: 'neq',
    isOneOf: 'iof',
    isNotOneOf: 'inof',
  };

  if (item.rules && !isEmpty(item.rules.required)) {
    const operandA = item.rules.required.operandA;
    const valueA = (values[operandA] && values[operandA].value) || values[operandA];
    const valueB = item.rules.required.operandB;
    const operation = item.rules.required.operation;
    switch (operation) {
      case OPERATIONS.equal:
        return valueA === valueB;
      case OPERATIONS.notEqual:
        return valueA !== valueB;
      case OPERATIONS.isOneOf:
        return valueB ? valueB.split(',').indexOf(valueA) > -1 : true;
      case OPERATIONS.isNotOneOf:
        return valueB ? valueB.split(',').indexOf(valueA) === -1 : true;
      default:
        return false;
    }
  }

  return false;
};

const TRADING_CATEGORIES = {
  intermediate: 'INTERMEDIATE',
  preAdvanced: 'PRE_ADVANCED',
  advanced: 'ADVANCED',
};

const successSpecification = {
  [TRADING_CATEGORIES.intermediate]: {
    image: `${constants.IMAGES_URL}/kyc-intermediate-acc.svg`,
    title: {
      key: 'KYC.ACCOUNT_CATEGORIZATION_INTERMEDIATE',
    },
    description: {
      key: 'KYC.ADVANCED_ACC_DESRIPTION',
      style: 'descriptionAdvanced',
    },
    fundDisclaimer: {
      key: 'KYC.FUND_UP_TO_2000',
    },
  },
  [TRADING_CATEGORIES.preAdvanced]: {
    image: `${constants.IMAGES_URL}/kyc-preadvanced-acc.svg`,
    title: {
      key: 'KYC.ACCOUNT_CATEGORIZATION_PRE_ADVANCED',
    },
    description: {
      key: 'KYC.ADVANCED_ACC_DESRIPTION',
      style: 'descriptionAdvanced',
    },
    fundDisclaimer: {
      key: 'KYC.FUND_UP_TO_2000',
    },
  },
  [TRADING_CATEGORIES.advanced]: {
    image: `${constants.IMAGES_URL}/kyc-advanced-acc.svg`,
    title: {
      key: 'KYC.ACCOUNT_CATEGORIZATION_ADVANCED',
    },
    description: {
      key: 'KYC.ADVANCED_ACC_DESRIPTION',
      style: 'descriptionAdvanced',
    },
    fundDisclaimer: {
      key: 'KYC.FUND_UP_TO_2000',
    },
  },
}

const kycUtils = {
  parseKyc,
  getAnswers,
  getFieldTooltip,
  validateZipCode,
  handleIsRequiredRules,
  successSpecification,
};

export default kycUtils;
