import "react-dates/initialize";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import SingleDatePicker from "react-dates/lib/components/SingleDatePicker";
import isInclusivelyBeforeDay from "react-dates/lib/utils/isInclusivelyBeforeDay";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import { Info } from "react-feather";

import "react-dates/lib/css/_datepicker.css";

const DatePickerWrapper = styled.div`
.SingleDatePicker {
  .CalendarMonthGrid,
  .CalendarMonth,
  .CalendarDay,
  .DayPickerNavigation_button {
    background-color: ${(props) => props.theme.color.calendarFill};
    outline: none;
    &_caption {
      color: ${(props) => props.theme.color.calendarCaption};
    }
    &__default {
      border: 1px solid ${(props) => props.theme.color.border};
    }
  }
  .DayPicker {
    background-color: ${(props) => props.theme.color.calendarNavigationFill};
    &_focusRegion {
      background-color: ${(props) => props.theme.color.calendarNavigationFill};
    }
    &_transitionContainer__horizontal {
      background-color: ${(props) => props.theme.color.calendarNavigationFill};
    }
    &_weekHeader {
      color: ${(props) => props.theme.color.calendarCaption};
    }
    &__withBorder {
      border-radius: 0px;
      box-shadow: none;
    }
  }
  .CalendarDay {
    border: 1px solid ${(props) => props.theme.color.border};
    &__default {
      color: ${(props) => props.theme.color.calendarCaption};
      &:hover {
        background: ${(props) => props.theme.color.calendarDayBackground};
        border: 1px solid ${(props) => props.theme.color.calendarDayBackground};
        color: ${(props) => props.theme.color.calendarDayHover};
      }
    }
    &__selected,
    &__selected:hover {
      background: ${(props) => props.theme.color.primary};
      color: #ffffff;
    }
    &__blocked_out_of_range,
    &__blocked_out_of_range:active,
    &__blocked_out_of_range:hover {
      color: ${(props) => props.theme.color.calendarDisabledDay};
      background-color: ${(props) => props.theme.color.calendarFill};
    }
  }
  .SingleDatePickerInput {
    &__withBorder {
      border-radius: 3px;
      background-color: ${(props) =>
        props.isRequired ? props.theme.color.inputFillError : props.theme.color.inputFill};;
      border: 1px solid ${(props) =>
        props.isRequired ? props.theme.color.inputBorderError : props.theme.color.border};
    }
    &_calendarIcon {
      margin: 0 0 0 5px;
      padding: 5px;
      &_svg {
        display: flex;
      }
      @media (max-width: 350px) {
        display: none;
      }
    }
    .DateInput {
      &_input {
        height: 30px;
        color: ${(props) => props.theme.color.contentPrimary};
        background-color: ${(props) => props.theme.color.inputFill};
        font-weight: 400;
        &__focused {
          border-bottom: 2px solid ${(props) => props.theme.color.primary};
        }
      }
      &_fang {
        height: 11px;
        display: none;
      }
    }
  }
  .SingleDatePicker_picker {
    z-index: 2;
    border: 1px solid ${(props) => props.theme.color.border};
    background-color: ${(props) => props.theme.color.headerNavigationFill};
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: 58px;
      width: 16px;
      height: 16px;
      background-color: ${(props) => props.theme.color.backgroundSecondary};
      border-top: 1px solid ${(props) => props.theme.color.border};
      border-right: 1px solid ${(props) => props.theme.color.border};
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
`;

const CalendarNote = styled.div`
  display: flex;
  padding: 0 20px 20px;
  color: #7f93bc;
  font-size: ${(props) => props.theme.typography.size.smallText};
`;

const InfoIcon = styled(Info)`
  margin-right: 5px;
`;

const DocumentDatePicker = (props) => {
  const { t } = useTranslation();
  const { date, documentMinExpiryDays, onDateChange } = props;

  const [dateFocused, setDateFocused] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);

  return (
    <DatePickerWrapper isRequired={dateChanged && !date}>
      <SingleDatePicker
        id="document_expiration_date"
        date={date}
        focused={dateFocused}
        small={true}
        showDefaultInputIcon={true}
        hideKeyboardShortcutsPanel={true}
        placeholder=""
        renderCalendarInfo={() => (
          <CalendarNote>
            <InfoIcon size={16} />
            {t(
              documentMinExpiryDays > 0
                ? "DOCUMENTS.DOCUMENT_EXPIRATION_NOTE"
                : "DOCUMENTS.DOCUMENT_ISSUING_NOTE",
              { number: Math.abs(documentMinExpiryDays) }
            )}
          </CalendarNote>
        )}
        isOutsideRange={(day) =>
          isInclusivelyBeforeDay(day, moment().startOf("day").add(documentMinExpiryDays, "d")) ||
          isAfterDay(
            day,
            documentMinExpiryDays < 0
              ? moment().startOf("day")
              : moment().startOf("day").add(10, "Y")
          )
        }
        initialVisibleMonth={() =>
          !!date
            ? date
            : documentMinExpiryDays > 0
            ? moment().add(documentMinExpiryDays, "d")
            : moment()
        }
        displayFormat={() => "DD/MM/YYYY"}
        onDateChange={(date) => {
          setDateChanged(true);
          onDateChange(date);
        }}
        onFocusChange={(dateInput) => setDateFocused(dateInput.focused)}
        numberOfMonths={1}
      />
    </DatePickerWrapper>
  );
};

export default DocumentDatePicker;
