import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { PlusCircle } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { Button, ParagraphLarge, ParagraphSmall } from "@my-swipestox/components";

import api from "../../api";
import constants from "../../constants";

import LobbyItem from "./LobbyItem";
import EditTradingAccount from "./EditTradingAccount";
import ChangePassword from "./ChangePassword";
import ShowPassword from "./ShowPassword";
import Loader from "../../components/loader/Loader";
import state from "../../state";

const LobbyContainer = styled.div`
  padding: 1rem;
`;

const LobbyItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 0.7em;
  grid-column-gap: 0.7em;
  margin-top: 2rem;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${constants.BREAKPOINTS.LARGE_DEVICES}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Title = styled.label`
  font-size: ${(props) => props.theme.typography.size.normalText};
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    font-size: ${(props) => props.theme.typography.size.headline};
  }
`;

const PlusCircleCustom = styled(PlusCircle)`
  width: 1rem;
  margin-right: 5px;
  color: ${(props) => props.theme.color.primary};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 2rem 0;
  background-color: ${(props) => props.theme.color.border};
`;

const Footer = styled.footer``;

const StyledParagraphSmall = styled(ParagraphSmall)`
  color: ${(props) => props.theme.color.contentSecondary};
`;

const activeItemInitialState = {
  custom_name: "",
  currency: "",
  login: "",
};

const Lobby = () => {
  const { t } = useTranslation();
  const userInfo = state.useUserInfo((state) => state.userInfo);

  const tradingAccountsQuery = useQuery(
    constants.QUERY_NAMES.tradingAccounts,
    api.queries.getTradingAccounts
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);
  const [isShowPasswordModalOpen, setIsShowPasswordModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(activeItemInitialState);

  const tradingAccounts = tradingAccountsQuery?.data?.data?.data || [];
  const filteredTradingAccounts =
    tradingAccounts.filter(
      (item) => constants.SUPPORTED_CURRENCIES.indexOf(item.currency) !== -1
    ) || [];

  const onModalClose = () => {
    setIsModalOpen(false);
    setActiveItem(activeItemInitialState);
  };

  const onAddAccountModalClose = () => {
    setIsAddAccountModalOpen(false);
    setActiveItem(activeItemInitialState);
  };

  const handleAddAccount = () => {
    setIsAddAccountModalOpen(true);
  };

  const onChangePasswordClose = () => {
    setIsChangePasswordModalOpen(false);
    setActiveItem(activeItemInitialState);
  };

  const onPasswordShowModalClose = () => {
    setIsShowPasswordModalOpen(false);
    setActiveItem(activeItemInitialState);
  };

  const countLiveAccounts = () => {
    const count = tradingAccounts.reduce((obj, value) => {
      if (!obj[value.type]) {
        obj[value.type] = 1;
      } else {
        obj[value.type] += 1;
      }

      return obj;
    }, {});

    return count.R;
  };

  if (tradingAccountsQuery.isLoading) {
    return <Loader text="LOBBY.LOADING_ACCOUNTS" />;
  }

  if (!tradingAccountsQuery.data) {
    return null;
  }

  const numberOfLiveTerminals = countLiveAccounts() || 0;
  const addAccountEnabled =
    userInfo.user_bo_status &&
    userInfo.user_bo_status !== constants.BO_STATUS.DEMO &&
    numberOfLiveTerminals < constants.MAX_LIVE_ACCOUNTS_NUMBER;
  return (
    <Fragment>
      <LobbyContainer>
        <TitleWrapper>
          <Title>{t("LOBBY.TITLE")}</Title>
          {addAccountEnabled && (
            <Button onClick={handleAddAccount} kind="secondary">
              <PlusCircleCustom />
              {t("LOBBY.ADD_ACCOUNT")}
            </Button>
          )}
        </TitleWrapper>
        <LobbyItemsWrapper>
          {filteredTradingAccounts.map((item) => (
            <LobbyItem
              key={item.terminal_id}
              item={item}
              setIsModalOpen={setIsModalOpen}
              setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
              setIsShowPasswordModalOpen={setIsShowPasswordModalOpen}
              setActiveItem={setActiveItem}
            />
          ))}
        </LobbyItemsWrapper>
        <Divider />
        <Footer>
          <ParagraphLarge mb="0.5rem">{t("LOBBY.PASSWORD_MODIFICATION")}</ParagraphLarge>
          <StyledParagraphSmall mb="2rem">
            {t("LOBBY.PASSWORD_MODIFICATION_DESCRIPTION")}
          </StyledParagraphSmall>
          <ParagraphLarge mb="0.5rem">{t("LOBBY.LEVERAGE_MODIFICATION")}</ParagraphLarge>
          <StyledParagraphSmall>
            <Trans
              defaults="LOBBY.LEVERAGE_MODIFICATION_DESCRIPTION"
              values={{ email: t("LOBBY.LEVERAGE_MODIFICATION_DESCRIPTION_EMAIL") }}
              components={[
                <a
                  href={`mailto:${constants.SERVICE_EMAIL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("LOBBY.LEVERAGE_MODIFICATION_DESCRIPTION_EMAIL")}
                </a>,
              ]}
            />
          </StyledParagraphSmall>
        </Footer>
      </LobbyContainer>
      <EditTradingAccount isOpen={isModalOpen} onClose={onModalClose} item={activeItem} />
      <EditTradingAccount
        isOpen={isAddAccountModalOpen}
        onClose={onAddAccountModalClose}
        item={activeItem}
      />
      <ChangePassword
        isOpen={isChangePasswordModalOpen}
        onClose={onChangePasswordClose}
        item={activeItem}
      />
      <ShowPassword
        isOpen={isShowPasswordModalOpen}
        item={activeItem}
        onClose={onPasswordShowModalClose}
      />
    </Fragment>
  );
};

export default Lobby;
