import { Button, FormControl, ParagraphSmall } from "@my-swipestox/components";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as R from "ramda";
import { components } from "react-select";
import styled from "styled-components";
import React from "react";
import { toast } from "react-toastify";
import { addMilliseconds, format, minutesToMilliseconds } from "date-fns";
import { useCountdownTimer } from "use-countdown-timer";
import NumberFormat from "react-number-format";

import Select from "../../components/select/Select";
import InternalTransferSuccessModal from "./InternalTransferSuccessModal";

import api from "../../api";
import utils from "../../utils";
import constants from "../../constants";

const StyledSingleValue = styled.div`
  display: flex;
  align-items: center;
`;

const SymbolImage = styled.img`
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
`;

const StyledImage = styled.img`
  width: 20px;
  margin-right: 10px;
  object-fit: contain;
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
`;

const OptionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SingleValueCustomName = styled.div`
  margin-right: 10px;
`;

const SingleValueBalance = styled.span`
  color: ${(props) => props.theme.color.contentSecondary};
`;

const GridWrapper = styled.div`
  margin-bottom: 1rem;
  .input-error {
    background-color: rgba(238, 47, 63, 0.02);
    border: 1px solid ${(props) => props.theme.color.negative};
  }
  .error {
    width: 500px;
    color: ${(props) => props.theme.color.negative};
    font-size: 12px;
    line-height: 1;
    margin: 2px 0 1rem 3px;
  }`;

const InputCurrencyWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.lightGrey};
  border-radius: 4px;
  box-shadow: none;
  padding: 0.5rem;
  .number-input{
    border: none;
    width: 100%;
    outline: none;
  }
`;

const InputCurrency = styled.input`
  border: none;
  width: 100%;
  outline: none;
`;

const CurrencyText = styled.div`
  color: #7f93bc;
  display: flex;
  justify-content: center;
  padding: 4px;
  font-weight: 200;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-end;
`;

const EqualsSign = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 9px;
  color: ${(props) => props.theme.color.lightGrey};
  font-weight: 500;
  font-size: 18px;
`;

const CustomSingleValue = (props) => {
  const { data } = props;
  const customNameWithLogin = `${data.custom_name || data.company} - ${data.login}`;
  return (
    <components.SingleValue {...props}>
      <StyledSingleValue>
        <StyledImage
          src={`${constants.WALLET_S3_CONTENT}/${R.toLower(data.currency)}.png`}
          alt=""
        />
        <SingleValueCustomName>{customNameWithLogin}</SingleValueCustomName>
        <SingleValueBalance>
          {parseFloat(data.balance).toFixed(data.currency_digits)} {data.currency}
        </SingleValueBalance>
      </StyledSingleValue>
    </components.SingleValue>
  );
};

const CustomOption = (props) => {
  const { data } = props;
  const customNameWithLogin = `${data.custom_name || data.company} - ${data.login}`;
  return (
    <components.Option {...props}>
      <StyledOption>
        <StyledImage
          src={`${constants.WALLET_S3_CONTENT}/${R.toLower(data.currency)}.png`}
          alt=""
        />
        <OptionColumn>
          <div>{customNameWithLogin}</div>
          {parseFloat(data.balance).toFixed(data.currency_digits)} {data.currency}
        </OptionColumn>
      </StyledOption>
    </components.Option>
  );
};

const initialValues = {
  amount: "",
  sourceAccountField: null,
  targetAccountField: null,
};

const InternalTransfer = (props) => {
  const { t } = useTranslation();
  const { setFullAccount } = props;
  const validationSchema = Yup.object()
    .shape({
      sourceAccountField: Yup.object().shape({
        balance: Yup.number()
          .required()
          .min(1, t("MANAGE_MONEY.TRANSFER.YOU_DONT_HAVE_ENOUGH_MONEY", { currency: "USD" })),
      }),
      amount: Yup.string().required(t("MANAGE_MONEY.TRANSFER.AMOUNT_IS_REQUIRED")),
    });

  const formik = useFormik({
    initialValues,
    onSubmit: handleOnSubmit,
    validationSchema,
  });

  const isEnoughBalance = formik.values.sourceAccountField?.balance < formik.values.amount;

  const tradingAccountsQuery = useQuery(
    constants.QUERY_NAMES.tradingAccounts,
    api.queries.getTradingAccounts
  );
  let tradingAccounts = tradingAccountsQuery?.data?.data?.data || [];
  const internalTransferMutation = useMutation(api.mutations.internalTransfer, {
    onSuccess: handleOnSuccess,
    onError: handleOnError,
  });
  const ratesQuery = useQuery(constants.QUERY_NAMES.getWalletAddressesAndRates, () =>
    api.queries.getWalletAddressesAndRates()
  );
  const [destinationAmount, setDestinationAmount] = React.useState("");
  const [isOpen, setisOpen] = React.useState(false);
  const { isRunning, countdown, start, reset } = useCountdownTimer({
    timer: minutesToMilliseconds(5),
    onExpire: () => ratesQuery.refetch(),
  });
  const renderAmount =
    !R.isNil(formik.values.sourceAccountField?.terminal_id) &&
    !R.isNil(formik.values.targetAccountField?.terminal_id);

  React.useEffect(() => {
    if (renderAmount && !isRunning) {
      start();
    }
  }, [renderAmount, isRunning, start]);

  function handleOnSuccess() {
    tradingAccountsQuery.refetch();
    tradingAccounts = tradingAccountsQuery?.data?.data?.data;
    setisOpen(true);
    reset();
  }

  function handleOnError() {
    toast.error(t("REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER"));
  }

  function handleOnClose() {
    setisOpen(false);
    formik.setFieldValue('amount','');
    tradingAccounts && formik.setFieldValue('sourceAccountField',tradingAccounts?.find(account => account.terminal_id === formik.values.sourceAccountField?.terminal_id));
    tradingAccounts && formik.setFieldValue('targetAccountField',tradingAccounts?.find(account => account.terminal_id === formik.values.targetAccountField?.terminal_id));
    tradingAccounts && setFullAccount(tradingAccounts?.find(account => account.terminal_id === formik.values.sourceAccountField?.terminal_id));
  }

  function handleOnSubmit(data) {
    const payload = {
      amount: parseFloat(data.amount),
      currency: data.sourceAccountField.currency,
      sourceTerminal: parseInt(data.sourceAccountField.terminal_id, 10),
      targetTerminal: parseInt(data.targetAccountField.terminal_id, 10),
    };
    internalTransferMutation.mutate(payload);
  }

  const memoizedGetRate = React.useCallback(() => {
    const sourceRate = R.find(
      (item) => item.currency === formik.values.sourceAccountField?.currency,
      ratesQuery.data?.other || []
    );
    if (R.isNil(sourceRate)) {
      return 0;
    }
    const currencyPairRate =
      sourceRate[`rate_${R.toLower(formik.values.targetAccountField?.currency || "")}`] ||
      sourceRate["rate"];
    return currencyPairRate.toFixed(2);
  }, [
    ratesQuery.data?.other,
    formik.values.sourceAccountField?.currency,
    formik.values.targetAccountField?.currency,
  ]);

  React.useEffect(() => {
    if (formik.values.sourceAccountField?.currency === formik.values.targetAccountField?.currency) {
      setDestinationAmount(formik.values.amount);
    } else {
      setDestinationAmount((formik.values.amount * memoizedGetRate()).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tradingAccounts = tradingAccountsQuery?.data?.data?.data;;
  }, [
    formik.values.sourceAccountField,
    formik.values.targetAccountField,
    formik.values.amount,
    memoizedGetRate,
    tradingAccounts,
    tradingAccountsQuery,
  ]);

  function getCaption() {
    if (R.and(formik.errors.amount, formik.touched.amount)) {
      return formik.errors.amount;
    } else if (
      R.and(formik.errors.sourceAccountField?.balance, formik.touched.sourceAccountField?.balance)
    ) {
      return formik.errors.sourceAccountField?.balance;
    }
    return "";
  }

  const caption = getCaption();
  const isError = R.or(
    R.and(formik.errors.amount, formik.touched.amount),
    R.and(formik.errors.sourceAccountField?.balance, formik.touched.sourceAccountField?.balance)
  );
  const successModalData = {
    currency: formik.values.sourceAccountField?.currency_symbol,
    amount: formik.values.amount,
    customNameWithLogin: `${
      formik.values.targetAccountField?.custom_name || formik.values.targetAccountField?.company
    } - ${formik.values.targetAccountField?.login}`,
  };

  const numberInputField = () => {
    const hasError = formik.values.sourceAccountField.balance < formik.values.amount;
    return (
      <InputCurrencyWrapper className={`${hasError ? 'input-error' : ''}`}>
        <SymbolImage
          src={`${constants.WALLET_S3_CONTENT}/${formik.values.sourceAccountField?.currency.toLowerCase()}.png`}
        />
        <NumberFormat
          name='amount'
          className='number-input'
          decimalScale={8}
          allowNegative={false}
          onChange={formik.handleChange}
          value={formik.values.amount}
        />
        <CurrencyText>{formik.values.sourceAccountField?.currency}</CurrencyText>
      </InputCurrencyWrapper>
    );
  }

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <FormControl label={t("MANAGE_MONEY.TRANSFER.TRANSFERRING_FROM")} mb="1rem">
          <Select
            options={R.filter(
              (item) =>
                item.type === constants.TERMINAL_TYPES.R.value &&
                utils.isSupported(item.currency) &&
                !utils.isCrypto(item.currency) &&
                item.terminal_id !== formik.values.targetAccountField?.terminal_id,
              tradingAccounts
            )}
            value ={formik.values.sourceAccountField}
            placeholder={t("MANAGE_MONEY.TRANSFER.SELECT_SOURCE_ACCOUNT")}
            getOptionValue={(e) => e.terminal_id}
            getOptionLabel={(e) => e.terminal_id}
            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            onChange={(item) => formik.setFieldValue("sourceAccountField", item)}
            isClearable
          />
        </FormControl>
        <FormControl label={t("MANAGE_MONEY.TRANSFER.TRANSFERRING_TO_MY_ACCOUNT")} mb="1rem">
          <Select
            options={R.filter(
              (item) =>
                item.type === constants.TERMINAL_TYPES.R.value &&
                utils.isSupported(item.currency) &&
                !utils.isCrypto(item.currency) &&
                item.terminal_id !== formik.values.sourceAccountField?.terminal_id,
              tradingAccounts
            )}
            placeholder={t("MANAGE_MONEY.TRANSFER.SELECT_TARGET_ACCOUNT")}
            getOptionValue={(e) => e.terminal_id}
            getOptionLabel={(e) => e.terminal_id}
            components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
            onChange={(item) => formik.setFieldValue("targetAccountField", item)}
            isClearable
          />
        </FormControl>
        {renderAmount ? (
          <React.Fragment>
            <GridWrapper>
              <FormControl mb={`${isEnoughBalance ? '' : '1rem'}`} caption={caption} error={isError}>
                <Grid>
                  <FormControl
                    label={t("MANAGE_MONEY.TRANSFER.AMOUNT", {
                      currency: formik.values.sourceAccountField?.currency,
                    })}
                  >
                    {numberInputField()}
                  </FormControl>
                  <EqualsSign>=</EqualsSign>
                  <FormControl
                    label={t("MANAGE_MONEY.TRANSFER.APPROX", {
                      currency: formik.values.targetAccountField?.currency,
                    })}
                  >
                    <InputCurrencyWrapper>
                      <SymbolImage
                        src={`${constants.WALLET_S3_CONTENT}/${formik.values.targetAccountField?.currency.toLowerCase()}.png`}
                      />
                      <InputCurrency value={destinationAmount} />
                      <CurrencyText>{formik.values.targetAccountField?.currency}</CurrencyText>
                    </InputCurrencyWrapper>
                  </FormControl>
                </Grid>
              </FormControl>
              {isEnoughBalance && <div className="error">{t("MANAGE_MONEY.TRANSFER.YOU_DONT_HAVE_ENOUGH_MONEY", { currency: formik.values.sourceAccountField?.currency })}</div>}
              <ParagraphSmall>
                {t("MANAGE_MONEY.TRANSFER.CURRENT_EXCHANGE_RATE", {
                  sourceCurrency: formik.values.sourceAccountField?.currency,
                  targetCurrency: formik.values.targetAccountField?.currency,
                })}{" "}
                = {memoizedGetRate()}
              </ParagraphSmall>
              <ParagraphSmall>
                {t("MANAGE_MONEY.TRANSFER.REFRESH_IN", {
                  time: format(addMilliseconds(new Date(0), countdown), "mm:ss"),
                })}
              </ParagraphSmall>
              <ParagraphSmall>
                {t("MANAGE_MONEY.TRANSFER.THE_ACTUAL_EXCHANGE_RATE_MAY_BE_DIFFERENT")}
              </ParagraphSmall>
            </GridWrapper>
            <Button
              isLoading={R.or(internalTransferMutation.isLoading, ratesQuery.isLoading)}
              disabled={isError || isEnoughBalance}
              type="submit"
            >
              {t("MANAGE_MONEY.TRANSFER.TRANSFER_MONEY")}
            </Button>
          </React.Fragment>
        ) : null}
      </form>
      <InternalTransferSuccessModal
        t={t}
        isOpen={isOpen}
        onClose={handleOnClose}
        data={successModalData}
      />
    </React.Fragment>
  );
};

export default InternalTransfer;
