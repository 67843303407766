import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import fromEntries from "object.fromentries";
import styled, { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import {
  ThemeProvider as NagaThemeProvider,
  DarkTheme,
  LightTheme,
  GlobalStyle,
} from "@my-swipestox/components";

import themes from "./styles/themes";
import state from "./state";
import mqtt from "./utils/mqtt";
import constants from "./constants";
import utils from "./utils";
import Global from "./styles/global";
import Layout from "./routing/Layout";
import NotFound from "./components/not-found/NotFound";
import Login from "./features/auth/Login";
import ManageMoney from "./features/money/ManageMoney";
import Account from "./features/account/Account";
import Security from "./features/account/Security";
import Register from "./features/auth/Register";
import Lobby from "./features/lobby/Lobby";
import KycLandingPage from "./features/kyc/KycLandingPage";
import Kyc from "./features/kyc/Kyc";
import Verification from "./features/kyc/Verification";
import DepositIframe from "./features/money/DepositIframe";
import MetaTrader from "./features/metatrader/MetaTrader";
import Documents from "./features/documents/Documents";
import Help from "./features/help/Help";
import ResetPassword from "./features/auth/ResetPassword";
import DepositStatus from "./features/money/DepositStatus";
import PasswordRecovery from "./features/auth/PasswordRecovery";
import ConfirmEmail from "./features/auth/ConfirmEmail";
import AutoLoginViaToken from "./features/auth/AutoLoginViaToken";
import MFAForm from "./features/auth/MfaForm";
import Nucleus365TransactionCompleted from './features/money/nucleus/transaction-completed';

if (!Object.fromEntries) {
  fromEntries.shim();
}

const oneDayInMs = 86400000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: oneDayInMs,
      retry: (failureCount, error) => {
        return failureCount < constants.REQUEST_RETRY_COUNT && error?.response?.status === 401;
      },
    },
    mutations: {
      retry: (failureCount, error) => {
        return failureCount < constants.REQUEST_RETRY_COUNT && error?.response?.status === 401;
      },
    },
  },
});

async function runMqtt(isLoggedIn, userId) {
  if (!isLoggedIn || !userId) {
    return;
  }

  await mqtt.connect();
  const topic = `user_changes/${userId}`;

  try {
    mqtt.subscribe(topic);
    mqtt.on("message");
  } catch (e) {
    if (!constants.IS_PROD_ENV) {
      console.log(e);
    }
  }
}

const ToastContainerCustom = styled(ToastContainer)`
  font-size: ${(props) => props.theme.typography.size.smallText};
`;

const App = () => {
  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);
  const token = state.useAuth((state) => state.token);
  const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const theme = isDarkMode ? themes.dark : themes.light;

  useEffect(() => {
    if (!isLoggedIn && !queryClient.isFetching()) {
      queryClient.resetQueries();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && userInfo.user_id) {
      runMqtt(isLoggedIn, userInfo.user_id);
    }
  }, [isLoggedIn, userInfo.user_id, token]);

  return (
    <ThemeProvider theme={theme}>
      <NagaThemeProvider theme={isDarkMode ? DarkTheme : LightTheme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            {isLoggedIn ? (
              <Layout>
                <Switch>
                  <Route path="/" exact component={Lobby} />
                  <Route path="/manage-money" component={ManageMoney} />
                  <Route path="/account" component={Account} />
                  <Route path="/security" component={Security} />
                  <Route path="/live-account" component={KycLandingPage} />
                  <Route path="/live-account-questionnaire" component={Kyc} />
                  <Route exact path="/login">
                    <Redirect to="/" />
                  </Route>
                  <Route exact path="/register">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/verification" component={Verification} />
                  <Route path="/credit-card" component={DepositIframe} />
                  <Route path="/metatrader" component={MetaTrader} />
                  {utils.canAccessDocuments(userInfo.user_bo_status) && (
                    <Route path="/documents" component={Documents} />
                  )}
                  <Route path="/help" component={Help} />
                  <Route path="/deposit-status" component={DepositStatus} />
                  <Route path="/confirm-email" component={ConfirmEmail} />
                  <Route path="/pulsdebit-transaction-completed" component={Nucleus365TransactionCompleted} />
                  <Route component={NotFound} />
                </Switch>
              </Layout>
            ) : (
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/auth/token" component={AutoLoginViaToken} />
                <Route path="/mfa" component={MFAForm} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/passwordrecovery/:email/:code" component={PasswordRecovery} />
                <Route component={() => <Redirect to="/" />} />
              </Switch>
            )}
            <Route path="/confirm-email" component={ConfirmEmail} />
          </Router>
          <Global />
          <GlobalStyle />
        </QueryClientProvider>
        <ToastContainerCustom />
      </NagaThemeProvider>
    </ThemeProvider>
  );
};

export default App;
