import { useWindowSize } from "react-use";

import constants from "../constants";

function useIsMobile() {
  const { width } = useWindowSize();

  const isMobile =
    width <= parseInt(constants.BREAKPOINTS.MEDIUM_DEVICES.slice(0, -2), 10);

  return isMobile;
}

export default useIsMobile;
