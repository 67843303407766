import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spinner } from "@my-swipestox/components";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  color: ${(props) => props.theme.color.contentSecondary};
  font-size: ${(props) => props.theme.typography.size.normalText};
  margin-left: 1rem;
`;

const Loader = (props) => {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <LoaderWrapper className={props.className}>
      <Spinner size="30px" />
      <Text>{t(text)}</Text>
    </LoaderWrapper>
  );
};

export default Loader;
