import axios from "axios";
import api from ".";

import constants from "../constants";
import cacheHelper from "../helpers/cacheHelper";
import state from "../state";

const instance = axios.create({
  baseURL: constants.API_URL,
});

let refreshInProgess = false;

instance.interceptors.request.use(
  function (config) {
    config.headers.authorization = `JWT ${cacheHelper.getToken()}`;
    config.headers.xsrf = cacheHelper.getXsrf();
    config.headers["accept-version"] = constants.ACCEPT_VERSION.ONE;
    config.headers.platform = constants.PLATFORM;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const retryCount = JSON.parse(cacheHelper.getRetryCount()) || {};

      if (retryCount[error.config.url] > constants.REQUEST_RETRY_COUNT) {
        cacheHelper.clearUserTokens();
        cacheHelper.clearRetryCount();
        window.location.href = "/";
        return;
      }

      const currentTime = new Date(new Date().toISOString()).getTime(); //utc date
      const expireTime = new Date(cacheHelper.getRefreshTokenExpTime()).getTime(); //already utc

      if (!refreshInProgess && cacheHelper.getRefreshToken() && currentTime > expireTime) {
        refreshInProgess = true;
        const result = await api.mutations.generateNewTokens(cacheHelper.getRefreshToken());
        const newTokens = result.data?.info || {};
        state.useAuth.setState({ token: newTokens.token });
        cacheHelper.setUserTokens(newTokens);
        cacheHelper.clearRetryCount();
        refreshInProgess = false;
      }

      retryCount[error.config.url] = retryCount[error.config.url]
        ? parseInt(retryCount[error.config.url], 10) + 1
        : 1;
      cacheHelper.setRetryCount(retryCount);

      return axios(error.config);
    }

    if (error?.response?.status === 451 && !error?.response?.data?.info?.blocked_for) {
      cacheHelper.clearUserTokens();
      cacheHelper.clearRetryCount();
      window.location.href = "/";
      return;
    }

    return Promise.reject(error);
  }
);

export default instance;
