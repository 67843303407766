import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Input,
  Button,
  FormControl,
  ParagraphSmall,
  ParagraphLarge,
} from "@my-swipestox/components";

import utils from "../../utils";
import api from "../../api";
import state from "../../state";
import constants from "../../constants";
import { COUNTRIES } from "../../components/phone-input/constants";

import Select from "../../components/select/Select";
import PhoneInput from "../../components/phone-input/PhoneInput";
import UnauthorizedHeader from "../header/UnauthorizedHeader";

const Grid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const Content = styled.div`
  border-radius: 3px;
  max-width: 32rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.border};
  background-color: ${(props) => props.theme.color.backgroundSecondary};
`;

const FormWrapper = styled.div`
  padding: 4rem 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    padding: 4rem;
  }
`;

const FormGrid = styled.div`
  grid-column-gap: 1rem;
`;

const Footer = styled.footer`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-align: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
`;

const StyledLink = styled.span`
  color: ${(props) => props.theme.color.primary};
  cursor: pointer;
`;

const StyledSubheadline = styled(ParagraphLarge)`
  text-align: center;
`;

const defaultValues = {
  firstName: "",
  lastName: "",
  password: "",
  username: "",
  phone: "",
  email: "",
  country: {
    value: "CN",
    label: "China",
  },
};

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
    />
  );
};

const Register = () => {
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const onSuccess = (data) => {
    const token = data.data.info.token;
    const xsrf = data.data.info.xsrf;
    const userInfo = data.data.data;

    i18n.changeLanguage(userInfo.language);
    moment.locale(userInfo.language === "en" ? "en" : "zh-cn");

    history.push("/live-account");
    setIsLoggedIn(true, token);
    setXsrf(xsrf);
    setUserInfo(userInfo);
  };
  const registerMutation = useMutation(api.mutations.register, {
    onSuccess,
    onError: () => toast.error(t("REGISTRATION.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER")),
  });
  const checkIfUserExistsMutation = useMutation(api.mutations.checkIfUserExists);
  const [country, setCountry] = React.useState(COUNTRIES.CN);
  const smsLookupMutation = useMutation(api.mutations.smsLookup);
  const { handleSubmit, getValues, clearErrors, control, errors, formState } = useForm({
    defaultValues,
  });
  const reason = utils.getParamByName("reason");
  const affiliate = utils.getParamByName("affiliate");
  const cmp = utils.getParamByName("cmp");
  const refid = utils.getParamByName("refid");

  const getLanguageCodeFromQuery = () => {
      const queryString = new URLSearchParams(window.location.search);
      const langQSParam = queryString.get("lang") || queryString.get("lng");
      const language =
        constants.SUPPORTED_LANGUAGES.find((lng) => lng.value === langQSParam)?.value || "zh-hans";

      return language;
  };

  const onSubmit = async (values) => {
    const data = {
      p_country: values.country.value,
      p_user_name: values.username,
      p_email: values.email,
      p_first_name: values.firstName,
      p_last_name: values.lastName,
      p_plain_password: values.password,
      p_tel: `${country.value}${values.phone}`,
      phone_number_confirmed: true,
      p_webinar: reason || affiliate || constants.PLATFORM,
      p_cl_cmp: cmp,
      p_cl_aff: refid,
      p_app_language: getLanguageCodeFromQuery(),
    };

    return registerMutation.mutate(data);
  };

  const validateUsername = async (value) => {
    const trimmedValue = value.trim();

    if (trimmedValue.length === 0) {
      return t("REGISTRATION.USERNAME_IS_REQUIRED");
    } else if (trimmedValue.length <= 6) {
      return t("REGISTRATION.PLEASE_ENTER_6_OR_MORE_CHARACTERS");
    } else if (trimmedValue.length >= 20) {
      return t("REGISTRATION.USERNAME_MUST_BE_SHORTER_THAN_20_CHARACTERS");
    }
    const data = await checkIfUserExistsMutation.mutateAsync({
      user_name: trimmedValue,
    });
    const isUsedUsername = data.data?.data?.is_used_user_name;

    if (isUsedUsername) {
      return t("REGISTRATION.USERNAME_HAS_ALREADY_BEEN_TAKEN");
    }

    return true;
  };

  const validateEmail = async (value) => {
    const trimmedValue = value.trim();

    if (trimmedValue.length === 0) {
      return t("REGISTRATION.EMAIL_IS_REQUIRED");
    } else if (!trimmedValue.match(/^\S+@\S+\.\S+$/)) {
      return t("REGISTRATION.EMAIL_MUST_BE_VALID");
    }
    const data = await checkIfUserExistsMutation.mutateAsync({
      email: value,
    });
    const isUsedEmail = data.data.data?.is_used_email;
    const isUsedPhone = data.data.data?.is_used_phone_number;

    if (!isUsedPhone) {
      clearErrors("phone");
    }

    if (isUsedEmail) {
      return t("REGISTRATION.EMAIL_HAS_ALREADY_BEEN_TAKEN");
    }

    return true;
  };

  const validatePhone = async (value) => {
    const trimmedValue = value.trim();

    if (trimmedValue.length === 0) {
      return t("REGISTRATION.PHONE_NUMBER_IS_REQUIRED");
    }

    const data = await smsLookupMutation.mutateAsync(`${country.value}${trimmedValue}`);
    const isValidPhone = data.data?.data?.isValid;

    if (!isValidPhone) {
      return t("REGISTRATION.PHONE_NUMBER_FORMAT_IS_INALID");
    }

    const resp = await checkIfUserExistsMutation.mutateAsync({
      email: getValues("email") || null,
      phone_number: `${country.value}${value}`,
    });
    const isUsedPhone = resp.data.data?.is_used_phone_number;

    if (isUsedPhone) {
      return t("REGISTRATION.PHONE_HAS_ALREADY_BEEN_TAKEN");
    }

    clearErrors("phone");
    return true;
  };

  const handleOnLoginClick = () => {
    if (reason) {
      return history.push(`/login?reason=${reason}`);
    }
    return history.push(`/login`);
  };

  React.useEffect(() => {
    if (formState.touched.phone) {
      validatePhone(getValues("phone"));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country.value, formState.touched.phone]);

  const submitDisabled = registerMutation.isLoading;

  return (
    <Grid>
      <UnauthorizedHeader />
      <Wrapper>
        <Content>
          <FormWrapper>
            <StyledSubheadline mb="1rem">
              {t("REGISTRATION.CREATE_YOUR_NAGA_ACCOUNT")}
            </StyledSubheadline>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGrid>
                <FormControl
                  caption={(errors.firstName && errors.firstName.message) || ""}
                  error={errors.firstName}
                  mb="1rem"
                >
                  <Controller
                    type="text"
                    placeholder={t("REGISTRATION.FIRST_NAME")}
                    name="firstName"
                    rules={{
                      required: t("REGISTRATION.FIRST_NAME_IS_REQUIRED"),
                    }}
                    control={control}
                    as={Input}
                  />
                </FormControl>
                <FormControl
                  caption={(errors.lastName && errors.lastName.message) || ""}
                  error={errors.lastName}
                  mb="1rem"
                >
                  <Controller
                    type="text"
                    name="lastName"
                    placeholder={t("REGISTRATION.LAST_NAME")}
                    rules={{
                      required: t("REGISTRATION.LAST_NAME_IS_REQUIRED"),
                    }}
                    control={control}
                    as={Input}
                  />
                </FormControl>
                <FormControl
                  caption={(errors.username && errors.username.message) || ""}
                  error={errors.username}
                  mb="1rem"
                >
                  <Controller
                    type="text"
                    name="username"
                    placeholder={t("REGISTRATION.USERNAME")}
                    rules={{
                      validate: validateUsername,
                    }}
                    control={control}
                    as={Input}
                  />
                </FormControl>
                <FormControl
                  caption={(errors.email && errors.email.message) || ""}
                  error={errors.email}
                  mb="1rem"
                >
                  <Controller
                    type="text"
                    name="email"
                    placeholder={t("REGISTRATION.EMAIL")}
                    rules={{
                      validate: validateEmail,
                    }}
                    control={control}
                    as={Input}
                  />
                </FormControl>
                <FormControl
                  caption={(errors.password && errors.password.message) || ""}
                  error={errors.password}
                  mb="1rem"
                >
                  <Controller
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    placeholder={t("REGISTRATION.PASSWORD")}
                    rules={{
                      required: t("REGISTRATION.PASSWORD_IS_REQUIRED"),
                      pattern: {
                        value: constants.PASSWORD_REGEX,
                        message: t("REGISTRATION.PASSWORD_REGEX_NOT_MET"),
                      },
                    }}
                    control={control}
                    as={Input}
                  />
                </FormControl>
                <FormControl disabled={true} mb="1rem">
                  <Controller
                    placeholder={t("REGISTRATION.COUNTRY")}
                    as={CustomSelect}
                    name="country"
                    control={control}
                  />
                </FormControl>
                <FormControl mb="1rem">
                  <PhoneInput country={country} onCountryChange={(option) => setCountry(option)}>
                    <FormControl
                      caption={(errors.phone && errors.phone.message) || ""}
                      error={errors.phone}
                    >
                      <Controller
                        name="phone"
                        placeholder={t("REGISTRATION.PHONE_NUMBER")}
                        control={control}
                        as={Input}
                        rules={{
                          validate: validatePhone,
                        }}
                      />
                    </FormControl>
                  </PhoneInput>
                </FormControl>
              </FormGrid>
              <FormControl mb="1rem">
                <Button
                  shouldFitContainer
                  type="submit"
                  disabled={submitDisabled}
                  isLoading={registerMutation.isLoading}
                >
                  <span>{t("REGISTRATION.SIGN_UP")}</span>
                </Button>
              </FormControl>
            </form>
            <ParagraphSmall as="span">
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    "REGISTRATION.BY_SIGNIN_UP_YOU_AGREE_TO_OUR_TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY",
                    {
                      terms: `<a href=${
                        constants.NAGA_LEGAL_DOCUMENTATION_URL
                      } target="_blank" rel="noopener noreferrer">${t(
                        "REGISTRATION.TERMS_AND_CONDITIONS"
                      )}</a>`,
                      privacyPolicy: `<a href=${
                        constants.NAGA_FLEXIBLE_PRIVACY_POLICY_URL
                      } target="_blank" rel="noopener noreferrer">${t(
                        "REGISTRATION.PRIVACY_POLICY"
                      )}</a>`,
                    }
                  ),
                }}
              />
            </ParagraphSmall>
          </FormWrapper>
          <Footer>
            <ParagraphSmall as="span">
              {t("REGISTRATION.ALREADY_HAVE_AN_ACCOUNT")}{" "}
              <StyledLink onClick={handleOnLoginClick}>{t("REGISTRATION.SIGN_IN")}</StyledLink>
            </ParagraphSmall>
          </Footer>
        </Content>
      </Wrapper>
    </Grid>
  );
};

export default Register;
