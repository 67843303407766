import { useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import api from "../../api";
import constants from "../../constants";

import Loader from "../../components/loader/Loader";
import DocumentsTable from "./DocumentsTable";
import EmptyDocumentRequests from "./EmptyDocumentRequests";
import DocumentsErrorFetching from "./DocumentsErrorFetching";
import DocumentModal from "./DocumentModal";

const DocumentsWrapper = styled.div`
  padding: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    background-color: transparent;
  }
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const DocumentsLoader = styled(Loader)`
  justify-content: flex-start;
`;

const DocumentRequestsTitle = styled.h2`
  font-size: ${(props) => props.theme.typography.size.subheadline};
  margin-bottom: 1rem;
`;

const DocumentsTitle = styled.h2`
  font-size: ${(props) => props.theme.typography.size.subheadline};
  margin-top: 2.5rem;
  margin-bottom: 1rem;
`;

const Documents = () => {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState([]);
  const [documentRequests, setDocumentRequests] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState({});

  const documentsQuery = useQuery(
    constants.QUERY_NAMES.documents,
    () => api.queries.getDocuments(),
    {
      refetchOnMount: "always",
      onSuccess: (response) => {
        setDocuments(response);
      },
    }
  );
  const documentRequestsQuery = useQuery(
    constants.QUERY_NAMES.documentRequests,
    () => api.queries.getDocumentRequests(["Requested"]),
    {
      refetchOnMount: "always",
      onSuccess: (response) => {
        setDocumentRequests(response);
      },
    }
  );

  return (
    <DocumentsWrapper>
      <DocumentRequestsTitle>{t("DOCUMENTS.REQUESTED_DOCUMENTS")}</DocumentRequestsTitle>
      {documentRequestsQuery.isFetching ? (
        <DocumentsLoader text="DOCUMENTS.LOADING_DOCUMENTS_INFORMATION" />
      ) : documentRequestsQuery.isError ? (
        <DocumentsErrorFetching refetch={documentRequestsQuery.refetch} />
      ) : !documentRequests.length ? (
        <EmptyDocumentRequests />
      ) : (
        <DocumentsTable documents={documentRequests} setDocument={setSelectedDocument} />
      )}
      <DocumentsTitle>{t("DOCUMENTS.DOCUMENT_UPLOAD_HISTORY")}</DocumentsTitle>
      {documentsQuery.isFetching ? (
        <DocumentsLoader text="DOCUMENTS.LOADING_DOCUMENTS_INFORMATION" />
      ) : documentsQuery.isError ? (
        <DocumentsErrorFetching refetch={documentsQuery.refetch} />
      ) : !documents.length ? (
        <NoData>{t("DOCUMENTS.NO_UPLOADED_DOCUMENT_HISTORY_AVAILABLE")}</NoData>
      ) : (
        <DocumentsTable documents={documents} setDocument={setSelectedDocument} />
      )}
      <DocumentModal
        document={selectedDocument}
        documents={documents}
        documentRequests={documentRequests}
        setDocument={setSelectedDocument}
        setDocuments={setDocuments}
        setDocumentRequests={setDocumentRequests}
      />
    </DocumentsWrapper>
  );
};

export default Documents;
