import { useMutation } from "react-query";
import styled from "styled-components";
import { Input, FormControl, Button, HeadingSmall, ParagraphSmall } from "@my-swipestox/components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";

import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";
import MFAAuthenticator from "./mfa/mfa-authenticator";
import MFAChanged from "./mfa/mfa-changed";
import SecurityMFA from "./mfa/security-mfa";
import state from "../../state";

const Wrapper = styled.div`
  padding: 1rem;
`;

const FormWrapper = styled.div`
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 50%;
  }
`;

const Security = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, errors } = useForm();

  const userInfo = state.useUserInfo((state) => state.userInfo);
  const [securityStep, setSecurityStep] = useState(constants.MFA_STEPS.INITIAL);
  const username = userInfo.user_name;

  const onSuccess = () => {
    toast.success(t("SECURITY.PASSWORD_CHANGE_SUCCESS"));
    reset();
  };
  const onError = (data) => {
    const code = data?.response?.data?.info?.errorCode;

    switch (code) {
      case "1054":
        return toast.error(t("SECURITY.CURRENT_PASSWORD_IS_NOT_CORRECT"));
      default:
        return toast.error(t("ERRORS.DEFAULT"));
    }
  };
  const changePasswordMutation = useMutation(api.mutations.changePassword, {
    onSuccess,
    onError,
  });

  const onSubmit = (values) => {
    const data = {
      current_password: utils.crypto.encode(values.currentPassword),
      new_password: utils.crypto.encode(values.newPassword),
    };

    changePasswordMutation.mutate(data);
  };

  if (securityStep !== constants.MFA_STEPS.INITIAL) {
    return (
      <div>
        {securityStep === constants.MFA_STEPS.MFA_ENABLED ||
        securityStep === constants.MFA_STEPS.RECOVERY_CODES ? (
          <MFAChanged
            mfaEnabled={userInfo.mfa_enabled}
            securityStep={securityStep}
            changeMFAStep={setSecurityStep}
            username={username}
          />
        ) : (
          <MFAAuthenticator
            mfaEnabled={userInfo.mfa_enabled}
            securityStep={securityStep}
            setSecurityStep={setSecurityStep}
          />
        )}
      </div>
    );
  }

  return (
    <Wrapper>
      <SecurityMFA mfaEnabled={userInfo.mfa_enabled} setSecurityStep={setSecurityStep} />
      <HeadingSmall mb="1rem">{t("SECURITY.CHANGE_YOUR_PASSWORD")}</HeadingSmall>
      <ParagraphSmall mb="0.5rem">
        {t("SECURITY.WE_HIGHLY_RECOMMEND_THAT_YOU_CHOOSE_UNIQUE_PASSWORD")}
      </ParagraphSmall>
      <ParagraphSmall mb="1rem">
        {t("SECURITY.PLEASE_NOTE_THIS_IS_YOUR_NAGA_ACCOUNT_PASSWORD_FOR_ALL_NAGA_SERVICES")}
      </ParagraphSmall>
      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            label={t("SECURITY.CURRENT_PASSWORD")}
            mb="1rem"
            caption={(errors.currentPassword && t(errors.currentPassword.message)) || ""}
            error={errors.currentPassword}
          >
            <Input
              type="password"
              name="currentPassword"
              ref={register({
                required: { value: true, message: "SECURITY.CURRENT_PASSWORD_IS_REQUIRED" },
                pattern: {
                  value: constants.PASSWORD_REGEX,
                  message: t("REGISTRATION.PASSWORD_REGEX_NOT_MET"),
                },
              })}
            />
          </FormControl>
          <FormControl
            label={t("SECURITY.NEW_PASSWORD")}
            mb="1rem"
            caption={(errors.newPassword && t(errors.newPassword.message)) || ""}
            error={errors.newPassword}
          >
            <Input
              type="password"
              name="newPassword"
              ref={register({
                required: { value: true, message: "SECURITY.NEW_PASSWORD_IS_REQUIRED" },
                pattern: {
                  value: constants.PASSWORD_REGEX,
                  message: t("REGISTRATION.PASSWORD_REGEX_NOT_MET"),
                },
              })}
            />
          </FormControl>
          <Button
            isLoading={changePasswordMutation.isLoading}
            disabled={changePasswordMutation.isLoading}
          >
            {t("SECURITY.SAVE_CHANGES")}
          </Button>
        </form>
      </FormWrapper>
    </Wrapper>
  );
};

export default Security;
