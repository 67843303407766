import NumberFormat from "react-number-format";
import styled from "styled-components";

const StyledInputNumberContainer = styled.div`
  position: relative;
`;

const StyledInputNumberFlag = styled.label`
  position: absolute;
  right: 10px;
  top: 11px;
  font-size: ${(props) => props.theme.typography.size.smallText};
  color: ${(props) => props.theme.color.contentSecondary};
`;
const StyledInputNumber = styled(NumberFormat)`
  font-size: 1rem;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.color.border};
  color: ${(props) => props.theme.color.contentPrimary};
  width: 100%;
  padding: 8px 6px;
  outline: 0;
  border-color: ${(props) =>
    props.error
      ? props.theme.color.inputBorderError
      : props.theme.color.border};
  background-color: ${(props) =>
    props.error
      ? props.theme.color.inputFillError
      : props.theme.color.inputFill};
  padding-right: ${(props) => (props.flag ? "40px" : 0)};
`;

const Number = (props) => {
  return (
    <StyledInputNumberContainer>
      <StyledInputNumber {...props} />
      {props.flag && (
        <StyledInputNumberFlag>{props.flag}</StyledInputNumberFlag>
      )}
    </StyledInputNumberContainer>
  );
};

export default Number;
