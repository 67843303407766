import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const global = createGlobalStyle`
  body {
    color: ${(props) => props.theme.color.contentPrimary};
  }
  a {
    outline: 0;
    color: ${(props) => props.theme.color.primary};
  }
  .kyc-account-types-modal {
    border-radius: 15px;
    width: 830px;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
`;

export default global;
