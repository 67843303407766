/* eslint-disable no-use-before-define */
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import * as Yup from "yup";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { components } from "react-select";
import styled from "styled-components";
import { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "@my-swipestox/components";
import { useHistory } from "react-router";

import api from "../../api";
import constants from "../../constants";
import state from "../../state";

import moneyHelper from "../../helpers/moneyHelper";

import Amount from "../../components/amount/Amount";
import Number from "../../components/number/Number";
import Select from "../../components/select/Select";

const StyledIcon = styled.img`
  width: 30px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

const WithdrawalWrapper = styled.div`
  padding: 15px;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 70%;
  }
  @media (min-width: ${constants.BREAKPOINTS.LARGE_DEVICES}) {
    width: 40%;
  }
`;

const WithdrawalTitle = styled.label`
  font-size: ${(props) => props.theme.typography.size.subheadline};
`;

const WithdrawalDetails = styled.table`
  width: 100%;
  margin: 2rem 0;
  tr {
    > td:last-of-type {
      text-align: right;
    }
    &:last-of-type {
      td {
        border-top: 1px solid ${(props) => props.theme.color.border};
        font-weight: 700;
      }
    }
    td {
      padding: 5px 0px;
      font-size: ${(props) => props.theme.typography.size.smallText};
    }
  }
`;

const WithdrawButton = styled(Button)`
  min-width: 150px;
`;

const Form = styled.form`
  margin-top: 2rem;
`;

const CustomSingleValue = (props) => {
  const { option, labelKey } = props;

  return (
    <components.SingleValue {...props.option}>
      <StyledFlex>
        {option.data.icon && <StyledIcon src={option.data.icon} alt="" />}
        {labelKey && option.data[labelKey]}
      </StyledFlex>
    </components.SingleValue>
  );
};

const CustomOption = (props) => {
  const { data, label } = props;

  return (
    <components.Option {...props}>
      <StyledFlex>
        {data.icon && <StyledIcon src={data.icon} alt="" />}
        {label}
      </StyledFlex>
    </components.Option>
  );
};

const initialValues = {
  amount: "",
  acc_number: "",
  bank_code: "",
  provider: {},
  customer_name: "",
  branch: "",
};

const Withdrawal = (props) => {
  const { fullAccount, accountLimits } = props;
  const { t, i18n } = useTranslation();
  const [bankCodeOptions, setBankCodeOptions] = useState(null);
  const [paymentLimit, setPaymentLimit] = useState(null);
  const [nucleusBankCodeOptions, setNucleusBankCodeOptions] = useState(null);
  const [tout, setTout] = useState(null);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalFee, setWithdrawalFee] = useState(0);
  const [newAmountData, setNetAmountData] = useState({});
  const [validatingForm, setValidatingForm] = useState(false);
  const queryClient = useQueryClient();
  const isChinese = i18n.language.startsWith("zh");
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const history = useHistory();
  const isWithdrawalUnavailable =
    userInfo.user_bo_status === constants.BO_STATUS.NEW ||
    userInfo.user_bo_status === constants.BO_STATUS.PENDING ||
    userInfo.user_bo_status === constants.BO_STATUS.REVIEWED;

  const withdrawalMutation = useMutation(
    (data) => {
      return api.mutations.withdrawalRequest(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};
        toast.success(
          t("MANAGE_MONEY.WITHDRAW.SUCCESS", {
            amount: new Intl.NumberFormat("en-EN", {
              style: "currency",
              currency: fullAccount.currency,
            }).format(result.withdraw_amount),
            account: result.account_id,
          })
        );
        resetForm();
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );
  const withdrawalZotaPay = useMutation(
    (data) => {
      return api.mutations.requestZotapayWithdrawal(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};

        if (!result.success) {
          const code = result.mt4_response.info.code;
          toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
        } else {
          toast.success(
            t("MANAGE_MONEY.WITHDRAW.SUCCESS", {
              amount: new Intl.NumberFormat("en-EN", {
                style: "currency",
                currency: fullAccount.currency,
              }).format(result.withdraw_amount),
              account: result.account_id,
            })
          );
          resetForm();
        }
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const withdrawalNacepay = useMutation(
    (data) => {
      return api.mutations.requestNacepayWithdrawal(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};

        if (!result.success) {
          const code = result.mt4_response.info.code;
          toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
        } else {
          toast.success(
            t("MANAGE_MONEY.WITHDRAW.SUCCESS", {
              amount: new Intl.NumberFormat("en-EN", {
                style: "currency",
                currency: fullAccount.currency,
              }).format(result.withdraw_amount),
              account: result.account_id,
            })
          );
          resetForm();
        }
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const withdrawalNucleus = useMutation(
    (data) => {
      return api.mutations.requestNucleusWithdrawal(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};
        if (!result.success) {
          const code = result.mt4_response.info.code;
          toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
        } else {
          toast.success(
            t("MANAGE_MONEY.WITHDRAW.SUCCESS", {
              amount: new Intl.NumberFormat("en-EN", {
                style: "currency",
                currency: fullAccount.currency,
              }).format(result.withdraw_amount),
              account: result.account_id,
            })
          );
          resetForm();
        }
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const resetForm = () => {
    formik.resetForm(initialValues);
    setWithdrawalAmount(0);
    setNetAmountData({});
    queryClient.invalidateQueries(constants.QUERY_NAMES.withdrawalTransactions);
    queryClient.invalidateQueries(constants.QUERY_NAMES.terminalLimits);
    queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
  };

  const onFormSubmit = (values) => {
    const payload = {
      amount: values.amount,
      terminal_id: fullAccount.terminal_id,
      solution: values.provider.value,
      currency: "CNY",
      bank_account_number: values.acc_number,
      bank_code: values.bank_code.value,
      endpoint_id: values.provider.endpoint_id,
      customer_name: values.customer_name,
      bank_account_name: values.customer_name,
    };

    if (values.provider.value === constants.PAYMENT_PROVIDERS.ZOTAPAY) {
      withdrawalZotaPay.mutate(payload);
      return;
    }

    if (values.provider.value === constants.PAYMENT_PROVIDERS.NACEPAY) {
      withdrawalNacepay.mutate({
        coin: values.coin.symbol,
        type: values.coin.type,
        address: values.address,
        amount: values.amount,
        terminal_id: payload.terminal_id,
        currency: fullAccount.currency,
      });
      return;
    }

    if (values.provider.value === constants.PAYMENT_PROVIDERS.NUCLEUS) {
      const additionalParams = {
        account_no: payload.bank_account_number,
        branch: values.branch,
        bank: payload.bank_code,
        state: "China",
        name: values.customer_name,
      };
      withdrawalNucleus.mutate({ ...payload, ...additionalParams });
      return;
    }

    withdrawalMutation.mutate(payload);
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
      .test({
        name: "min",
        message: t("MANAGE_MONEY.WITHDRAW.MIN_AMOUNT_ERROR", {
          amount: new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: fullAccount.currency,
          }).format((paymentLimit || accountLimits).min_withdraw_amount),
        }),
        test: (value) => {
          return (
            parseFloat(value) + withdrawalFee >= (paymentLimit || accountLimits).min_withdraw_amount
          );
        },
      })
      .test({
        name: "max",
        message: t("MANAGE_MONEY.WITHDRAW.MAX_AMOUNT_ERROR", {
          amount: new Intl.NumberFormat("en-EN", {
            style: "currency",
            currency: fullAccount.currency,
          }).format((paymentLimit || accountLimits).max_withdraw_amount - withdrawalFee),
        }),
        test: (value) => {
          return (
            parseFloat(value) + withdrawalFee <= (paymentLimit || accountLimits).max_withdraw_amount
          );
        },
      }),
    provider: Yup.object().required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD")),
    bank_code: Yup.object().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NACEPAY
        ? schema
        : schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
    ),
    acc_number: Yup.string().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NACEPAY
        ? schema
        : schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
    ),
    customer_name: Yup.string().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NACEPAY
        ? schema
        : schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
    ),
    coin: Yup.object().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NACEPAY
        ? schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
        : schema
    ),
    address: Yup.string().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NACEPAY
        ? schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
        : schema
    ),
    branch: Yup.string().when("provider", (provider, schema) =>
      provider?.value === constants.PAYMENT_PROVIDERS.NUCLEUS
        ? schema.required(t("MANAGE_MONEY.WITHDRAW.REQUIRED_FIELD"))
        : schema
    ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: onFormSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const selectedProvider = formik && formik.values && formik.values.provider.value;

  const [
    netAmountResponse,
    lookupsResponse,
    paymentGates,
    isCryptoAddressValid,
    nucleusBankList,
    paymentLimitQuery,
  ] = useQueries([
    {
      queryKey: constants.QUERY_NAMES.netAmount,
      queryFn: async () => {
        const response = await api.queries.getNetAmountForWithdrawal({
          amount: parseFloat(withdrawalAmount),
          currency_from: fullAccount.currency,
          currency_to: "CNY",
          destination: Object.values(constants.PAYMENT_PROVIDERS).includes(
            formik.values.provider.value
          )
            ? formik.values.provider.value
            : "mpsa",
          terminal_id: fullAccount.terminal_id,
        });

        setWithdrawalFee(response.fee_amount);

        return response;
      },
      enabled: !!withdrawalAmount,
    },
    {
      queryKey: [constants.QUERY_NAMES.lookups, "MPSA_CNY_BANK_CODES"],
      queryFn: () => {
        return api.queries.getLookups("MPSA_CNY_BANK_CODES", "Y");
      },
    },
    {
      queryKey: [constants.QUERY_NAMES.paymentGates],
      queryFn: () => {
        return api.queries.getPaymentGates("withdraw");
      },
    },
    {
      queryKey: [constants.QUERY_NAMES.isCryptoAddressValid],
      queryFn: () => {
        const params = {
          address: formik.values.address,
          currency:
            constants.DEFAULT_CRPYTO_VALIDATION_NETWORK[formik.values.coin.type] ||
            formik.values.coin?.symbol,
        };

        if (params.address && params.currency) {
          return api.queries.isCryptoAddressValid(params);
        }

        return {
          data: {
            data: {
              is_valid_address: true,
            },
          },
        };
      },
      staleTime: 0,
      onSuccess: (response) => {
        const isAddressValid = response.data.data && response.data.data.is_valid_address;

        if (!isAddressValid) {
          formik.setErrors({
            ...formik.errors,
            address: t("WALLET.INVALID_CRYPTO_WALLET_ADDRESS_V2", {
              currency: formik.values.coin.symbol,
            }),
          });
        } else {
          formik.validateForm(formik.values);
        }

        setValidatingForm(false);
      },
      onError: (error) => {
        setValidatingForm(false);
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
      enabled: false,
    },
    {
      queryKey: [constants.QUERY_NAMES.lookups, "NUCLEUS_CNY_BANK_CODES"],
      queryFn: () => {
        return api.queries.getLookups("NUCLEUS_CNY_BANK_CODES", "Y");
      },
    },
    {
      queryKey: [constants.QUERY_NAMES.terminalLimits, fullAccount?.terminal_id, selectedProvider],
      queryFn: () => {
        return api.queries.getTerminalLimits(fullAccount?.terminal_id, selectedProvider);
      },
      enabled: !!fullAccount,
    },
  ]);

  const paymentOptions = moneyHelper.mapPaymentOptions(paymentGates);
  formik.initialValues.provider = paymentOptions[0];

  useEffect(() => {
    if (paymentLimitQuery.data) {
      const data = paymentLimitQuery?.data?.data?.data;
      if (data.destination) {
        setPaymentLimit(data);
      }
    }
  }, [paymentLimit, paymentLimitQuery.data]);

  useEffect(() => {
    if (lookupsResponse.data) {
      const options = lookupsResponse.data.map((m) => ({
        value: m.code,
        label: isChinese ? m.description : m.meaning,
      }));

      setBankCodeOptions(options);
    }
  }, [lookupsResponse.data, isChinese]);

  useEffect(() => {
    if (nucleusBankList.data) {
      const options = nucleusBankList.data.map((m) => ({
        value: m.code,
        label: isChinese ? m.description : m.meaning,
      }));

      setNucleusBankCodeOptions(options);
    }
  }, [nucleusBankList.data, isChinese]);

  useEffect(() => {
    if (withdrawalAmount) {
      netAmountResponse.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalAmount, formik.values.provider]);

  useEffect(() => {
    if (netAmountResponse.data) {
      setNetAmountData(netAmountResponse.data);
    }
  }, [netAmountResponse.data]);

  const onAmountChange = (value) => {
    if (tout) {
      clearTimeout(tout);
    }

    setTout(setTimeout(() => setWithdrawalAmount(value), 300));
  };

  const modalOnSubmit = () => {
    history.push("/verification");
  };

  const handleAddressChange = debounce(async () => {
    isCryptoAddressValid.refetch();
  }, 400);

  const isNacePay = formik.values.provider.value === constants.PAYMENT_PROVIDERS.NACEPAY;
  const isNucleus = formik.values.provider.value === constants.PAYMENT_PROVIDERS.NUCLEUS;

  return (
    <Fragment>
      <WithdrawalWrapper>
        <WithdrawalTitle>{t("MANAGE_MONEY.WITHDRAW.TITLE")}</WithdrawalTitle>
        <Form onSubmit={formik.handleSubmit}>
          {!isNacePay && (
            <FormControl
              label={t("MANAGE_MONEY.ACCOUNT.CUSTOMER_NAME")}
              caption={(formik.touched.customer_name && formik.errors.customer_name) || ""}
              error={Boolean(formik.errors.customer_name && formik.touched.customer_name)}
              mb="1rem"
            >
              <Input
                placeholder="客户全名"
                name="customer_name"
                type="text"
                value={formik.values.customer_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.customer_name && formik.touched.customer_name)}
              />
            </FormControl>
          )}
          <FormControl
            label={t("MANAGE_MONEY.DEPOSIT.PROVIDER")}
            caption={(formik.touched.provider && formik.errors.provider) || ""}
            error={Boolean(formik.errors.provider && formik.touched.provider)}
            mb="1rem"
          >
            <Select
              name="provider"
              placeholder={t("MANAGE_MONEY.DEPOSIT.PROVIDER_PLACEHOLDER")}
              value={formik.values.provider}
              options={paymentOptions}
              onChange={(value) => formik.setFieldValue("provider", value)}
              getOptionLabel={(option) => t(option.label)}
            />
          </FormControl>
          {!isNacePay && (
            <FormControl
              label={t("MANAGE_MONEY.WITHDRAW.BANK_CODE")}
              caption={(formik.touched.bank_code && formik.errors.bank_code) || ""}
              error={Boolean(formik.errors.bank_code && formik.touched.bank_code)}
              mb="1rem"
            >
              <Select
                name="bank_code"
                placeholder={t("MANAGE_MONEY.DEPOSIT.PROVIDER_PLACEHOLDER")}
                value={formik.values.bank_code}
                options={isNucleus ? nucleusBankCodeOptions : bankCodeOptions}
                onChange={(value) => formik.setFieldValue("bank_code", value)}
              />
            </FormControl>
          )}
          {!isNacePay && (
            <FormControl
              label={t("MANAGE_MONEY.WITHDRAW.ACC_NUMBER")}
              caption={(formik.touched.acc_number && formik.errors.acc_number) || ""}
              error={Boolean(formik.errors.acc_number && formik.touched.acc_number)}
              mb="1rem"
            >
              <Number
                name="acc_number"
                value={formik.values.acc_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.amount && formik.touched.amount)}
              />
            </FormControl>
          )}
          <FormControl
            label={t("MANAGE_MONEY.ACCOUNT.AMOUNT")}
            caption={(formik.touched.amount && formik.errors.amount) || ""}
            error={Boolean(formik.errors.amount && formik.touched.amount)}
            mb="1rem"
          >
            <Number
              name="amount"
              value={formik.values.amount}
              isNumericString={true}
              onValueChange={(values) => {
                formik.setFieldValue("amount", values.floatValue);
                onAmountChange(values.floatValue);
              }}
              onBlur={formik.handleBlur}
              thousandSeparator
              flag={fullAccount.currency}
              error={Boolean(formik.errors.amount && formik.touched.amount)}
            />
          </FormControl>
          {isNacePay && (
            <FormControl
              label={t("MANAGE_MONEY.DEPOSIT.WALLET")}
              caption={(formik.touched.provider && formik.errors.provider) || ""}
              error={Boolean(formik.errors.provider && formik.touched.provider)}
              mb="1rem"
            >
              <Select
                name="coin"
                value={formik.values.coin}
                getOptionLabel={(option) => option.name}
                onChange={(value) => {
                  setValidatingForm(true);
                  formik.setFieldValue("coin", value);
                  handleAddressChange();
                }}
                options={constants.NACEPAY_WITHDRAW_WALLETS}
                components={{
                  Option: CustomOption,
                  SingleValue: (option) => CustomSingleValue({ option, labelKey: "name" }),
                }}
              />
            </FormControl>
          )}
          {isNacePay && (
            <FormControl
              label={t("MANAGE_MONEY.DEPOSIT.WALLET_ADDRESS")}
              caption={(formik.touched.address && formik.errors.address) || ""}
              error={Boolean(formik.errors.address && formik.touched.address)}
              mb="1rem"
            >
              <Input
                name="address"
                type="text"
                value={formik.values.address}
                onChange={(e) => {
                  setValidatingForm(true);
                  formik.setFieldValue("address", e.target.value);
                  formik.setTouched({ ...formik.touched, address: true });
                  handleAddressChange();
                }}
                error={Boolean(formik.errors.address && formik.touched.address)}
              />
            </FormControl>
          )}
          {isNucleus && (
            <FormControl
              label={t("MANAGE_MONEY.WITHDRAW.BRANCH_NAME")}
              caption={(formik.touched.branch && formik.errors.branch) || ""}
              error={Boolean(formik.errors.branch && formik.touched.branch)}
              mb="1rem"
            >
              <Input
                name="branch"
                type="text"
                value={formik.values.branch}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.branch && formik.touched.branch)}
              />
            </FormControl>
          )}
          <WithdrawalDetails>
            <tbody>
              <tr>
                <td>{t("MANAGE_MONEY.ACCOUNT.AMOUNT")}</td>
                <td>
                  <Amount
                    noColor
                    amount={formik.values.amount}
                    decimals={fullAccount.currency_digits}
                    symbol={fullAccount.currency_symbol}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("MANAGE_MONEY.WITHDRAW.AMOUNT_IN_CNY")}</td>
                <td>
                  <Amount
                    noColor
                    amount={newAmountData.net_amount}
                    decimals={fullAccount.currency_digits}
                    symbol="¥"
                  />
                </td>
              </tr>
              <tr>
                <td>{t("MANAGE_MONEY.WITHDRAW.FEE")}</td>
                <td>
                  <Amount
                    noColor
                    amount={newAmountData.fee_amount}
                    decimals={fullAccount.currency_digits}
                    symbol={fullAccount.currency_symbol}
                  />
                </td>
              </tr>
              <tr>
                <td>{t("MANAGE_MONEY.WITHDRAW.TOTAL")}</td>
                <td>
                  <Amount
                    noColor
                    amount={newAmountData.withdraw_amount}
                    decimals={fullAccount.currency_digits}
                    symbol={fullAccount.currency_symbol}
                  />
                </td>
              </tr>
            </tbody>
          </WithdrawalDetails>
          <WithdrawButton
            type="submit"
            disabled={
              withdrawalMutation.isLoading ||
              isWithdrawalUnavailable ||
              !formik.isValid ||
              validatingForm
            }
            isLoading={withdrawalMutation.isLoading}
          >
            <span>{t("MANAGE_MONEY.WITHDRAW.WITHDRAW")}</span>
          </WithdrawButton>
        </Form>
      </WithdrawalWrapper>
      <Modal
        isOpen={isWithdrawalUnavailable}
        hasClose={false}
        dismissOnClickOutside={false}
        dismissOnEsc={false}
      >
        <ModalHeader />
        <ModalBody>{t("MANAGE_MONEY.NEW_USER_MODAL_MESSAGE")}</ModalBody>
        <ModalFooter>
          <ModalButton onClick={modalOnSubmit}>{t("OK")}</ModalButton>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Withdrawal;
