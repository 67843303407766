import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ParagraphMedium, ParagraphSmall } from "@my-swipestox/components";
import { useIntercom } from "react-use-intercom";

import constants from "../../constants";

const Wrapper = styled.div`
  padding: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    grid-template-columns: auto 1fr;
  }
`;

const StyledParagraphSmall = styled(ParagraphSmall)`
  color: ${(props) => props.theme.color.primary};
  cursor: pointer;
`;

const Help = () => {
  const { t } = useTranslation();
  const { boot, show } = useIntercom();

  function handleOnClick() {
    boot();
    show();
  }

  return (
    <Wrapper>
      <ParagraphMedium mb="1rem">{t("HELP.CALL_US")}</ParagraphMedium>
      <Grid>
        <ParagraphSmall>{t("HELP.EMAIL")}</ParagraphSmall>
        <ParagraphSmall>
          <a href={`mailto:${constants.SERVICE_EMAIL}`}>{constants.SERVICE_EMAIL}</a>
        </ParagraphSmall>
        <ParagraphSmall>{t("HELP.LIVE_CHAT_SUPPORT")}</ParagraphSmall>
        <StyledParagraphSmall onClick={handleOnClick}>
          {t("HELP.START_CONVERSATION")}
        </StyledParagraphSmall>
      </Grid>
    </Wrapper>
  );
};

export default Help;
