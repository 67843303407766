import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueries } from "react-query";
import { NavLink, Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import api from "../../api";
import Amount from "../../components/amount/Amount";
import Loader from "../../components/loader/Loader";
import Select from "../../components/select/Select";
import constants from "../../constants";
import state from "../../state";
import TradingAccountShortDesc from "../lobby/TradingAccountShortDesc";
import optionComponents from "./AccountOption";
import Deposit from "./Deposit";
import History from "./History";
import InternalTransfer from "./InternalTransfer";
import Withdrawal from "./Withdrawal";

const ManageMoneyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    flex-direction: row;
    height: 100%;
    border-radius: 3px;
  }
`;

const Menu = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  margin-bottom: 20px;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    border-right: 1px solid ${(props) => props.theme.color.border};
    max-width: 300px;
    padding: 10px;
    border-bottom: none;
    margin-bottom: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: calc(100% - 300px);
  }
`;

const Navigation = styled.div`
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding: 0px 20px;
`;

const Content = styled.div`
  padding: 10px;
`;

const AccountDetails = styled.div`
  margin-top: 1.5rem;
  filter: ${(props) => (props.loading ? "blur(3px)" : "none")};
`;

const AmountContainer = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

const AmountWrapper = styled.div``;

const AmountText = styled.div`
  color: ${(props) => props.theme.color.contentSecondary};
  font-size: ${(props) => props.theme.typography.size.smallText};
`;

const BalanceAmount = styled(Amount)`
  font-size: ${(props) => props.theme.typography.size.headline};
  font-weight: 300;
`;

const TradingAccountShortDescCustom = styled(TradingAccountShortDesc)`
  margin-bottom: 1.5rem;
`;

const SubHeaderNavigation = styled.div`
  display: flex;
  height: 100%;
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color.contentPrimary};
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 1rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &.active {
    color: ${(props) => props.theme.color.primary};
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.color.primary};
  }
  &:hover {
    color: ${(props) => props.theme.color.primary}7f;
    box-shadow: inset 0 -2px 0 ${(props) => props.theme.color.primary};
  }
`;

const ManageMoney = () => {
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [fullAccount, setFullAccount] = useState(null);
  const userInfo = state.useUserInfo((state) => state.userInfo);
  const { url } = useRouteMatch();
  const [tradingAccountsQuery, terminalLimitsQuery] = useQueries([
    {
      queryKey: [constants.QUERY_NAMES.tradingAccounts, userInfo.user_bo_status],
      queryFn: api.queries.getTradingAccounts,
    },
    {
      queryKey: [constants.QUERY_NAMES.terminalLimits, fullAccount?.terminal_id],
      queryFn: () => {
        return api.queries.getTerminalLimits(fullAccount?.terminal_id);
      },
      enabled: !!fullAccount,
    },
  ]);

  const accounts = useMemo(() => {
    return (
      tradingAccountsQuery.data?.data?.data.filter(
        (f) => f.type === constants.TERMINAL_TYPES.R.value
      ) || []
    );
  }, [tradingAccountsQuery.data?.data?.data]);

  const accountLimits = useMemo(() => {
    return terminalLimitsQuery.data?.data?.data || {};
  }, [terminalLimitsQuery.data?.data?.data]);

  useEffect(() => {
    if (!selectedAccount && accounts.length) {
      setSelectedAccount({
        value: accounts[0]?.login,
        label: accounts[0]?.custom_name,
        currency: accounts[0]?.currency,
      });
    }
  }, [accounts, selectedAccount]);

  useEffect(() => {
    if (selectedAccount) {
      const account = accounts.find((f) => f.login === selectedAccount.value);
      setFullAccount(account);
    }
  }, [selectedAccount, accounts]);

  const onAccountChange = (value) => {
    setSelectedAccount(value);
  };

  const accountOptions = accounts.map((i) => ({
    value: i.login,
    label: i.custom_name,
    currency: i.currency,
    currencySymbol: i.currency_symbol,
    digits: i.currency_digits,
    type: i.type,
    balance: i.equity,
    accountNumber: i.login,
  }));

  if (tradingAccountsQuery.isLoading) {
    return <Loader text="MANAGE_MONEY.LOADING_CONTENT" />;
  }

  if (!fullAccount) {
    return null;
  }

  return (
    <ManageMoneyWrapper>
      <Menu>
        <Select
          placeholder={t("MANAGE_MONEY.ACCOUNT.PLACEHOLDER")}
          value={selectedAccount}
          options={accountOptions}
          onChange={onAccountChange}
          components={{
            Option: optionComponents.AccountOption,
            SingleValue: optionComponents.SingleAccountOption,
          }}
        />
        <AccountDetails loading={terminalLimitsQuery.isLoading ? 1 : undefined}>
          <TradingAccountShortDescCustom
            accountNumber={fullAccount.login}
            currency={fullAccount.currency}
            name={fullAccount.custom_name}
          />
          <BalanceAmount
            value={fullAccount.equity}
            symbol={fullAccount.currency_symbol}
            decimals={fullAccount.currency_digits}
          />
          <AmountContainer>
            <AmountWrapper>
              <AmountText>{t("MANAGE_MONEY.ACCOUNT.AMOUNT")}</AmountText>
              <Amount
                amount={fullAccount.balance}
                symbol={fullAccount.currency_symbol}
                decimals={fullAccount.currency_digits}
              />
            </AmountWrapper>
            <AmountWrapper>
              <AmountText>{t("MANAGE_MONEY.ACCOUNT.WITHDRAWABLE")}</AmountText>
              <Amount
                amount={accountLimits.max_withdraw_amount}
                symbol={fullAccount.currency_symbol}
                decimals={fullAccount.currency_digits}
              />
            </AmountWrapper>
          </AmountContainer>
        </AccountDetails>
      </Menu>
      <ContentWrapper>
        <Navigation>
          <SubHeaderNavigation>
            <StyledNavLink to={`${url}/deposit`}>
              <span>{t("MANAGE_MONEY.NAV.DEPOSIT")}</span>
            </StyledNavLink>
            <StyledNavLink to={`${url}/withdraw`}>
              <span>{t("MANAGE_MONEY.NAV.WITHDRAW")}</span>
            </StyledNavLink>
            <StyledNavLink to={`${url}/transfer`}>
              <span>{t("MANAGE_MONEY.NAV.TRANSFER")}</span>
            </StyledNavLink>
            <StyledNavLink to={`${url}/history`}>
              <span>{t("MANAGE_MONEY.NAV.HISTORY")}</span>
            </StyledNavLink>
          </SubHeaderNavigation>
        </Navigation>
        <Content>
          <Switch>
            <Route
              path={`${url}/deposit`}
              render={(props) => <Deposit fullAccount={fullAccount} {...props} />}
            />
            <Route
              path={`${url}/withdraw`}
              render={(props) => (
                <Withdrawal fullAccount={fullAccount} accountLimits={accountLimits} {...props} />
              )}
            />
            <Route
              path={`${url}/transfer`}
              render={(props) => <InternalTransfer fullAccount={fullAccount} setFullAccount={setFullAccount} {...props} />}
            />
            <Route
              path={`${url}/history`}
              render={(props) => <History fullAccount={fullAccount} {...props} />}
            />
          </Switch>
        </Content>
      </ContentWrapper>
    </ManageMoneyWrapper>
  );
};

export default ManageMoney;
