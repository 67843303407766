import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { useTranslation, Trans } from "react-i18next";
import { ChevronLeft, AlertCircle, CheckCircle } from "react-feather";
import { toast } from "react-toastify";
import {
  Input,
  FormControl,
  Button,
  HeadingSmall,
  ParagraphSmall,
  HeadingXSmall,
  Modal,
  ModalFooter,
  ModalBody,
  ModalButton,
  ModalHeader,
  Spinner,
} from "@my-swipestox/components";

import constants from "../../../constants";
import api from "../../../api";
import state from "../../../state";

const Wrapper = styled.div`
  padding: 1rem;
`;

const StyledForm = styled(Form)`
  display: flex;
  margin-bottom: 1rem;
  align-contenet: space-between;
`;

const InputWrapper = styled.div`
  width: 250px;
  display: block;
  margin-right: 0.5rem;
`;

const AuthCodeWrapper = styled.div`
  margin: 1rem 0;
`;

const LightText = styled(ParagraphSmall)`
  color: #7f93bc;
`;

const DisclaimerText = styled(LightText)`
  margin-top: 2rem;
`;

const AuthenticatorAppsText = styled.span`
  color: ${(props) => props.theme.color.primary};
  font-weight: 500;
`;

const ConnectYourAPPHeading = styled(HeadingSmall)`
  font-weight: 500;
`;

const BackButton = styled(Button)`
  background: transparent;
  border: 0;
  padding: 0;
  color: ${(props) => props.theme.color.primary};
`;

const SpinnerStyled = styled(Spinner)`
  margin: 1rem auto;
`;

const AlertIcon = styled(AlertCircle)`
  color: #ee3142;
  margin-right: 10px;
`;

const CheckIcon = styled(CheckCircle)`
  color: #3dc752;
  margin-right: 10px;
`;

const ModalTitle = styled(HeadingXSmall)`
  display: flex;
  align-items: center;
`;

const MFAAuthenticator = (props) => {
  const { mfaEnabled, setSecurityStep } = props;

  const { t } = useTranslation();
  const [qrImage, setQrImage] = useState();
  const [secret, setSecret] = useState();
  const [modalData, setModalData] = useState({ isOpen: false, type: "default", message: "" });

  const userInfo = state.useUserInfo((state) => state.userInfo);
  const setUserInfo = state.useUserInfo((state) => state.setUserInfo);
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const setRefreshTokenExpireTime = state.useRefreshToken(
    (state) => state.setRefreshTokenExpireTime
  );

  // Set Tokens After Enabling 2FA
  const setTokens = (data) => {
    setIsLoggedIn(true, data.token);
    setRefreshToken(data.refreshToken);
    setXsrf(data.xsrf);
    setRefreshTokenExpireTime(data.token_expiration);
  };

  const generateMFAKeys = useQuery(
    constants.QUERY_NAMES.getMFAKeys,
    () => api.queries.getMFAKeys(),
    {
      enabled: !mfaEnabled,
      onSuccess: (data) => {
        setQrImage(data.qr_image);
        setSecret(data.secret_key);
      },
    }
  );

  const enableMFA = useMutation(api.mutations.enableMFA, {
    onSuccess: (data) => {
      setUserInfo({ ...userInfo, mfa_enabled: true });
      setTokens(data.data.info);
      setSecurityStep(constants.MFA_STEPS.RECOVERY_CODES);
      toast.success(t("SECURITY.TWO_FACTOR_AUTHENTICATION_ENABLED"));
    },

    onError: (error) => {
      const errorCode = error?.response?.data?.info?.errorCode || null;
      const errorTextMessage = constants.MFA_STATUS_CODES[errorCode] || "ERRORS.DEFAULT";
      openModal(constants.MODAL_TYPES.ERROR, errorTextMessage);
    },
  });

  const disableMFA = useMutation(api.mutations.disableMFA, {
    onSuccess: (data) => {
      setUserInfo({ ...userInfo, mfa_enabled: false });
      setSecurityStep(constants.MFA_STEPS.INITIAL);
      setTokens(data.data.info);
      toast.success(t("SECURITY.TWO_FACTOR_AUTHENTICATION_DISABLED"));
    },
    onError: (error) => {
      const errorCode = error?.response?.data?.info?.errorCode || null;
      const errorTextMessage = constants.MFA_STATUS_CODES[errorCode] || "ERRORS.DEFAULT";
      openModal(constants.MODAL_TYPES.ERROR, errorTextMessage);
    },
  });

  const openModal = (type, message) => {
    const data = {
      type,
      message,
      isOpen: true,
    };
    setModalData(data);
  };

  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };

  const handleFormSubmit = (values) => {
    const data = {
      code: values.code,
    };

    if (mfaEnabled) {
      disableMFA.mutate(data);
    } else {
      data.secret_key = secret;

      enableMFA.mutate(data);
    }
  };

  const goBack = () => {
    setSecret();
    setQrImage();
    setSecurityStep(constants.MFA_STEPS.INITIAL);
    generateMFAKeys.remove();
  };

  return (
    <Wrapper>
      <BackButton size="large" onClick={goBack}>
        <ChevronLeft />
        {t("SECURITY.BACK")}
      </BackButton>
      <ConnectYourAPPHeading mt="2rem" mb="1.5rem">
        {t(
          mfaEnabled
            ? "SECURITY.DISABLE_TWO_FACTOR_AUTHENTICATION"
            : "SECURITY.ENABLE_TWO_FACTOR_AUTHENTICATION"
        )}
      </ConnectYourAPPHeading>
      <ConnectYourAPPHeading as={HeadingXSmall} mb="1rem">
        {t(mfaEnabled ? "SECURITY.SECURITY_VERIFICATION" : "SECURITY.CONNECT_YOUR_APP")}
      </ConnectYourAPPHeading>
      <LightText mb="0.5rem">
        <Trans
          defaults={
            mfaEnabled ? "SECURITY.ENTER_THE_6_DIGIT_CODE" : "SECURITY.CONNECT_YOUR_APP_DESCRIPTION"
          }
          values={{ n: "Google Authenticator", m: "Duo" }}
          components={[<AuthenticatorAppsText />]}
        />
      </LightText>
      <div>
        {!mfaEnabled &&
          (generateMFAKeys.isLoading ? <SpinnerStyled /> : <img src={qrImage} alt="QR Code" />)}
      </div>
      <Formik
        initialValues={{ code: "" }}
        onSubmit={handleFormSubmit}
        validationSchema={object().shape({
          code: string()
            .required(t("SECURITY.AUTHENTICATION_CODE_INVALID"))
            .matches(
              mfaEnabled ? constants.MFA_CODE_OR_RECOVERY_CRITERIA : constants.MFA_CODE_CRITERIA,
              t(
                mfaEnabled
                  ? "SECURITY.MFA_CODE_FORMAT_ERROR"
                  : "SECURITY.AUTHENTICATION_CODE_DIGIT_ERROR"
              )
            ),
        })}
      >
        {({ errors }) => (
          <StyledForm>
            <InputWrapper>
              <FormControl error={errors.code} caption={errors.code || ""}>
                <Field
                  as={Input}
                  name="code"
                  placeholder={t("SECURITY.ENTER_AUTHENTICATION_CODE")}
                />
              </FormControl>
            </InputWrapper>
            <FormControl>
              <Button type="submit" isLoading={enableMFA.isLoading || disableMFA.isLoading}>
                {t("SECURITY.SUBMIT")}
              </Button>
            </FormControl>
          </StyledForm>
        )}
      </Formik>
      {!mfaEnabled && (
        <AuthCodeWrapper>
          <ParagraphSmall mt="1rem" mb="0.5rem">
            {t("SECURITY.BACKUP_2_STEP_KEY")}
          </ParagraphSmall>
          <ParagraphSmall>{generateMFAKeys.isLoading ? <SpinnerStyled /> : secret}</ParagraphSmall>
        </AuthCodeWrapper>
      )}
      <DisclaimerText>
        {t(mfaEnabled ? "SECURITY.MFA_DISABLE_DISCLAIMER" : "SECURITY.MFA_DISCLAIMER")}
      </DisclaimerText>

      <Modal role="alertdialog" isOpen={modalData.isOpen} onClose={closeModal}>
        <ModalHeader>
          {modalData.type === constants.MODAL_TYPES.ERROR ? (
            <ModalTitle>
              <AlertIcon /> {t("ERROR")}
            </ModalTitle>
          ) : (
            <ModalTitle>
              <CheckIcon /> {t("SUCCESS")}
            </ModalTitle>
          )}
        </ModalHeader>
        <ModalBody>
          <LightText>{t(modalData.message)}</LightText>
        </ModalBody>
        <ModalFooter>
          <ModalButton size="compact" onClick={closeModal}>
            {t("OK")}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

export default MFAAuthenticator;
