import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";

const CustomIframeWrapper = styled.div`
  height: 100%;
`;

const CustomIframe = styled.iframe`
  width: 100%;
  height: calc(100% - 93px);
  border: 0px;
`;

const BackButtonStripe = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
`;

const Title = styled.label`
  font-size: ${(props) => props.theme.typography.size.headline};
  margin-left: 2rem;
`;

const BackButton = styled.a`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.primary};
  cursor: pointer;
`;

const DepositIframe = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const onBackClick = () => {
    history.push("/manage-money/deposit");
  };

  return (
    <CustomIframeWrapper>
      <BackButtonStripe>
        <BackButton onClick={onBackClick}>
          <ChevronLeft />
          {t("MANAGE_MONEY.IFRAME.BACK")}
        </BackButton>
        <Title>
          {t("MANAGE_MONEY.IFRAME.FUND_WITH", {
            provider: location.state.provider,
          })}
        </Title>
      </BackButtonStripe>
      <CustomIframe srcDoc={location.state.html} title="deposit" />
    </CustomIframeWrapper>
  );
};

export default DepositIframe;
