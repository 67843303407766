import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

import existingResources from "./resources";
import constants from "./constants";

const customParse =  (data, inLang) => {
  const resources = JSON.parse(data).data;
  const lang = inLang === 'en' ? inLang : "zn";

  // Merge the existing resources with the API response
  const mergedResources = {
    ...existingResources,
    [lang]: {
      translation: {
      ...existingResources[lang].translation,
      ...resources,
      }

    },
  };

  return mergedResources[lang].translation;
};

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: constants.IS_PROD_ENV ? 'zh-hans' : 'en',
    keySeparator: false,
    returnEmptyString: false,
    debug: constants.IS_PROD_ENV ? false : true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [
        Backend,
        resourcesToBackend(existingResources)
      ],
      backendOptions: [{
        loadPath: ([lng]) => `${constants.API_URL}/translations?lang=${lng.toLowerCase()}`,
        // Keeping this only in DEV so that it can be tested as its own task rather than NA-23622
        parse: constants.IS_PROD_ENV ? data => JSON.parse(data).data : customParse,
        customHeaders: {
          platform: 'web-china',
        }
      }]
    },
  });

export default i18n;
