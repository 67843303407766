import constants from "../constants";

// Check if item exists in localStorage
const ifItemExists = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key) || "");
  } catch {
    return false;
  }
};

//    USER TOKENS
function setUserTokens(tokens) {
  for (const token in constants.USER_TOKENS) {
    const userToken = constants.USER_TOKENS[token];
    window.localStorage.setItem(userToken.cacheKey, tokens[userToken.name] || "");
  }
}

function clearUserTokens() {
  for (const token in constants.USER_TOKENS) {
    window.localStorage.removeItem(constants.USER_TOKENS[token].cacheKey);
  }
}

//  Token
function getToken() {
  return window.localStorage.getItem(constants.USER_TOKENS.TOKEN.cacheKey);
}

function setToken(token) {
  window.localStorage.setItem(constants.USER_TOKENS.TOKEN.cacheKey, token);
}
function clearToken() {
  window.localStorage.removeItem(constants.USER_TOKENS.TOKEN.cacheKey);
}

//  Xsrf
function getXsrf() {
  return window.localStorage.getItem(constants.USER_TOKENS.XSRF.cacheKey);
}
function setXsrf(xsrf) {
  window.localStorage.setItem(constants.USER_TOKENS.XSRF.cacheKey, xsrf);
}
function clearXsrf() {
  window.localStorage.removeItem(constants.USER_TOKENS.XSRF.cacheKey);
}

// Refresh token
function getRefreshToken() {
  return window.localStorage.getItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey);
}
function setRefreshToken(refreshToken) {
  window.localStorage.setItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey, refreshToken);
}
function clearRefreshToken() {
  window.localStorage.removeItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey);
}

// Refresh token exp time
function getRefreshTokenExpTime() {
  return window.localStorage.getItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey);
}
function setRefreshTokenExpTime(expTime) {
  window.localStorage.setItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey, expTime);
}
function clearRefreshTokenExpTime() {
  window.localStorage.removeItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey);
}

// User info
function getUserInfo() {
  return ifItemExists(constants.CACHED_ITEMS.USER_INFO) || {};
}
function setUserInfo(userInfo) {
  window.localStorage.setItem(constants.CACHED_ITEMS.USER_INFO, JSON.stringify(userInfo));
}
function clearUserInfo() {
  window.localStorage.removeItem(constants.CACHED_ITEMS.USER_INFO);
}

//   DARK MODE
function getDarkMode() {
  return ifItemExists(constants.CACHED_ITEMS.DARK_MODE) || false;
}
function setDarkMode(darkMode) {
  window.localStorage.setItem(constants.CACHED_ITEMS.DARK_MODE, darkMode);
}
function clearDarkMode() {
  window.localStorage.removeItem(constants.CACHED_ITEMS.DARK_MODE);
}

//   RETRY COUNT

function getRetryCount() {
  return window.localStorage.getItem(constants.CACHED_ITEMS.RETRY_COUNT);
}
function setRetryCount(count_nr) {
  window.localStorage.setItem(constants.CACHED_ITEMS.RETRY_COUNT, JSON.stringify(count_nr));
}
function clearRetryCount() {
  window.localStorage.removeItem(constants.CACHED_ITEMS.RETRY_COUNT);
}

//   FINGERPRINT
function setFingerprint(fingerprint) {
  window.localStorage.setItem(constants.CACHED_ITEMS.FINGERPRINT, fingerprint);
}

function clearFingerprint() {
  window.localStorage.removeItem(constants.CACHED_ITEMS.FINGERPRINT);
}

function getFingerprint() {
  return window.localStorage.getItem(constants.CACHED_ITEMS.FINGERPRINT);
}

const cacheHelper = {
  setUserTokens,
  clearUserTokens,
  getToken,
  setToken,
  clearToken,
  getXsrf,
  setXsrf,
  clearXsrf,
  getRefreshToken,
  setRefreshToken,
  clearRefreshToken,
  getRefreshTokenExpTime,
  setRefreshTokenExpTime,
  clearRefreshTokenExpTime,
  setRetryCount,
  getRetryCount,
  clearRetryCount,
  setFingerprint,
  clearFingerprint,
  getFingerprint,
  getUserInfo,
  setUserInfo,
  clearUserInfo,
  getDarkMode,
  setDarkMode,
  clearDarkMode,
};

export default cacheHelper;
