import React from "react";
import { Info, AlertCircle, HelpCircle, XCircle, CheckCircle } from "react-feather";
import styled from "styled-components";

import { INFO_BLOCK_TYPE } from "./info-block-constants";

const InfoIcon = styled(Info)`
  color: ${(props) => props.theme.color.primary};
`;

const CheckCircleIcon = styled(CheckCircle)`
  color: ${(props) => props.theme.color.primary};
`;

const ErrorIcon = styled(XCircle)`
  color: ${(props) => props.theme.color.negative};
`;

const WarningIcon = styled(AlertCircle)`
  color: ${(props) => props.theme.color.negative};
`;

const DefaultIcon = styled(HelpCircle)`
  color: ${(props) => props.theme.color.warning};
`;

const InfoBlock = (props) => {
  const { title, description, type = INFO_BLOCK_TYPE.DEFAULT, showContent = true } = props;

  let icon;

  switch (type) {
    case INFO_BLOCK_TYPE.INFO:
      icon = <InfoIcon />;
      break;
    case INFO_BLOCK_TYPE.SUCCESS:
      icon = <CheckCircleIcon />;
      break;
    case INFO_BLOCK_TYPE.ERROR:
      icon = <ErrorIcon />;
      break;
    case INFO_BLOCK_TYPE.WARNING:
      icon = <WarningIcon />;
      break;
    default:
      icon = <DefaultIcon />;
      break;
  }

  return (
    <div>
      <div>{icon}</div>
      {showContent && (
        <div>
          {title && <h4>{title}</h4>}
          {description && <p>{description}</p>}
        </div>
      )}
    </div>
  );
};

export default InfoBlock;
