import styled from "styled-components";
import PropTypes from "prop-types";
import { MoreHorizontal } from "react-feather";
import { StatefulPopover } from "@malcodeman/react-popover";
import constants from "../../constants";
import Amount from "../../components/amount/Amount";
import LobbyItemContextMenu from "./LobyItemContextMenu";
import { useTranslation } from "react-i18next";
import TradingAccountShortDesc from "./TradingAccountShortDesc";

const LobbyItemWrapper = styled.div`
  border: 1px solid;
  border-color: ${(props) => props.theme.color.border};
  border-radius: 3px;
  padding: 15px;
  background: ${(props) => props.theme.color.backgroundSecondary};
  box-shadow: 0px 2px 8px ${(props) => props.theme.color.shadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    background-color: ${(props) => props.theme.color.backgroundPrimary};
  }
`;

const LobbyItemTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const MoreHorizontalCustom = styled(MoreHorizontal)`
  color: ${(props) => props.theme.color.contentSecondary};
  height: 1rem;
  cursor: pointer;
`;

const LobbyItemBalanceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`;

const CustomFlag = styled.span`
  color: #fff;
  font-size: ${(props) => props.theme.typography.size.extraSmallText};
  padding: 3px 7px;
  border-radius: 0.8rem;
  margin-right: 5px;
  text-transform: uppercase;
`;

const MT4Flag = styled(CustomFlag)`
  background-color: ${(props) => props.theme.color.primary};
`;
const MT5Flag = styled(CustomFlag)`
  background-color: #ffbf3d;
`;
const Live = styled(CustomFlag)`
  background-color: ${(props) => props.theme.color.positive};
`;
const Demo = styled(CustomFlag)`
  background-color: #ccc;
`;
const Locked = styled(CustomFlag)`
  background-color: ${(props) => props.theme.color.negative};
`;

const LobbyItem = (props) => {
  const {
    item,
    setIsModalOpen,
    setActiveItem,
    setIsChangePasswordModalOpen,
    setIsShowPasswordModalOpen,
  } = props;
  const { t } = useTranslation();

  return (
    <LobbyItemWrapper>
      <LobbyItemTitleWrapper>
        <TradingAccountShortDesc
          name={item.custom_name}
          leverage={item.leverage}
          currency={item.currency}
          accountNumber={item.login}
        />
        <StatefulPopover
          placement="bottom-end"
          content={() => (
            <div>
              <LobbyItemContextMenu
                item={item}
                setIsModalOpen={setIsModalOpen}
                setIsChangePasswordModalOpen={setIsChangePasswordModalOpen}
                setActiveItem={setActiveItem}
                setIsShowPasswordModalOpen={setIsShowPasswordModalOpen}
              />
            </div>
          )}
        >
          <MoreHorizontalCustom />
        </StatefulPopover>
      </LobbyItemTitleWrapper>
      <LobbyItemBalanceWrapper>
        <Amount
          amount={item.balance}
          decimals={item.currency_digits}
          symbol={item.currency_symbol}
        />
        <div>
          {item.status === constants.TERMINAL_STATUSES.READ_ONLY && (
            <Locked>{t("LOBBY.ACCOUNT_LOCKED")}</Locked>
          )}
          {constants.MT4_BROKER_SERVER_IDS.indexOf(item.broker_server_id) >
            -1 && <MT4Flag>{constants.MT_VERSIONS.MT4.NAME}</MT4Flag>}
          {constants.MT5_BROKER_SERVER_IDS.indexOf(item.broker_server_id) >
            -1 && <MT5Flag>{constants.MT_VERSIONS.MT5.NAME}</MT5Flag>}
          {item.type === constants.TERMINAL_TYPES.R.value && (
            <Live>{t("LOBBY.ACCOUNT_LIVE")}</Live>
          )}
          {item.type === constants.TERMINAL_TYPES.D.value && (
            <Demo>{t("LOBBY.ACCOUNT_DEMO")}</Demo>
          )}
        </div>
      </LobbyItemBalanceWrapper>
    </LobbyItemWrapper>
  );
};

LobbyItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default LobbyItem;
