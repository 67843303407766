import PropTypes from "prop-types";
import styled from "styled-components";
import constants from "../../constants";

const CurrencyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CurrencyImage = styled.img`
  width: 2.1rem;
  margin-right: 10px;
`;

const CurrencyDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrencyName = styled.label`
  font-size: ${(props) => props.theme.typography.size.normalText};
`;

const CurrencyCode = styled.span`
  font-size: ${(props) => props.theme.typography.size.smallText};
  color: ${(props) => props.theme.color.contentSecondary};
`;

const Currency = (props) => {
  const { currencyCode, currencyName } = props;

  return (
    <CurrencyWrapper>
      <CurrencyImage
        src={`${constants.WALLET_S3_CONTENT}/${currencyCode.toLowerCase()}.png`}
        alt={currencyCode}
      />
      <CurrencyDetails>
        <CurrencyName>{currencyName}</CurrencyName>
        <CurrencyCode>{currencyCode}</CurrencyCode>
      </CurrencyDetails>
    </CurrencyWrapper>
  );
};

Currency.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
};

export default Currency;
