import { useTranslation } from "react-i18next";
import styled from "styled-components";

import constants from "../../constants";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Image = styled.img`
  max-width: 300px;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.typography.size.headline};
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 1rem;
`;

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Image src={`${constants.IMAGES_URL}/not-found.svg`} alt="404 - not found" />
      <Title className="not-found__title">{t("NOT_FOUND.TITLE")}</Title>
      <Description className="not-found__description">{t("NOT_FOUND.DESCRIPTION")}</Description>
    </Wrapper>
  );
};

export default NotFound;
