import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button, Input, FormControl } from "@my-swipestox/components";
import { useMutation } from "react-query";
import get from "lodash/get";

import constants from "../../constants";
import api from "../../api";
import state from "../../state";
import UnauthorizedHeader from "../header/UnauthorizedHeader";
import cacheHelper from "../../helpers/cacheHelper";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Content = styled.div`
  height: inherit;
  width: inherit;
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background: ${(props) => props.theme.color.backgroundPrimary};
`;

const FormWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.color.backgroundSecondary};
  min-height: 460px;
  max-width: 500px;
  width: 100%;
  height: calc(100% - 60px);
  border: 0;

  @media screen and (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    border: 1px solid ${(props) => props.theme.color.border};
    border-radius: 3px;
    height: 540px;
    margin-top: -65px;
    align-self: center;
  }
`;

const MfaForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  width: 415px;
  justify-content: space-between;
  text-align: left;
`;

const ActionButtons = styled(Button)`
  margin-top: 15px;
  &:disabled {
    opacity: 0.4;
    background: ${(props) => props.theme.color.primary};
    color: white !important;
  }
  &:first-child {
    color: white !important;
  }
  &:last-child {
    background: transparent;
    border: none;
    color: ${(props) => props.theme.color.primary};
  }
`;

const Subtitle = styled.h2`
  font-weight: 200;
  margin-bottom: 1.5rem;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
  font-weight: 500;
`;

const ContentText = styled.p`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  font-weight: 200;
  padding: 10px;
  text-align: center;
`;

const ContentTextLight = styled(ContentText)`
  color: #7f93bc;
`;

const MFAForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();

  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setXsrf = state.useCrossSite((state) => state.setXsrf);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const setRefreshTokenExpireTime = state.useRefreshToken(
    (state) => state.setRefreshTokenExpireTime
  );

  //  Set tokens on MFA Enable / Disable
  const setTokens = (data) => {
    setIsLoggedIn(true, data.token);
    setRefreshToken(data.refreshToken);
    setXsrf(data.xsrf);
    setRefreshTokenExpireTime(data.token_expiration);
  };

  const loginUser = (data) => {
    setTokens(data.data.info);
    history.push("/");
  };

  const handleGoBackClick = () => {
    setIsLoggedIn(false);
    cacheHelper.clearUserTokens();
    history.push("/");
  };

  const formatErrorMessage = (error) => {
    const errorCode = get(error, "response.data.info.errorCode");
    const errorTextMessage = constants.MFA_STATUS_CODES[errorCode]
      ? t(constants.MFA_STATUS_CODES[errorCode])
      : t("ERRORS.DEFAULT");
    setErrorMessage(errorTextMessage);
  };

  const loginWithCode = useMutation(api.mutations.validateMFA, {
    enable: false,
    onSuccess: (data) => loginUser(data),
    onError: (data) => {
      formatErrorMessage(data);
    },
  });

  const loginWithRecovery = useMutation(api.mutations.validateMFARecovery, {
    enable: false,
    onSuccess: (data) => loginUser(data),
    onError: (data) => {
      formatErrorMessage(data);
    },
  });

  const handleFormSubmit = (values) => {
    const code = values.mfa_code.toString();
    if (values.mfa_code.length === constants.MFA_CODE_LENGTH && !isNaN(values.mfa_code)) {
      loginWithCode.mutate({ code });
    } else if (values.mfa_code.length > constants.MFA_CODE_LENGTH) {
      loginWithRecovery.mutate(code);
    }
  };

  return (
    <Wrapper>
      <UnauthorizedHeader />
      <Content>
        <FormWrapper>
          <MfaForm>
            <Formik
              initialValues={{ mfa_code: "" }}
              validationSchema={object().shape({
                mfa_code: string()
                  .required(t("MFA.MFA_CODE_REQUIRED"))
                  .min(
                    constants.MFA_CODE_LENGTH,
                    t("MFA.SHORT_CODE", { length: constants.MFA_CODE_LENGTH })
                  )
                  .max(
                    constants.MFA_RECOVERY_CODE_LENGTH,
                    t("MFA.LONG_CODE", { length: constants.MFA_RECOVERY_CODE_LENGTH })
                  ),
              })}
              onSubmit={handleFormSubmit}
            >
              {({ values, touched, errors }) => (
                <Form>
                  <Subtitle>{t("MFA.TITLE")}</Subtitle>
                  <ContentText>{t("MFA.DESCRIPTION")}</ContentText>
                  <FormControl
                    error={errors.mfa_code || loginWithCode.error || loginWithRecovery.error}
                    caption={errors.mfa_code || errorMessage || ""}
                  >
                    <Field
                      as={Input}
                      name="mfa_code"
                      touched={touched.mfa_code}
                      error={errors.mfa_code}
                      placeholder={t("MFA.LOGIN_OR_RECOVERY_CODE")}
                      autoFocus={true}
                      inputProps={{
                        placeholder: t("MFA.LOGIN_OR_RECOVERY_CODE"),
                        disabled: loginWithCode.isLoading || loginWithRecovery.isLoading,
                      }}
                    />
                  </FormControl>
                  <ActionButtons
                    isLoading={loginWithCode.isLoading || loginWithRecovery.isLoading}
                    shouldFitContainer
                    size="default"
                    disabled={
                      loginWithCode.isLoading ||
                      loginWithRecovery.isLoading ||
                      values.mfa_code.length < constants.MFA_CODE_LENGTH ||
                      values.mfa_code.length > constants.MFA_RECOVERY_CODE_LENGTH
                    }
                    type="submit"
                  >
                    {t("MFA.CONTINUE")}
                  </ActionButtons>
                  <ContentTextLight>{t("MFA.RECOVERY_DESCRIPTION")}</ContentTextLight>
                </Form>
              )}
            </Formik>
            <ActionButtons onClick={handleGoBackClick}>{t("MFA.GO_BACK")}</ActionButtons>
          </MfaForm>
        </FormWrapper>
      </Content>
    </Wrapper>
  );
};

export default MFAForm;
