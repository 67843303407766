import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ChevronLeft } from "react-feather";
import { toast } from "react-toastify";
import { Button, Spinner } from "@my-swipestox/components";

import constants from "../../../constants";
import api from "../../../api";
import utils from "../../../utils";

const Wrapper = styled.div`
  padding: 1rem;

  h2,
  h3,
  h4 {
    font-weight: 500;
    margin: 2rem 0rem 1rem;
  }

  h4 {
    span {
      font-weight: bold;
    }
  }
`;

const Description = styled.div`
  color: #7f93bc;
  margin-bottom: 1rem;
`;

const MfaCodes = styled.div`
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
  span {
    margin: 10px 20px 10px 0;
    width: 100px;
    font-weight: 200;
    font-size: 0.9rem;
  }
`;

const CodesActionButtons = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.border};
  padding-bottom: 20px;
  margin-bottom: 20px;
  button {
    display: inline;
    margin-right: 10px;
  }
`;

const Note = styled.div`
  font-size: ${(props) => props.theme.typography.size.smallText};
  color: #7f93bc;

  p {
    &:first-child {
      color: ${(props) => props.theme.color.contentPrimary};
      font-weight: 400;
    }
  }
`;

const BackButton = styled(Button)`
  background: transparent;
  border: 0;
  padding: 0;
  color: ${(props) => props.theme.color.primary};
`;

const MFAChanged = (props) => {
  const { username, securityStep, changeMFAStep, mfaEnabled } = props;

  const { t } = useTranslation();
  const [recoveryCodes, setRecoveryCodes] = useState([]);

  const hasRecoveryCodes = !utils.isEmpty(recoveryCodes);

  const getRecoveryCodes = useQuery(
    constants.QUERY_NAMES.getMFARecoveryCodes,
    () => api.queries.getRecoveryCodes(),
    {
      enabled: mfaEnabled,
      onSuccess: (data) => {
        setRecoveryCodes(data.recovery_keys);
      },
    }
  );

  const goBack = () => {
    changeMFAStep(constants.MFA_STEPS.INITIAL);
    getRecoveryCodes.remove();
  };

  const generateRecoveryCodes = () => {
    getRecoveryCodes.refetch();
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(recoveryCodes.join("\n"));
    toast.success(t("SECURITY.COPIED_TO_CLIPBOARD"));
  };

  return (
    <Wrapper>
      <BackButton onClick={goBack} size="large">
        <ChevronLeft />
        {t("SECURITY.PASSWORD_AND_SECURITY")}
      </BackButton>
      <h2>
        {t(
          securityStep === constants.MFA_STEPS.RECOVERY_CODES
            ? "SECURITY.TWO_FACTOR_AUTHENTICATION_BACKUP_CODES"
            : "SECURITY.TWO_FACTOR_AUTHENTICATION_ENABLED"
        )}
      </h2>
      <h4>
        {t("SECURITY.ACCOUNT")} <span>{username}</span>
      </h4>
      <Description>{t("SECURITY.KEEP_BACKUP_CODES_SAFE")}</Description>
      <h3>{t("SECURITY.BACKUP_CODES")}</h3>
      <MfaCodes>
        {hasRecoveryCodes ? (
          recoveryCodes.map((code, index) => <span key={index}>{code}</span>)
        ) : (
          <div>{t("SECURITY.NO_CODES_AVAILABLE")}</div>
        )}
        {getRecoveryCodes.isLoading && <Spinner mb="1rem" />}
      </MfaCodes>
      <CodesActionButtons>
        <Button
          disabled={!hasRecoveryCodes || getRecoveryCodes.isLoading}
          onClick={copyToClipboard}
        >
          {t("SECURITY.COPY_CODES")}
        </Button>
        <Button onClick={generateRecoveryCodes} as={BackButton}>
          {t("SECURITY.GENERATE_NEW_RECOVERY_CODES")}
        </Button>
      </CodesActionButtons>
      <Note>
        <p>{t("SECURITY.NOTE")}</p>
        <p>{t("SECURITY.BACKUP_CODE_CAN_BE_USED_ONCE")}</p>
        <p>{t("SECURITY.RECOVERY_CODES_NOTE")}</p>
      </Note>
    </Wrapper>
  );
};

export default MFAChanged;
