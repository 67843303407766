import { useRef } from "react";
import { AlertTriangle, FileText } from "react-feather";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  Button,
  Spinner,
  Modal,
  ModalFooter,
  ModalBody,
  ModalButton,
  ModalHeader,
} from "@my-swipestox/components";

import api from "../../api";

import TradingAccountShortDesc from "./TradingAccountShortDesc";

const Note = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.color.negative};
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
`;

const NoteText = styled.label`
  font-size: ${(props) => props.theme.typography.size.normalText};
  margin-left: 10px;
`;

const PasswordContainer = styled.div`
  padding: 15px;
  background-color: ${(props) => props.theme.color.backgroundPrimary};
  font-size: ${(props) => props.theme.typography.size.subheadline};
  border-radius: 3px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

const CopyPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  span {
    font-size: ${(props) => props.theme.typography.size.smallText};
  }
`;

const FileTextCustom = styled(FileText)`
  margin-right: 7px;
`;

const DisclaimerText = styled.span`
  font-size: ${(props) => props.theme.typography.size.smallText};
`;

const ShowPassword = (props) => {
  const { item, isOpen, onClose } = props;
  const { t } = useTranslation();
  const passwordRef = useRef(null);
  const { data, isLoading } = useQuery(
    ["tradingAccountPassword", item.login],
    () => api.queries.getTradingAccountPassword(item.terminal_id, item.login),
    { enabled: isOpen }
  );

  const passwordData = data?.data?.data || {};

  const onDownloadClick = () => {
    window.open(passwordData.download_url, "_blank");
  };

  const onCopyToClipboardClick = async () => {
    const value = passwordRef.current.innerText;
    await navigator.clipboard.writeText(value);
    toast.success(t("LOBBY.SHOW_PASSWORD.PASSWORD_COPIED"));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        <TradingAccountShortDesc
          name={item.custom_name}
          currency={item.currency}
          accountNumber={item.login}
        />
      </ModalHeader>
      <ModalBody>
        <Note>
          <AlertTriangle />
          <NoteText>{t("LOBBY.SHOW_PASSWORD.NOTE")}</NoteText>
        </Note>
        <CopyPassword>
          <span>{t("LOBBY.SHOW_PASSWORD.TITLE")}</span>
          <Button disabled={isLoading} onClick={onCopyToClipboardClick} kind="secondary">
            {t("LOBBY.SHOW_PASSWORD.COPY")}
          </Button>
        </CopyPassword>
        <PasswordContainer ref={passwordRef}>
          {isLoading ? <Spinner /> : passwordData.password}
        </PasswordContainer>
        <DisclaimerText>{t("LOBBY.SHOW_PASSWORD.DISCLAIMER")}</DisclaimerText>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onDownloadClick} disabled={isLoading} shouldFitContainer>
          <FileTextCustom size={16} />
          {t("LOBBY.SHOW_PASSWORD.DOWNLOAD_PDF")}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ShowPassword;
