import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  Input,
  FormControl,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "@my-swipestox/components";

import api from "../../api";
import constants from "../../constants";

import TradingAccountShortDesc from "./TradingAccountShortDesc";
import Currency from "./Currency";

const CurrenciesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 0.7em;
  grid-column-gap: 0.7em;
  margin-top: 2rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CurrencyOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.color.backgroundPrimary : props.theme.color.backgroundSecondary};
`;

const AddNewAccountTitle = styled.label`
  font-size: ${(props) => props.theme.typography.size.subheadline};
`;

const initialValues = {
  name: "",
  currency: "",
};

const EditTradingAccount = (props) => {
  const { isOpen, onClose, item } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isEditMode, setIsEditMode] = useState(false);
  const { data, isSuccess } = useQuery("currencies", api.queries.getCurrencies);
  const filteredCurrencies =
    data?.filter((item) => constants.SUPPORTED_CURRENCIES.indexOf(item.currency) !== -1) || [];

  const closeModal = () => {
    setIsEditMode(false);
    formik.resetForm();
    onClose();
  };

  const invalidateTradingAccountsQuery = () => {
    queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
    closeModal();
    toast.success(t("LOBBY.EDIT_ACCOUNT.SUCCESS_MESSAGE"));
  };

  const editAccountMutation = useMutation((data) => api.mutations.editTradingAccountName(data), {
    onSuccess: invalidateTradingAccountsQuery,
  });

  const addNewTradingAccountMutation = useMutation(
    (data) => api.mutations.addNewTradingAccount(data),
    {
      onSuccess: invalidateTradingAccountsQuery,
      onError: closeModal,
    }
  );

  const onSubmit = (values) => {
    if (isEditMode) {
      const payload = {
        terminal_id: item.terminal_id,
        name: values.name,
      };

      editAccountMutation.mutate(payload);
    } else {
      const payload = {
        currency: values.currency,
        name: values.name || `NAGA - ${values.currency}`,
        is_live: true,
        server_type: constants.MT_VERSIONS.MT4.NAME,
      };

      addNewTradingAccountMutation.mutate(payload);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().min(3, t("LOBBY.EDIT_ACCOUNT.ERROR")),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isOpen) {
      if (item.login && !formik.values.name && !formik.values.currency) {
        formik.setFieldValue("name", item.custom_name);
        formik.setFieldValue("currency", item.currency);
        setIsEditMode(true);
      } else {
        if (!formik.values.currency && data) {
          formik.setFieldValue("currency", constants.SUPPORTED_CURRENCIES[0]);
        }
      }
    }
  }, [data, item, isOpen, formik]);

  if (!isSuccess) {
    return null;
  }

  const onCurrencySelect = (e) => {
    e.stopPropagation();
    const currency = e.currentTarget.dataset.currency;

    formik.setFieldValue("currency", currency);
  };

  const renderCurrencyOptions = (item) => {
    return (
      <FormControl key={item.currency}>
        <CurrencyOption
          selected={item.currency === formik.values.currency}
          data-currency={item.currency}
          onClick={isEditMode ? undefined : onCurrencySelect}
        >
          <Currency currencyCode={item.currency} currencyName={item.currency_name} />
          <input
            type="radio"
            name="currency"
            disabled={isEditMode}
            value={formik.values.currency}
            checked={item.currency === formik.values.currency}
            onChange={formik.handleChange}
          />
        </CurrencyOption>
      </FormControl>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader>
          {item.login ? (
            <TradingAccountShortDesc
              name={item.custom_name}
              currency={item.currency}
              accountNumber={item.login}
            />
          ) : (
            <AddNewAccountTitle>{t("LOBBY.EDIT_ACCOUNT.TITLE")}</AddNewAccountTitle>
          )}
        </ModalHeader>
        <ModalBody>
          <FormControl
            label={t("LOBBY.EDIT_ACCOUNT.ACCOUNT_NAME")}
            caption={(formik.touched.name && formik.errors.name) || ""}
            error={Boolean(formik.errors.name && formik.touched.name)}
          >
            <Input
              name="name"
              type="text"
              error={Boolean(formik.errors.name && formik.touched.name)}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={`NAGA - ${formik.values.currency}`}
            />
          </FormControl>
          <CurrenciesWrapper>
            {filteredCurrencies.map((i) => renderCurrencyOptions(i))}
          </CurrenciesWrapper>
        </ModalBody>
        <ModalFooter>
          <ModalButton type="button" onClick={closeModal} kind="secondary">
            {t("LOBBY.EDIT_ACCOUNT.CANCEL")}
          </ModalButton>
          <ModalButton
            type="submit"
            disabled={editAccountMutation.isLoading || addNewTradingAccountMutation.isLoading}
            isLoading={editAccountMutation.isLoading || addNewTradingAccountMutation.isLoading}
          >
            <span>{t("LOBBY.EDIT_ACCOUNT.SAVE")}</span>
          </ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditTradingAccount;
