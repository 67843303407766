import { useTranslation } from "react-i18next";
import { Button } from "@my-swipestox/components";
import styled from "styled-components";

import KycHeader from "./KycHeader";
import kycUtils from "./utils";

const KycSuccessModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  background: ${(props) => props.theme.color.kycBackground};
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  white-space: pre-wrap;

  .categorizationWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 42px;
    }
  }
  
  .categoryDescription {
    padding: 10px;
    font-weight: 500;
    margin: 24px auto;
    border-radius: 8px;
    max-width: 350px;
  }
  
  .descriptionBeginner {
    color: #ff9900;
    background-color: rgba(255, 153, 0, 0.1);
  }
  
  .descriptionIntermediate {
    color: #1585d8;
    background-color: rgba(152, 188, 255, 0.1);
  }
  
  .descriptionAdvanced {
    color: #3dc752;
    background-color: rgba(61, 199, 82, 0.1);
  }
  
  .descriptionFooter {
    margin-bottom: 22px !important;
    max-width: 350px;
  }
  
  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    width: 430px;
    padding-bottom: 20px;
  }

  .descriptionWrapper {
    width: 390px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .descriptionHeader {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .footerBtnWrapper,
  .verifyFooterBtnWrapper {
    display: flex;
    flex-direction: column;
    min-height: 48px;
    width: 100%;
    top: 0px;
    border-radius: 4px;
    gap: 16px;
  }

  .verifyFooterBtnWrapper {
    flex-direction: row;
  }

  .wizardFooter {
    position: absolute;
    bottom: 36px;
  }

  .successButton {
    height: 48px;
    width: 350px;
  }
  .secondaryButton {
    height: 48px;
    color: ${(props) => props.theme.color.kycContinueButton};
    background-color: ${(props) => props.theme.color.kycCancelButtonBackground};
    border: none;
    width: 350px;
    &:hover {
      color: ${(props) => props.theme.color.headerFill};
    }
  }
`;

const KycSuccessModal = (props) => {
  const { onFundAccount, onVerify, tradingExperience } = props;
  const { t } = useTranslation();

  const { image, title, description, fundDisclaimer, verifyDisclaimer } = kycUtils.successSpecification[tradingExperience];

  return (
    <KycSuccessModalContainer>
      <KycHeader transparent />
      <div className="categorizationWrapper">
        <img src={image} alt="success" />
        <div className="descriptionHeader">{t('KYC.ACCOUNT_CATEGORIZED_AS')}</div>
        <div className="descriptionHeader">{t(title.key, title.params)}</div>
        <div>
          {description && <p className={`categoryDescription ${description.style}`}>{t(description.key, description.params)}</p>}
          {verifyDisclaimer && <p className="descriptionFooter">{t(verifyDisclaimer.key, verifyDisclaimer.params)}</p>}
          {fundDisclaimer && <p className="descriptionFooter">{t(fundDisclaimer.key, fundDisclaimer.params)}</p>}
        </div>
      </div>
      <footer className="wizardFooter">
        <div className="footerBtnWrapper">
          <Button className="successButton" bsStyle="primary" onClick={onFundAccount}>
            {t('KYC.FUND_ACCOUNT')}
          </Button>
          <Button className="secondaryButton" bsStyle="secondary" onClick={onVerify}>
            {t('KYC.VERIFY_IDENTITY')}
          </Button>
        </div>
      </footer>
  </KycSuccessModalContainer>
  );
};

export default KycSuccessModal;
