import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQueries } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Button, FormControl } from "@my-swipestox/components";

import api from "../../api";

import Select from "../../components/select/Select";
import Number from "../../components/number/Number";
import Amount from "../../components/amount/Amount";
import constants from "../../constants";

import moneyHelper from "../../helpers/moneyHelper";
import NacepayWidget from "./NacepayWidget";

const DepositWrapper = styled.div`
  padding: 15px;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    width: 70%;
  }
  @media (min-width: ${constants.BREAKPOINTS.LARGE_DEVICES}) {
    width: 40%;
  }
`;

const DepositDetails = styled.table`
  width: 100%;
  margin-top: 1rem;
  tr {
    > td:last-of-type {
      text-align: right;
    }
    td {
      padding: 5px 0px;
      font-size: ${(props) => props.theme.typography.size.smallText};
    }
  }
`;

const DepositTitle = styled.label`
  font-size: ${(props) => props.theme.typography.size.subheadline};
`;

const SubmitButton = styled(Button)`
  margin-top: 2rem;
  min-width: 150px;
`;

const FormControlCustom = styled(FormControl)`
  margin-top: 1rem;
`;

const Form = styled.form`
  margin-top: 2rem;
`;

const initialValues = {
  amount: "",
  provider: constants.PAYMENT_OPTIONS.peerToPeer,
};

let formik = null;

const Deposit = (props) => {
  const { fullAccount } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [nacePayRefId, setNacePayRefID] = useState(null);
  const [ibDepositLimitsQuery, crossRateQuery, paymentGates] = useQueries([
    {
      queryKey: [constants.QUERY_NAMES.ibDepositLimits, formik?.values?.provider?.value],
      queryFn: () =>
        api.queries.ibDepositLimits(
          fullAccount.terminal_id,
          Object.values(constants.PAYMENT_PROVIDERS).includes(formik?.values?.provider?.value)
            ? formik.values.provider.value
            : "mpsa"
        ),
    },
    {
      queryKey: [constants.QUERY_NAMES.cross_rate, fullAccount.currency],
      queryFn: () => {
        return api.queries.getCrossRate("CNY", fullAccount.currency);
      },
    },
    {
      queryKey: [constants.QUERY_NAMES.paymentGates],
      queryFn: () => {
        return api.queries.getPaymentGates("deposit");
      },
    },
  ]);
  const [maxAmountLimit, setMaxAmountLimit] = useState(
    constants.PAYMENT_OPTIONS.peerToPeer.maxAmount
  );

  const minAmountLimit = ibDepositLimitsQuery.data?.data?.data?.limits?.CNY?.min ? parseFloat(ibDepositLimitsQuery.data?.data?.data?.limits?.CNY?.min) : 0;
  const depositMutation = useMutation(
    (data) => {
      return api.mutations.depositRequest(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};

        history.push("/credit-card", {
          html: result,
          provider: formik.values.provider.value,
        });
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );
  const zotaPayMutation = useMutation(
    (data) => {
      return api.mutations.getDepositZotapayUri(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || {};
        window.location.href = result.url;
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const nacePayMutation = useMutation(
    (data) => {
      return api.mutations.getDepositNacepayData(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || null;
        setNacePayRefID(result);
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const nucleusMutation = useMutation(
    (data) => {
      return api.mutations.getDepositNucleus(data);
    },
    {
      onSuccess: (response) => {
        const result = response?.data?.data || null;
        window.location.href = result.url;
      },
      onError: (error) => {
        const code = error?.response?.data?.info?.errorCode || null;
        toast.error(t(constants.ERRORS[code] || constants.ERRORS.DEFAULT));
      },
    }
  );

  const closeNacepayModal = () => {
    setNacePayRefID(null);
    formik.resetForm(initialValues);
  };

  const validationSchema = Yup.object().shape({
    provider: Yup.object().required(t("MANAGE_MONEY.DEPOSIT.SELECT_PROVIDER_ERROR")),
    amount: Yup.string()
      .required(t("MANAGE_MONEY.DEPOSIT.MISSING_AMOUNT_ERROR"))
      .test({
        name: "min",
        message: t("MANAGE_MONEY.DEPOSIT.MIN_AMOUNT_ERROR", {
          amount: minAmountLimit,
        }),
        test: (value) => {
          return parseFloat(value) >= minAmountLimit;
        },
      })
      .test({
        name: "max",
        message: t("MANAGE_MONEY.DEPOSIT.MAX_AMOUNT_ERROR", {
          amount: maxAmountLimit,
        }),
        test: (value) => {
          return parseFloat(value) <= maxAmountLimit;
        },
      }),
  });

  const onSubmit = (values) => {
    if (values.provider.value === constants.PAYMENT_PROVIDERS.ZOTAPAY) {
      const payload = {
        currency: values.provider.currency,
        amount: values.amount,
        endpoint_id: values.provider.endpoint_id,
        terminal_id: fullAccount.terminal_id,
        redirect_url: constants.NAGA_TRADER_URL,
        checkout_url: `${constants.NAGA_TRADER_URL}/manage-money/deposit`,
      };
      zotaPayMutation.mutate(payload);
      return;
    } else if (values.provider.value === constants.PAYMENT_PROVIDERS.NACEPAY) {
      const payload = {
        currency: fullAccount.currency,
        amount: +(values.amount * crossRateQuery.data).toFixed(fullAccount.currency_digits),
        terminal_id: fullAccount.terminal_id,
      };
      nacePayMutation.mutate(payload);
      return;
    } else if (values.provider.value === constants.PAYMENT_PROVIDERS.NUCLEUS) {
      const payload = {
        currency: values.provider.currency,
        amount: values.amount,
        terminal_id: fullAccount.terminal_id,
      };
      nucleusMutation.mutate(payload);
      return;
    }
    const payload = {
      amount: values.amount,
      return_url: constants.NAGA_TRADER_URL,
      solution: values.provider.value,
      terminal_id: fullAccount.terminal_id,
    };

    depositMutation.mutate(payload);
  };

  formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    formik.validateForm();
  }, [minAmountLimit]);

  useEffect(() => {
    ibDepositLimitsQuery.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullAccount.terminal_id]);

  useEffect(() => {
    const provider = formik.values.provider.value;
    const maxAmmountMap = {
      [constants.PAYMENT_OPTIONS.peerToPeer.value]: constants.PAYMENT_OPTIONS.peerToPeer.maxAmount,
      [constants.ZOTAPAY.value]: constants.ZOTAPAY.maxAmount,
      [constants.NUCLEUS.value]: constants.NUCLEUS.maxAmount,
      [constants.NACEPAY.value]: constants.NACEPAY.maxAmount,
    };
    setMaxAmountLimit(maxAmmountMap[provider]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.provider]);

  useEffect(() => {
    formik.validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxAmountLimit]);

  const paymentOptions = moneyHelper.mapPaymentOptions(paymentGates);

  return (
    <DepositWrapper>
      <DepositTitle>{t("MANAGE_MONEY.DEPOSIT.TITLE")}</DepositTitle>
      <Form onSubmit={formik.handleSubmit}>
        <FormControl
          label={t("MANAGE_MONEY.DEPOSIT.PROVIDER")}
          caption={(formik.touched.provider && formik.errors.provider) || ""}
          error={Boolean(formik.errors.provider && formik.touched.provider)}
        >
          <Select
            name="provider"
            options={paymentOptions}
            onChange={(value) => formik.setFieldValue("provider", value)}
            value={formik.values.provider}
            placeholder={t("MANAGE_MONEY.DEPOSIT.PROVIDER_PLACEHOLDER")}
            getOptionLabel={(option) => t(option.label)}
          />
        </FormControl>
        <FormControlCustom
          label={t("MANAGE_MONEY.DEPOSIT.AMOUNT")}
          caption={(formik.touched.amount && formik.errors.amount) || ""}
          error={Boolean(formik.errors.amount && formik.touched.amount)}
        >
          <Number
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            flag="CNY"
          />
        </FormControlCustom>
        <DepositDetails>
          <tbody>
            <tr>
              <td>
                {t("MANAGE_MONEY.ACCOUNT.AMOUNT_IN_CURRENCY", {
                  currency: fullAccount.currency,
                })}
              </td>
              <td>
                <Amount
                  noColor
                  amount={formik.values.amount * crossRateQuery.data}
                  decimals={fullAccount.currency_digits}
                  symbol={fullAccount.currency_symbol}
                />
              </td>
            </tr>
          </tbody>
        </DepositDetails>
        <SubmitButton
          type="submit"
          disabled={depositMutation.isLoading}
          isLoading={depositMutation.isLoading}
        >
          {t("MANAGE_MONEY.DEPOSIT.DEPOSIT")}
        </SubmitButton>
      </Form>
      <NacepayWidget
        amount={+(formik.values.amount * crossRateQuery.data).toFixed(fullAccount.currency_digits)}
        currency={fullAccount.currency}
        show={!!nacePayRefId}
        refId={nacePayRefId}
        closeModal={closeNacepayModal}
      />
    </DepositWrapper>
  );
};

export default Deposit;
