import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { IntercomProvider } from "react-use-intercom";
import "url-search-params-polyfill";
import "moment/locale/zh-cn";

import constants from "./constants";

import App from "./App";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <IntercomProvider appId={constants.INTERCOM_APP_ID}>
        <App />
      </IntercomProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
