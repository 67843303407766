import PropTypes from "prop-types";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Input,
  FormControl,
  Modal,
  ModalFooter,
  ModalBody,
  ModalButton,
  ModalHeader,
} from "@my-swipestox/components";

import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";

import TradingAccountShortDesc from "./TradingAccountShortDesc";

const Title = styled.label`
  font-size: ${(props) => props.theme.typography.size.subheadline};
  margin-bottom: 1rem;
`;

const PasswordDesc = styled.span`
  font-size: ${(props) => props.theme.typography.size.smallText};
  color: ${(props) => props.theme.color.contentSecondary};
  width: 70%;
  margin-top: 2rem;
`;

const initialValues = {
  password: "",
};

const ChangePassword = (props) => {
  const { isOpen, onClose, item } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const generatePasswordMutation = useMutation(api.mutations.generateNewTradingAccountPassword, {
    onSuccess: () => {
      toast.success(t("LOBBY.CHANGE_PASSWORD.SUCCESS_MESSAGE"));
      onModalClose();
      queryClient.invalidateQueries(constants.QUERY_NAMES.tradingAccounts);
    },
    onError: () => {
      toast.error(t("LOBBY.CHANGE_PASSWORD.ERROR_MESSAGE"));
    },
  });

  const onSubmit = (values) => {
    const payload = {
      broker_server_id: item.broker_server_id,
      company: item.company,
      custom_name: item.custom_name,
      login: item.login,
      naga_password: utils.crypto.encode(values.password),
      terminal_id: item.terminal_id,
    };

    generatePasswordMutation.mutate(payload);
  };

  const onModalClose = () => {
    onClose();
    formik.resetForm();
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("LOBBY.CHANGE_PASSWORD.ERROR"))
      .min(6, t("LOBBY.CHANGE_PASSWORD.ERROR"))
      .max(15, t("LOBBY.CHANGE_PASSWORD.ERROR")),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader>
          <TradingAccountShortDesc
            name={item.custom_name}
            currency={item.currency}
            accountNumber={item.login}
          />
        </ModalHeader>
        <ModalBody>
          <Title>{t("LOBBY.CHANGE_PASSWORD.TITLE")}</Title>
          <FormControl
            label={t("LOBBY.CHANGE_PASSWORD.CURRENT_PASSWORD")}
            caption={(formik.touched.password && formik.errors.password) || ""}
            error={Boolean(formik.errors.password && formik.touched.password)}
          >
            <Input
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
          <PasswordDesc>{t("LOBBY.CHANGE_PASSWORD.DESC")}</PasswordDesc>
        </ModalBody>
        <ModalFooter>
          <ModalButton type="button" onClick={onModalClose} kind="secondary">
            {t("LOBBY.CHANGE_PASSWORD.CANCEL")}
          </ModalButton>
          <ModalButton
            type="submit"
            disabled={generatePasswordMutation.isLoading}
            isLoading={generatePasswordMutation.isLoading}
          >
            <span>{t("LOBBY.CHANGE_PASSWORD.SAVE")}</span>
          </ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ChangePassword.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangePassword;
