import React from "react";
import styled from "styled-components";

import { COUNTRIES } from "./constants";

import Select from "../select/Select";

import constants from "../../constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    flex-direction: row;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    width: 50%;
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;

const options = Object.values(COUNTRIES);

const PhoneInput = (props) => {
  const { country = {}, onCountryChange = () => {}, children } = props;

  const getOptionLabel = (item) => {
    return `${item.label} ${item.value}`;
  };

  return (
    <Wrapper>
      <SelectWrapper>
        <Select
          value={country}
          options={options}
          getOptionLabel={getOptionLabel}
          onChange={onCountryChange}
        />
      </SelectWrapper>
      {children}
    </Wrapper>
  );
};

export default PhoneInput;
