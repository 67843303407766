const typography = {
  type: {
    primary: `"Inter UI", sans-serif`,
  },
  size: {
    title: "1.953rem",
    headline: "1.563rem",
    subheadline: "1.25rem",
    normalText: "0.875rem",
    smallText: "0.8rem",
    extraSmallText: "0.6rem",
  },
};
const light = {
  color: {
    primary: "#3179ff",
    border: "#eeeeee",
    inputFill: "#fff",
    backgroundPrimary: "#f3f7fa",
    backgroundSecondary: "#fff",
    backgroundHover: "#f8f9fb",
    contentPrimary: "#000",
    contentSecondary: "#545454",
    negative: "#D44333",
    warning: "#ffbf3d",
    shadow: "rgba(127, 147, 188, 0.1)",
    positive: "#3AA76D",
    headerFill: "#1d1e37",
    headerNavigationFill: "#f6f7f8",
    foreground: "#000000",
    lightGrey: "#dfe3e9",
    menuFill: "#FFFFFF",
    menuFillHover: "#F6F6F6",
    borderFocus: "#000000",
    inputFillActive: "#F6F6F6",
    inputBorderError: "#F4AFA7",
    inputFillError: "#FDF0EF",
    inputBorderPositive: "#AEDDC2",
    inputFillPositive: "#F0F9F4",
    buttonDisabledFill: "#F6F6F6",
    buttonDisabledText: "#AFAFAF",
    calendarFill: "#ffffff",
    calendarCaption: "#484848",
    calendarNavigationFill: "#ffffff",
    calendarDayBackground: "#e4e7e7",
    calendarDisabledDay: "#cacccd",
    calendarDayHover: "#484848",
    kycBackground: "#FFFFFF",
    kycCancelButtonBackground: '#E4E9EF',
    kycContinueLater: "#1d1e37",
  },
  typography,
};
const dark = {
  color: {
    primary: "#3179ff",
    border: "#20283c",
    inputFill: "#252b40",
    backgroundPrimary: "#0c0f15",
    backgroundSecondary: "#141722",
    backgroundHover: "#1a1e2d",
    contentPrimary: "#fff",
    contentSecondary: "#AFAFAF",
    shadow: "rgba(39, 110, 241, 0.32)",
    negative: "#D44333",
    positive: "#3AA76D",
    warning: "#ffbf3d",
    headerFill: "#161b2a",
    headerNavigationFill: "#1d1d1d",
    foreground: "#CBCBCB",
    lightGrey: "#dfe3e9",
    menuFill: "#292929",
    menuFillHover: "#1F1F1F",
    borderFocus: "#FFFFFF",
    inputFillActive: "#333333",
    inputBorderError: "#F4AFA7",
    inputFillError: "#5C1D16",
    inputBorderPositive: "#3AA76D",
    inputFillPositive: "#1C472F",
    buttonDisabledFill: "#292929",
    buttonDisabledText: "#757575",
    calendarFill: "#161b2a",
    calendarCaption: "#b0b0b0",
    calendarNavigationFill: "#161b2a",
    calendarDayBackground: "#1f273b",
    calendarDisabledDay: "#43494a",
    calendarDayHover: "#e9e9e9",
    kycBackground: "#131723",
    kycCancelButtonBackground: '#404C64',
    kycContinueLater: "#ffffff",
  },
  typography,
};
const themes = {
  light,
  dark,
};

export default themes;
